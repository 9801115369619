import { rest } from 'msw';
import { FORM_ID_CHILD_SERVICES } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN } from 'constants/questionTypes';
import { SECONDARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 5
export const form5 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_CHILD_SERVICES}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_CHILD_SERVICES;
    const formTitle =
      'You mentioned a dependent under the age of 5. Do you think they’ll need any of these services?';
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: '2431dbc6-6bcb-44f1-9c14-07e610ba831d',
          title: 'Well Child Visits',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a731dbc6-6bcb-44f1-9c14-07e610ba872c'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          sidebar: {},
        },
        {
          id: '516c0d1a-e941-4775-8a4a-20cfb52c4b76',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '2431dbc6-6bcb-44f1-9c14-07e610ba831d',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'ac4298a0-bee5-4a7e-bfe9-66b2cfc3392c',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '2431dbc6-6bcb-44f1-9c14-07e610ba831d',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '8e3bb7bf-9e68-4881-b0be-783e045A4006',
          title: 'Early Intervention',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a731dbc6-6bcb-44f1-9c14-07e610ba872c'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          sidebar: {},
        },
        {
          id: '43caf185-6587-4403-941e-ab9ac02b65fe',
          titleLarge: 'Who is it for?',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '8e3bb7bf-9e68-4881-b0be-783e045A4006',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '6204c1a7-34e5-4a0e-9752-1d86004fa690',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '8e3bb7bf-9e68-4881-b0be-783e045A4006',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a731dbc6-6bcb-44f1-9c14-07e610ba872c',
          title: 'None of these',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            questionId: null,
            answerId: null,
            disableOnQuestionIds: [
              '2431dbc6-6bcb-44f1-9c14-07e610ba831d',
              '8e3bb7bf-9e68-4881-b0be-783e045A4006',
            ],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'In a few more questions, you will have the option to select additional services (like specialists, primary care visits, surgeries, and prescriptions) for your child(ren).',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
