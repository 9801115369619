import { rest } from 'msw';
import { FORM_ID_HEAD_AND_NECK_SURGERY } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_RADIO } from 'constants/questionTypes';
import { SECONDARY, TERTIARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 15
export const form15 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_HEAD_AND_NECK_SURGERY}`,
  (req, res, ctx) => {
    const formId = FORM_ID_HEAD_AND_NECK_SURGERY;
    const formTitle = 'What kind of head and neck surgery?';
    const formSubtitle = 'Add as many as you need';
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: '06404d67-86b5-4dbc-afd5-813d2c834c25',
          title: 'Neck Pain - Cervical Spine Fusion',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '85961910-89c7-4715-b6bc-4f904e1a5af5',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          memberId: 'd50884',
          dependency: {
            status: SECONDARY,
            questionId: '06404d67-86b5-4dbc-afd5-813d2c834c25',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a1e4fec3-1023-49a1-9f63-cf5e8c73f766',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          memberId: 'd50885',
          dependency: {
            status: SECONDARY,
            questionId: '06404d67-86b5-4dbc-afd5-813d2c834c25',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '5f6c0236-66f4-4e75-b612-e1eef64ce3d7',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          memberId: 'd50883',
          dependency: {
            status: SECONDARY,
            questionId: '06404d67-86b5-4dbc-afd5-813d2c834c25',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'e10692ef-5640-4c2b-a445-92d2236d19bd',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '06404d67-86b5-4dbc-afd5-813d2c834c25',
          },
          multipleChoiceOptions: [
            {
              id: '761ebb03-bd6d-4983-8b7e-00f408752c28',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '1d72be3c-e5e8-4bfa-b105-d3c65c3a9301',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: '74a5c94e-2ee1-40b5-bf7a-11830c7ff47e',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '07f8ff5f-6433-4ff5-aa66-abc7bb2423d4',
          title: 'Cataract Removal with Lens Implant',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '7f3cb251-dce2-4a4c-af3f-c23f787878fb',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '07f8ff5f-6433-4ff5-aa66-abc7bb2423d4',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'e77c8ae0-7f04-4418-bad6-e5ba9cd9b6c5',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '07f8ff5f-6433-4ff5-aa66-abc7bb2423d4',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '75489823-e446-4638-805c-c33e258350a4',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '07f8ff5f-6433-4ff5-aa66-abc7bb2423d4',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'f1a1200d-ed38-4ff2-9942-cbbd8b2fed3e',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '07f8ff5f-6433-4ff5-aa66-abc7bb2423d4',
          },
          multipleChoiceOptions: [
            {
              id: 'e22a1d3d-3302-495e-badc-5e28f5bdf347',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: 'a8adf711-4ffb-4fca-9259-058619906c72',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'cf56727e-f69e-4051-a769-c83dd7ca933c',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '76dafee4-6133-4064-b6d1-735056ea9afc',
          title: 'Glaucoma',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'c180b6a5-2760-43c5-90c6-b5c84d428abe',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '76dafee4-6133-4064-b6d1-735056ea9afc',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '855079c2-bc2a-4608-a55d-fc4591eebbe0',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '76dafee4-6133-4064-b6d1-735056ea9afc',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'd7481283-32f4-4698-ac34-13afeadee87c',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '76dafee4-6133-4064-b6d1-735056ea9afc',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '59d485af-a33f-4b31-9f2c-2357bd7688ac',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '76dafee4-6133-4064-b6d1-735056ea9afc',
          },
          multipleChoiceOptions: [
            {
              id: '179bdef0-6b9f-4a92-8f2d-511cce9a9ef8',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '5f1cd9f3-6aec-4feb-aa87-778bd21378fa',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: '089a5956-8e9e-4db4-99b2-851966ffafcc',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '8c089e37-dd8f-41e7-a89e-2a53f3aa5d61',
          title: 'Tonsil Removal',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '6e5bb6b7-e7b2-4a0a-b91e-51d20cc35f21',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '8c089e37-dd8f-41e7-a89e-2a53f3aa5d61',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '7c99e8f1-6b88-4ad8-b1ec-80e3a019fd70',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '8c089e37-dd8f-41e7-a89e-2a53f3aa5d61',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '6ef0c578-96a4-4d03-9bfa-ae72db65ead8',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '8c089e37-dd8f-41e7-a89e-2a53f3aa5d61',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '4a2b239a-77fe-45c6-b8ee-84a6c21b93d6',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '8c089e37-dd8f-41e7-a89e-2a53f3aa5d61',
          },
          multipleChoiceOptions: [
            {
              id: '3025fdd7-a80e-4b59-82b8-2b21a0d011b8',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '1c87663d-c004-4761-9fd4-a99025c37eae',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'a9d0ba4e-0453-42fa-840d-3882768a09bf',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '4386f646-2eaa-4b99-86d4-3b3df3afc337',
          title: 'General Surgery',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '2678ba6e-dc7d-4f4e-8c44-2813d68ef2c8',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '4386f646-2eaa-4b99-86d4-3b3df3afc337',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'c4288cc6-47dc-428e-a6a0-3ffe89a520b7',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '4386f646-2eaa-4b99-86d4-3b3df3afc337',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '88f133ca-bf34-4c24-be42-4f6acea7b089',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '4386f646-2eaa-4b99-86d4-3b3df3afc337',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '548e1c93-b267-4f87-b5c5-0a9266ee67cf',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '4386f646-2eaa-4b99-86d4-3b3df3afc337',
          },
          multipleChoiceOptions: [
            {
              id: '097ebdec-00ff-488d-bd89-b24cd2d39675',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '9871faca-a222-4865-9c73-9ac4b5f3bbe8',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'd59fd547-d3d8-4dd5-a7b3-28cdfd6eb566',
              title: "I don't know",
            },
          ],
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        "If you don't see a surgery that you're planning or don't know the name, don't worry! Select General Surgery and I will factor an average cost surgery into my recommendation.",
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formSubtitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
