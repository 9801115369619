import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';

import routes from 'constants/routes';

const RegisteredGuestRoute = ({ component, exact, path, userData = {} }) => {
  const renderComponent = () => {
    const { auth, email } = userData;
    const hasEmail = email && email.length > 0;

    const authenticationData = auth ? auth : {};
    const { authenticated } = authenticationData;

    const C = component;

    if (hasEmail || authenticated) return <C />;

    return <Redirect to={`${routes.ABOUT}`} />;
  };

  return <Route exact={exact} path={path} render={() => renderComponent()} />;
};

const WrappedComponent = withRouter(RegisteredGuestRoute);
export default WrappedComponent;
