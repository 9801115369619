import { rest } from 'msw';
import { FORM_ID_LAB_WORK_OTHER } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';
import { SECONDARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 21
export const form21 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_LAB_WORK_OTHER}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_LAB_WORK_OTHER;
    const formTitle = 'What about any of these labs or imaging services?';
    const formSubtitle = '';
    const formContent = '';
    const groupedQuestions = [
      [
        {
          id: 'a631dbc6-6bcb-44f1-9c14-07e610ba520e',
          title: 'Colonoscopy',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba2021'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '69fe3690-047c-458e-9b35-6395e1e7af18',
          titleLarge: 'Colonoscopy - number per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a631dbc6-6bcb-44f1-9c14-07e610ba520e',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'eb98e3c5-197a-4092-a79d-50b23d09e21f',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a631dbc6-6bcb-44f1-9c14-07e610ba520e',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'f00e752d-a2d8-43fa-bfb7-ec6acfb1c741',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a631dbc6-6bcb-44f1-9c14-07e610ba520e',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'c331dbc6-6bcb-44f1-9c14-07e610ba5201',
          title: 'Endoscopy',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba2021'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'ae475b65-0b34-4fc3-a607-27a747635088',
          titleLarge: 'Endoscopy - number per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'c331dbc6-6bcb-44f1-9c14-07e610ba5201',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'c34d5eb2-2018-419f-bc72-33b1136e27ae',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'c331dbc6-6bcb-44f1-9c14-07e610ba5201',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '719b5eaf-d338-4057-ac02-34392540e445',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'c331dbc6-6bcb-44f1-9c14-07e610ba5201',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a371dbc6-6bcb-44f1-9c14-07e610ba5201',
          title: 'Mammogram',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba2021'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '9a881baa-1531-45b5-9af3-04d3f280fb71',
          titleLarge: 'Mammogram - number per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a371dbc6-6bcb-44f1-9c14-07e610ba5201',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '296958df-9b92-46b2-b975-30eacbd4d82a',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a371dbc6-6bcb-44f1-9c14-07e610ba5201',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'db5a9efb-8478-4d49-be72-9b7b40abf297',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a371dbc6-6bcb-44f1-9c14-07e610ba5201',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a571dbc6-6bcb-44f1-9c14-07e610ba5201',
          title: 'Ultrasound',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba2021'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '3bf3c33d-7683-4a5e-b2c6-319036f42b2b',
          titleLarge: 'Ultrasound - number per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a571dbc6-6bcb-44f1-9c14-07e610ba5201',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '7734867e-751c-4b3e-9504-7a4f926bccfb',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a571dbc6-6bcb-44f1-9c14-07e610ba5201',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '563c78d2-aaca-4702-9868-53f09a3e60e5',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a571dbc6-6bcb-44f1-9c14-07e610ba5201',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'ac71dbc6-6bcb-44f1-9c14-07e610ba5201',
          title: 'Lab Work',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba2021'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '55da6e4d-c547-453b-872c-37630d47a44c',
          titleLarge: 'Lab Work - number per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'ac71dbc6-6bcb-44f1-9c14-07e610ba5201',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '0037c5c0-85c5-4dcf-aa90-64372f85bd04',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'ac71dbc6-6bcb-44f1-9c14-07e610ba5201',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '000685f1-3302-41e1-9056-de4127b1a036',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'ac71dbc6-6bcb-44f1-9c14-07e610ba5201',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a0d1dbc6-6bcb-44f1-9c14-07e610ba2021',
          title: 'None of these',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            questionId: null,
            answerId: null,
            disableOnQuestionIds: [
              'a631dbc6-6bcb-44f1-9c14-07e610ba520e',
              'c331dbc6-6bcb-44f1-9c14-07e610ba5201',
              'a371dbc6-6bcb-44f1-9c14-07e610ba5201',
              'a571dbc6-6bcb-44f1-9c14-07e610ba5201',
              'ac71dbc6-6bcb-44f1-9c14-07e610ba5201',
            ],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        "Some people need these types of services. Just add what you think you'll need, and you can always remove them later.",
      bodyText:
        'Not sure whether to add these? Consider adding if you do any of these yearly or if you are having a major surgery next year (major surgeries often require some of these services).',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formSubtitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
