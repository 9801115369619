import { rest } from 'msw';
import { SHERPA_FORM_ID_SHORT_TERM_DISABILITY } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_NUMBER } from 'constants/questionTypes';

export const sherpaForm218 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_SHORT_TERM_DISABILITY}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_SHORT_TERM_DISABILITY;
    const formHeader = 'SHORT TERM DISABILITY';
    const formTitle = `What percent of income would your short term disability insurance pay out?`;
    const formSubtitle = ``;
    const formContent = ``;
    const formIsSherpa = true;
    const groupedQuestions = [
      [
        {
          id: 'e47be618-2a7a-4b74-b069-1d24dfd1086f',
          title: 'Percent of Income',
          subtitle: '',
          titleLarge: '',
          type: INPUT_NUMBER,
          dependency: null,
          max: 100,
          min: 10,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '',
          suffix: '%',
        },
      ],
      [
        {
          id: '9afb4a46-b8b9-4989-b297-01ab17377ca4',
          title: 'Length of Short Term Disability',
          subtitle: '',
          titleLarge:
            'How long will your short term disability insurance pay for?',
          type: INPUT_NUMBER,
          dependency: null,
          max: 99,
          min: 1,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '',
          suffix: 'weeks',
        },
      ],
      [
        {
          id: '179101a9-de56-43f7-8327-5c343cb44d52',
          title: 'Short Term Disability Wait Period',
          subtitle: '',
          titleLarge:
            'How long would you have to wait until your short term disability starts paying out?',
          type: INPUT_NUMBER,
          dependency: null,
          max: 24,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '',
          suffix: 'weeks',
          shownDefaultValue: 0,
          allowNullQuestions: true,
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'This is why we ask this question...',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formHeader,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
