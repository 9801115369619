import { rest } from 'msw';

import { PROGRESS_ID_ABOUT_YOU } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { PROGRESS_ABOUT_YOU } from 'constants/progressStatuses';
import { FORM_TYPE_PROGRESS } from 'constants/formTypes';

export const progress111 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${PROGRESS_ID_ABOUT_YOU}`,
  async (req, res, ctx) => {
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId: PROGRESS_ID_ABOUT_YOU,
        progressStatus: PROGRESS_ABOUT_YOU,
        formType: FORM_TYPE_PROGRESS,
      }),
    );
  },
);
