import { rest } from 'msw';
import { FORM_ID_HEALTH_SELECTIONS } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN } from 'constants/questionTypes';

// Form ID from the MHM Decision Doc Copy Document: 24
export const form24 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_HEALTH_SELECTIONS}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_HEALTH_SELECTIONS;
    const formTitle =
      'I put some healthcare on cards again. Do any of these apply to you or your household?';
    const formContent = '';
    const displayAsGrid = true;
    const formCssId = 'health-selections';
    const formSubtitle = 'Add as many as you need';
    const groupedQuestions = [
      [
        {
          id: '1657041d-3f71-4cca-838c-bca3a01035ca',
          title: 'Allergy Testing or Treatment',
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['b615c1c1-79ca-44c9-82c3-87e2bf3bcdb0'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Throw-Trash-2--Ecology-Recycling-Recycle-Tissue-Dirty-Paper-Trash-Can-Bin-Throw-Hand+1.svg`,
            alt: 'Allergy Testing or Treatment',
            title: '',
          },
          sidebar: {
            title: 'Head & Neck Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
      [
        {
          id: '72d2e586-cba9-4175-b149-e9737d847900',
          title: 'Pain Management',
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['b615c1c1-79ca-44c9-82c3-87e2bf3bcdb0'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Bone-Broken-1--Health-Injuries-Injured-Joint-Articulation-Inflammation-Pain-Soreness-Osteoarthritis+1.svg`,
            alt: 'Pain Management',
            title: '',
          },
          sidebar: {
            title: 'Pregnancy or Fertility Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
      [
        {
          id: '3a46fdd0-d53d-4da7-bf1d-d0777d2ce278',
          title: 'Purchasing New Medical Equipment',
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['b615c1c1-79ca-44c9-82c3-87e2bf3bcdb0'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Ultrasound-Machine--Health-Pregnancy-Ultrasound-Machine-Medical-Ultrasonography-Diagnostic-Imaging-Echography+1.svg`,
            alt: 'Purchasing New Medical Equipment',
            title: '',
          },
          sidebar: {
            title: 'Surgery or Hospital Visits Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
      [
        {
          id: '21ace1aa-792b-4e84-9b20-2085092ac345',
          title: 'Diabetic Supplies',
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['b615c1c1-79ca-44c9-82c3-87e2bf3bcdb0'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Blood-Tester-1--Health-Laboratory-Blood-Tester-Test-Sugar-Glucose-Level-Sample+1.svg`,
            alt: 'Diabetic Supplies',
            title: '',
          },
          sidebar: {
            title: 'X-Rays, Imaging, Lab Tests Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
      [
        {
          id: '170155a8-d838-4f08-8258-ee8db4912c64',
          title: 'Mental Health Care',
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['b615c1c1-79ca-44c9-82c3-87e2bf3bcdb0'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Psychological-Exam-2--Health-Psychiatry-Psychological-Exam-Divan-Couch-Sofa-Psycologist-Therapy-Session-Patient+1.svg`,
            alt: 'Mental Health Care',
            title: '',
          },
          sidebar: {
            title: 'Head & Neck Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
      [
        {
          id: '68ccb5ab-d6c5-4520-82f0-fc37e210315d',
          title: 'Substance Use and Recovery',
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['b615c1c1-79ca-44c9-82c3-87e2bf3bcdb0'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Capsule-Drug-Weed--Health-Medication-Medicine-Cannabis-Pill-Capsule-Drug-Weed-Pharmacology-Natural+1.svg`,
            alt: 'Substance Use and Recovery',
            title: '',
          },
          sidebar: {
            title: 'X-Rays, Imaging, Lab Tests Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
      [
        {
          id: '6acc709d-8a1f-4f32-930d-9bb57e2be99d',
          title: 'Prescription Medications',
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['b615c1c1-79ca-44c9-82c3-87e2bf3bcdb0'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Pill-Medicine--Health-Medication-Medicine-Pill-Tablet-Aspirin-Capsule-Drug-Pharmacology+1.svg`,
            alt: 'Prescription Medications',
            title: '',
          },
          sidebar: {
            title: 'X-Rays, Imaging, Lab Tests Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
      [
        {
          id: 'b615c1c1-79ca-44c9-82c3-87e2bf3bcdb0',
          title: "I Don't Need These Types of Health Care",
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: [
              '68ccb5ab-d6c5-4520-82f0-fc37e210315d',
              '170155a8-d838-4f08-8258-ee8db4912c64',
              '21ace1aa-792b-4e84-9b20-2085092ac345',
              '3a46fdd0-d53d-4da7-bf1d-d0777d2ce278',
              '72d2e586-cba9-4175-b149-e9737d847900',
              '1657041d-3f71-4cca-838c-bca3a01035ca',
              '6acc709d-8a1f-4f32-930d-9bb57e2be99d',
            ],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Heart-Status--Health-Medical-Condition-Specialties-Cardiology-Heart-Vitality-Status-Battery-Full+1.svg`,
            alt: "I Don't Need These Types of Health Care",
            title: '',
          },
          sidebar: {
            title: 'Head & Neck Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'Some of your plans may offer lower costs for certain providers and facilities. Your answer here will not impact your enrollment or limit what doctors you can see.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        formCssId,
        displayAsGrid,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
