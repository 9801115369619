import { rest } from 'msw';
import { SHERPA_FORM_ID_GROSS_ANNUAL_INCOME } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_NUMBER } from 'constants/questionTypes';

export const sherpaForm205 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_GROSS_ANNUAL_INCOME}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_GROSS_ANNUAL_INCOME;
    const formTitle = `What’s your gross annual income?`;
    const formSubtitle = ``;
    const formContent = ``;
    const formIsSherpa = true;
    const groupedQuestions = [
      [
        {
          id: '982f70f6-af33-44d6-a00c-9c30632e5fe8',
          titleLarge: '',
          title: 'My Gross Annual Income',
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          multipleChoiceOptions: null,
          dependency: null,
          max: 600000,
          min: 1200,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '$',
          suffix: '',
        },
      ],
      [
        {
          id: '0aa4ebc1-f2b9-4036-890f-785c8df84fc7',
          title: 'My Partner’s Gross Annual Income',
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          multipleChoiceOptions: null,
          max: 600000,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '$',
          suffix: '',
          shownDefaultValue: 0,
          allowNullQuestions: true,
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'This is why we ask this question...',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
