import { rest } from 'msw';
import { SHERPA_FORM_ID_HSA_BALANCE } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_NUMBER, INPUT_RADIO } from 'constants/questionTypes';
import { SECONDARY } from 'constants/dependencyStatuses';

export const sherpaForm220 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_HSA_BALANCE}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_HSA_BALANCE;
    const formTitle = `Do you currently have a Health Savings Account (HSA)?`;
    const formSubtitle = ``;
    const formContent = ``;
    const formIsSherpa = true;
    const groupedQuestions = [
      [
        {
          id: '0cba064e-9532-4d23-840b-0cf863b67a90',
          title: '',
          type: INPUT_RADIO,
          multipleChoiceOptions: [
            {
              id: '6dfd4818-fd06-45d5-a097-671c458edb0a',
              title: 'Yes',
            },
            {
              id: '68f41d3d-c5f8-4aa1-898a-3c533df0d85f',
              title: 'No',
            },
          ],
          dependency: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          wipeValueWhenUnchecked: true,
          preventPopupHidden: true,
          img: null,
        },
        {
          id: '9b8989b1-e84c-4326-a6c4-2f58e3eee106',
          title: 'Current HSA Balance',
          subtitle: 'If you’re not sure, give us your best guess!',
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          answerLabelData: {
            prefix: '$',
          },
          dependency: {
            status: SECONDARY,
            questionId: '0cba064e-9532-4d23-840b-0cf863b67a90',
            answerId: '6dfd4818-fd06-45d5-a097-671c458edb0a',
          },
          max: 5000000,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '$',
          suffix: '',
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'Stuff about this',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
