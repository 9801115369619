import { rest } from 'msw';
import { FORM_ID_EMPLOYMENT_RATES } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_RADIO } from 'constants/questionTypes';
import { SUPPORT_EMAIL } from 'constants/config';

// Form ID from the MHM Decision Doc Copy Document: 1
export const form1 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_EMPLOYMENT_RATES}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_EMPLOYMENT_RATES;
    const formTitle =
      'Do you qualify for part-time or full-time premium rates?';
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: 'de41dd9b-3789-4b28-91ad-7ddf395276ae',
          title: '',
          subtitle: '',
          titleLarge: null,
          type: INPUT_RADIO,
          multipleChoiceOptions: [
            {
              id: '5d1bd1aa-e6ae-455a-9299-f0f286194db8',
              image: {
                src: '',
                alt: '',
                title: '',
              },
              title: 'Full-Time',
              bodyText: '',
            },
            {
              id: '8e1043de-b734-4dc1-9169-440661de0297',
              image: {
                src: '',
                alt: '',
                title: '',
              },
              title: 'Part-Time',
              bodyText: '',
            },
          ],
          dependency: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          image: {
            src: '',
            alt: '',
            title: '',
          },
        },
      ],
      [
        {
          id: 'e361dbc6-6bcb-44f1-9c14-07e610ba831d',
          title: 'Do you qualify for your employer’s tobacco premium rates?',
          subtitle: '',
          titleLarge: null,
          type: INPUT_RADIO,
          multipleChoiceOptions: [
            {
              id: '4042c148-f128-4c9c-9418-47038d8ffc05',
              image: {
                src: '',
                alt: '',
                title: '',
              },
              title: 'Yes',
              bodyText: '',
            },
            {
              id: '5dd3cbd3-66e8-4fb8-8252-4237262ade3b',
              image: {
                src: '',
                alt: '',
                title: '',
              },
              title: 'No',
              bodyText: '',
            },
          ],
          dependency: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a1278d35-9e15-4514-ac7f-790a8ca67861',
          title: 'Do you participate in your employer’s wellness program?',
          subtitle: '',
          titleLarge: null,
          type: INPUT_RADIO,
          multipleChoiceOptions: [
            {
              id: 'c4f9628c-7085-4535-98e1-f242c629ea93',
              image: {
                src: '',
                alt: '',
                title: '',
              },
              title: 'Yes',
              bodyText: '',
            },
            {
              id: '13eb115e-ae76-4ec6-9669-1dd560072b4e',
              image: {
                src: '',
                alt: '',
                title: '',
              },
              title: 'No',
              bodyText: '',
            },
          ],
          dependency: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'bbfc90ed-c7fb-442d-a049-6820936c7bb2',
          title: 'What situation applies to you?',
          subtitle: '',
          titleLarge: null,
          type: INPUT_RADIO,
          multipleChoiceOptions: [
            {
              id: '3e86bbb4-360e-4807-8f99-989af49b8c6a',
              image: {
                src: '',
                alt: '',
                title: '',
              },
              title: 'MA Resident',
              bodyText: '',
            },
            {
              id: '9fd92966-7ecc-4338-a214-5c05bddbef0e',
              image: {
                src: '',
                alt: '',
                title: '',
              },
              title: 'ME Resident',
              bodyText: '',
            },
            {
              id: '0fd92966-7ecc-4338-a214-5c05bddbef0f',
              image: {
                src: '',
                alt: '',
                title: '',
              },
              title: 'NH Resident',
              bodyText: '',
            },
            {
              id: '5fd92966-7ecc-4338-a214-5c05bddbef05',
              image: {
                src: '',
                alt: '',
                title: '',
              },
              title: 'Non MA/ME/NH Resident',
              bodyText: '',
            },
          ],
          dependency: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText: `Your employer contributes money to help pay for your health insurance. Your answer(s) will help me find out how much your employer will contribute. This will impact what you see on your report. If you are unsure how to answer, please reach out to us at <a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>`,
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
