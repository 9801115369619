import { rest } from 'msw';

import { MOCK_CBR_ID } from 'constants/mocks/mockQuestionnaire';

export const cbr = rest.get(
  `/api/cbr/${MOCK_CBR_ID}`,
  async (req, res, ctx) => {
    const recommendedPlanData = {
      planName: 'HSA 2700',
      productName: '',
    };
    const planComparisonData = {
      totalSavings: [
        {
          name: 'HSA 2700',
          amount: 3257,
        },
        {
          name: 'HRA 5000',
          amount: 1731,
        },
        {
          name: 'PPO 500',
          amount: 1406,
        },
        {
          name: 'FSA 3500',
          amount: 0,
        },
      ],
    };
    const theoCallout = {
      summaryText: 'Wondering what to do next?',
      bodyText:
        'If your health needs are NOT changing next year, download your report then navigate to your enrollment platform and choose the highest savings plan. If your health needs WILL likely change (which is probable!), answer some questions on the next few pages and receive an up-to-date report.',
    };
    const expandableCards = [
      {
        title: 'How we calculate this:',
        body: `Based on your health insurance claims, we calculated the total cost of each health plan, then ranked them in order of savings. Total costs include your annual premium plus estimated out-of-pocket costs, minus any employer HSA/HRA contributions. We used claims from [date range] for you and any family members on the plan.`,
      },
    ];
    const cbrItems = [
      {
        name: 'HSA 2700',
        deductibleIndividual: 5000,
        deductibleFamily: 10000,
        outOfPocketIndividual: 6500,
        outOfPocketFamily: 11010,
        employerHraContribution: 10,
        annualPremium: 4050,
      },
      {
        name: 'HRA 5000',
        deductibleIndividual: 2700,
        deductibleFamily: 5400,
        outOfPocketIndividual: 2700,
        outOfPocketFamily: 5400,
        employerHraContribution: 0,
        annualPremium: 2626,
      },
      {
        name: 'FSA 3500',
        deductibleIndividual: 3500,
        deductibleFamily: 7000,
        outOfPocketIndividual: 3500,
        outOfPocketFamily: 7000,
        employerHraContribution: 5,
        annualPremium: 3051,
      },
    ];
    const enrollUrl = 'https://google.com';
    const claimDateStart = '01/01/2021';
    const claimDateEnd = '12/31/2021';
    const planDateStart = 'January 1, 2022';
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        theoCallout,
        expandableCards,
        recommendedPlanData,
        planComparisonData,
        cbrItems,
        enrollUrl,
        claimDateStart,
        claimDateEnd,
        planDateStart,
      }),
    );
  },
);
