import { rest } from 'msw';
import { SHERPA_FORM_ID_EMERGENCY_SAVINGS } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';
import { SECONDARY } from 'constants/dependencyStatuses';

export const sherpaForm207 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_EMERGENCY_SAVINGS}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_EMERGENCY_SAVINGS;
    const formTitle = `Do you have any of the following...`;
    const formSubtitle = ``;
    const formContent = ``;
    const allowNull = true;
    const formIsSherpa = true;
    const groupedQuestions = [
      [
        {
          id: 'e0a5a662-4484-4ce4-9640-471906043a69',
          title: 'Emergency Savings',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          required: false,
          selectedAnswer: null,
          wipeValueWhenUnchecked: true,
          img: null,
        },
        {
          id: 'a7188823-82dc-4133-a022-be0591f3d321',
          title: 'Total Emergency Savings',
          subtitle: 'If you’re not sure, give us your best guess!',
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          answerLabelData: {
            prefix: '$',
          },
          dependency: {
            status: SECONDARY,
            questionId: 'e0a5a662-4484-4ce4-9640-471906043a69',
            answerId: null,
          },
          max: 1000000,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '$',
          suffix: '',
        },
      ],
      [
        {
          id: 'f7e52cb4-3d1b-4343-a06d-b6eaf53b6290',
          title: 'Pension',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          wipeValueWhenUnchecked: true,
          img: null,
        },
        {
          id: '802f35df-6849-4152-9763-fde9b4d0238e',
          title: 'Pension Monthly Payment',
          subtitle: 'If you’re not sure, give us your best guess!',
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          answerLabelData: {
            prefix: '$',
            suffix: '',
          },
          dependency: {
            status: SECONDARY,
            questionId: 'f7e52cb4-3d1b-4343-a06d-b6eaf53b6290',
            answerId: null,
          },
          max: 500000,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '$',
          suffix: 'per month',
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'This is why we ask this question...',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
        allowNull,
      }),
    );
  },
);
