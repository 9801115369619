// @flow

const initialState = {
  auth: {
    authenticated: false,
    token: '',
    expiresIn: '',
  },
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirth: '',
};

export type AuthType = {
  authenticated: boolean,
  token: string,
  expiresIn: string,
};

export type PersonalResponsesType = {
  coverageOptions: Object,
};

export type DependentsType = {
  spouse: Object,
  children: Array,
};

export type UserType = {
  auth: AuthType,
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  dateOfBirth: string,
  gender: string,
  sexAtBirth: string,
  personalResponses: PersonalResponsesType,
  dependents: DependentsType,
};

const Auth: Function = (
  { authenticated, token, expiresIn }: AuthType = initialState.auth,
) => ({
  authenticated,
  token,
  expiresIn,
});

const User: Function = ({
  auth,
  id,
  firstName,
  lastName,
  email,
  dateOfBirth,
  gender,
  sexAtBirth,
  personalResponses,
  dependents,
}: UserType = initialState) => ({
  auth: Auth(auth),
  id,
  firstName,
  lastName,
  email,
  dateOfBirth,
  gender,
  sexAtBirth,
  personalResponses,
  dependents,
});

export default User;
