import { rest } from 'msw';
import { FORM_ID_LAST_YEAR_CLINICS } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN } from 'constants/questionTypes';
import { SECONDARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 23
export const form23 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_LAST_YEAR_CLINICS}`,
  (req, res, ctx) => {
    const formId = FORM_ID_LAST_YEAR_CLINICS;
    const formTitle =
      "Most people don't plan on using these services, but would you like me to include them just in case?";
    const formContent = '';
    const formCssId = '';
    const groupedQuestions = [
      [
        {
          id: '44c5d612-5077-46d9-8857-ffaa7e6ce815',
          title: 'Emergency Room',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['4c684efc-ab2e-4e8d-920c-58e528e20ec4'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          sidebar: {},
        },
        {
          id: '88c5d612-5077-46d9-8857-ffaa7e6ce815',
          subtitle: '',
          titleLarge: 'How many times per year?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '44c5d612-5077-46d9-8857-ffaa7e6ce815',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '1e3050a6-a4b3-496b-ab10-9ec3057725dd',
          subtitle: '',
          titleLarge: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '44c5d612-5077-46d9-8857-ffaa7e6ce815',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'e4e98156-abc8-467c-ac2a-9a8f27152905',
          subtitle: '',
          titleLarge: '',
          title: 'Dave',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '44c5d612-5077-46d9-8857-ffaa7e6ce815',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a85ed7c4-6871-4acd-a21d-f0d0c4c752ac',
          title: 'Urgent care',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['4c684efc-ab2e-4e8d-920c-58e528e20ec4'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          sidebar: {},
        },
        {
          id: '19acfde5-06d0-4025-a461-157ec531b248',
          subtitle: '',
          titleLarge: 'How many times per year?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a85ed7c4-6871-4acd-a21d-f0d0c4c752ac',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '456a1625-cb03-4212-ade0-5d107bb0f466',
          subtitle: '',
          titleLarge: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a85ed7c4-6871-4acd-a21d-f0d0c4c752ac',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'fd8a6281-7093-431d-81aa-762f2daf504d',
          subtitle: '',
          titleLarge: '',
          title: 'Dave',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a85ed7c4-6871-4acd-a21d-f0d0c4c752ac',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '09173c8a-4e34-4307-9fc0-d6921ee5c1f9',
          title: 'Convenience Care Clinic',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['4c684efc-ab2e-4e8d-920c-58e528e20ec4'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          sidebar: {},
        },
        {
          id: '8d691805-f5a7-4d58-8936-b33427a19159',
          subtitle: '',
          titleLarge: 'How many times per year?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '09173c8a-4e34-4307-9fc0-d6921ee5c1f9',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '9e7633dc-a462-4d96-873a-9bb9f523bd2a',
          subtitle: '',
          titleLarge: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '09173c8a-4e34-4307-9fc0-d6921ee5c1f9',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'b08cd285-e22e-4cfb-a282-2d8e78f664d1',
          subtitle: '',
          titleLarge: '',
          title: 'Dave',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '09173c8a-4e34-4307-9fc0-d6921ee5c1f9',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '4c684efc-ab2e-4e8d-920c-58e528e20ec4',
          title: 'No, thank you',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            questionId: null,
            answerId: null,
            disableOnQuestionIds: [
              '44c5d612-5077-46d9-8857-ffaa7e6ce815',
              'a85ed7c4-6871-4acd-a21d-f0d0c4c752ac',
              '09173c8a-4e34-4307-9fc0-d6921ee5c1f9',
            ],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'If you are unsure, consider if you have used these services last year.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formCssId,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
