import sessionStorage from 'redux-persist/es/storage/session';
import {
  STORAGE_AUTH_TOKEN,
  STORAGE_FIRST_NAME,
  STORAGE_FORM_ID,
  STORAGE_HAS_SUBSCRIBER_ERROR,
  STORAGE_CBR_ID,
  STORAGE_QUESTIONNAIRE_ID,
  STORAGE_QUESTIONNAIRE_IS_COMPLETE,
  STORAGE_EDITING_RESPONSES,
  STORAGE_SHERPA_ENCRYPTED_CONTENT,
} from 'constants/sessionStorage';

const clearUser = async () => {
  // await sessionStorage.removeItem(STORAGE_AUTH_TOKEN);
  await sessionStorage.removeItem(STORAGE_FIRST_NAME);
  await sessionStorage.removeItem(STORAGE_FORM_ID);
  await sessionStorage.removeItem(STORAGE_HAS_SUBSCRIBER_ERROR);
  await sessionStorage.removeItem(STORAGE_CBR_ID);
  await sessionStorage.removeItem(STORAGE_QUESTIONNAIRE_ID);
  await sessionStorage.removeItem(STORAGE_QUESTIONNAIRE_IS_COMPLETE);
  await sessionStorage.removeItem(STORAGE_EDITING_RESPONSES);
  await sessionStorage.removeItem(STORAGE_SHERPA_ENCRYPTED_CONTENT);
};

export default clearUser;
