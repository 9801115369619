import { rest } from 'msw';
import { FORM_ID_MEDICARE_ELIGIBILITY } from 'constants/mocks/formIds';
import { INPUT_RADIO } from 'constants/questionTypes';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';

// Form ID from the MHM Decision Doc Copy Document: 34
export const form34 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_MEDICARE_ELIGIBILITY}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_MEDICARE_ELIGIBILITY;
    const formTitle = 'Are you enrolled in Medicare?';
    const formContent = '';
    const formCssId = '';
    const groupedQuestions = [
      [
        {
          id: '259e038c-4881-4334-9abd-c4ca375d9efd',
          title: '',
          subtitle: '',
          titleLarge: null,
          type: INPUT_RADIO,
          multipleChoiceOptions: [
            {
              id: 'de1cb516-3ecd-48c6-b53f-18bc51fccb4c',
              title: 'Yes',
            },
            {
              id: '680f9d26-8838-47b8-ba8f-adf0c48d2b6f',
              title: 'No',
            },
          ],
          dependency: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'Did you know if you are enrolled in Medicare you will not be able to contribute to a Health Savings Account (HSA)? Click here to view more information.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formCssId,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
