import { rest } from 'msw';
import { FORM_ID_HEALTH_EVENTS } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN } from 'constants/questionTypes';

// Form ID from the MHM Decision Doc Copy Document: 8
export const form8 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_HEALTH_EVENTS}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_HEALTH_EVENTS;
    const formTitle =
      'Here are some common health events. Do any of these apply to you or your household?';
    const formContent = '';
    const displayAsGrid = true;
    const formCssId = 'health-events';
    const formSubtitle = 'Add as many as you need';
    const groupedQuestions = [
      [
        {
          id: 'c3caf868-285c-417b-81e6-fc812d8a5ddb',
          title: 'Specialists Visits or Special Health Consults',
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            id: '00000000-0000-0000-0000-000000000000',
            disableOnQuestionIds: ['8ab81c15-ebc0-4085-be66-50f0972c5b20'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: ['null'],
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Magnifier-Egk--Health-Monitoring-Examination-Pulse-Ekg-Ecg-Electrocardiogram-Magnifier+1.svg`,
            alt: 'Special Visits',
            title: '',
          },
          sidebar: {
            title: 'Head & Neck Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
      [
        {
          id: '5e23ee7f-6820-457d-af21-59aae273bbe3',
          title: 'Pregnancy or Fertility Treatment',
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['8ab81c15-ebc0-4085-be66-50f0972c5b20'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: ['true'],
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Pregnancy-3--Health-Pregnancy-Fetus-Development-Offspring-Pregnant-Belly-Gestation+1.svg`,
            alt: 'Pregnancy Treatment',
            title: '',
          },
          sidebar: {
            title: 'Pregnancy or Fertility Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
      [
        {
          id: '0dae85fd-7f22-489f-b6fd-52aa6aa67d59',
          title: 'Surgeries and Procedures',
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['8ab81c15-ebc0-4085-be66-50f0972c5b20'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: ['true'],
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Surgery-Suture-3--Health-Surgery-Suture-Skin-Medical-Intervention-Surgical-Wound-Stitches+1.svg`,
            alt: 'Surgery Visits',
            title: '',
          },
          sidebar: {
            title: 'Surgery or Hospital Visits Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
      [
        {
          id: '4165b51f-43af-4cc0-bcf0-240f2d21d732',
          title: 'X-Rays, Imaging, or Lab Tests',
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['8ab81c15-ebc0-4085-be66-50f0972c5b20'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/X-Ray-3--Health-Monitoring-Examination-X-Ray-Radiation-Radiography-Medical-Imaging-Ribs-Male+1.svg`,
            alt: 'X-ray Tests',
            title: '',
          },
          sidebar: {
            title: 'X-Rays, Imaging, Lab Tests Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
      [
        {
          id: '8ab81c15-ebc0-4085-be66-50f0972c5b20',
          title: "I don't need any of these services.",
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: [
              '4165b51f-43af-4cc0-bcf0-240f2d21d732',
              '0dae85fd-7f22-489f-b6fd-52aa6aa67d59',
              '5e23ee7f-6820-457d-af21-59aae273bbe3',
              'c3caf868-285c-417b-81e6-fc812d8a5ddb',
            ],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: ['false'],
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Heart-Status--Health-Medical-Condition-Specialties-Cardiology-Heart-Vitality-Status-Battery-Full+1.svg`,
            alt: 'Does not apply',
            title: '',
          },
          sidebar: {
            title: 'Head & Neck Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'Some of your plans may offer lower costs for certain providers and facilities. Your answer here will not impact your enrollment or limit what doctors you can see.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        formCssId,
        displayAsGrid,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
