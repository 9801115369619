import restCall from '../rest';

const { restPost } = restCall;

export const post = (additionalParams) => async (filters) => {
  const params = {
    ...additionalParams,
    path: 'auth/sso/authenticate',
  };
  return restPost(params)(filters);
};

export const postEmail = (additionalParams) => async (filters) => {
  const params = {
    ...additionalParams,
    path: 'auth/sso/email',
  };
  return restPost(params)(filters);
};
