// @flow

const initialState = {
  loading: false,
  errorMessage: '',
  data: {},
};

export type BaseType = {|
  loading: boolean,
  errorMessage: string,
  data: any,
|};

const Base: Function = ({
  loading,
  errorMessage,
  data,
}: BaseType = initialState) => ({
  loading,
  errorMessage,
  data,
});

export default Base;
