import { rest } from 'msw';
import { FORM_ID_DEPENDENTS_VISION_COMBINED_CHILDREN } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';
import { SECONDARY } from '../../constants/dependencyStatuses';

export const formDependentsVisionCombinedWithChildren = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_DEPENDENTS_VISION_COMBINED_CHILDREN}`,
  (req, res, ctx) => {
    const formId = FORM_ID_DEPENDENTS_VISION_COMBINED_CHILDREN;
    const formTitle = `What about vision? Will the same people on your health insurance be on that as well?`;
    const formSubtitle = null;
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: 'cf342c1e-3df9-4048-95a5-0cafa18b4380',
          title: 'My Partner/Spouse',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['2c79d291-a391-4bc6-ab9a-b0a52433f989'],
          },
        },
      ],
      [
        {
          id: 'fdaeb68b-6354-4382-a01b-b2d6d4bb37b0',
          title: 'My Child(ren)',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: ['true'],
          img: null,
          dependency: {
            disableOnQuestionIds: ['2c79d291-a391-4bc6-ab9a-b0a52433f989'],
          },
        },
        {
          id: 'e0c23b4f-d743-4f2d-bcb0-a04352cf0596',
          title: 'Add Children',
          subtitle: 'How many children do you have?',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          answerLabelData: {
            singular: 'child',
            plural: 'children',
          },
          dependency: {
            status: SECONDARY,
            questionId: 'fdaeb68b-6354-4382-a01b-b2d6d4bb37b0',
            answerId: null,
          },
          max: 26,
          min: 1,
          required: true,
          selectedAnswer: [2],
          img: null,
        },
      ],
      [
        {
          id: '2c79d291-a391-4bc6-ab9a-b0a52433f989',
          title: `Just Me`,
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: [
              'cf342c1e-3df9-4048-95a5-0cafa18b4380',
              'fdaeb68b-6354-4382-a01b-b2d6d4bb37b0',
            ],
          },
        },
      ],
    ];
    const theoCallout = {
      summaryText: `Here is some text about why I'm asking this again here. And telling them that they have the option to have dependents on dental and vision even if they didn't on their health care.`,
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
