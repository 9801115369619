import restCall from '../rest';

const { restGet, restList, restPost, restPatch, restPut, restDelete } =
  restCall;

const config = (additionalParams = {}) => {
  return {
    ...additionalParams,
    path: 'employers',
  };
};

export const get = (additionalParams) => async (filters) => {
  const params = config(additionalParams);
  return restGet(params)(filters);
};

export const list = (additionalParams) => async (filters) => {
  const params = config(additionalParams);
  return restList(params)(filters);
};

export const post = (additionalParams) => async (filters) => {
  const params = config(additionalParams);
  return restPost(params)(filters);
};

export const patch = (additionalParams) => async (filters) => {
  const params = config(additionalParams);
  return restPatch(params)(filters);
};

export const put = (additionalParams) => async (filters) => {
  const params = config(additionalParams);
  return restPut(params)(filters);
};

export const del = (additionalParams) => async (filters) => {
  const params = config(additionalParams);
  return restDelete(params)(filters);
};
