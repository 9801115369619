import sessionStorage from 'redux-persist/es/storage';
import {
  STORAGE_JOB_ID,
  STORAGE_JOB_HAS_CBR,
  STORAGE_SCHEDULE_CODE,
  STORAGE_IS_SHERPA,
} from 'constants/sessionStorage';

const clearJob = async () => {
  await sessionStorage.removeItem(STORAGE_JOB_ID);
  await sessionStorage.removeItem(STORAGE_JOB_HAS_CBR);
  await sessionStorage.removeItem(STORAGE_SCHEDULE_CODE);
  await sessionStorage.removeItem(STORAGE_IS_SHERPA);
};

export default clearJob;
