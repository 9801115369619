import { rest } from 'msw';
import { FORM_ID_LAB_WORK_IMAGING } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';
import { SECONDARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 20
export const form20 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_LAB_WORK_IMAGING}`,
  (req, res, ctx) => {
    const formId = FORM_ID_LAB_WORK_IMAGING;
    const formTitle =
      'Do you think you might need to get any of these imaging services?';
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: 'e361dbc6-6bcb-44f1-9c14-07e610ba831d',
          title: 'CT Scan',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '31ed3dba-ed1a-46c3-8da2-924c9c9d6fd1',
          titleLarge: 'Number per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'e361dbc6-6bcb-44f1-9c14-07e610ba831d',
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
        {
          id: '5a0afbab-bbb2-4894-b65a-1fe5e8e74b54',
          title: 'David',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'e361dbc6-6bcb-44f1-9c14-07e610ba831d',
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
        {
          id: '0484629d-6358-421e-82d9-5ee47cba5c00',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'e361dbc6-6bcb-44f1-9c14-07e610ba831d',
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
      ],
      [
        {
          id: '913ce029-c19e-494f-892b-42d643e11462',
          title: 'EKG',
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          sidebar: {},
        },
        {
          id: 'c0476d5f-0835-446f-995e-45237b4e97c6',
          titleLarge: 'Number per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '913ce029-c19e-494f-892b-42d643e11462',
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
        {
          id: '6e99761f-a9d5-40eb-98eb-1ce41d4b9eef',
          title: 'David',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '913ce029-c19e-494f-892b-42d643e11462',
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
        {
          id: 'dbd0fe72-7e43-474f-a519-aab804583c3e',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '913ce029-c19e-494f-892b-42d643e11462',
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
      ],
      [
        {
          id: 'c26d371f-3147-4752-8a23-1f6e33e86294',
          title: 'MRI',
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            questionId: null,
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          sidebar: {},
        },
        {
          id: '86cb0a13-76d6-45a6-8638-b5d70424dbda',
          titleLarge: 'Number per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'c26d371f-3147-4752-8a23-1f6e33e86294',
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
        {
          id: 'c3c40ceb-9df4-4a39-91e4-e2ff1713a41e',
          title: 'David',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'c26d371f-3147-4752-8a23-1f6e33e86294',
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
        {
          id: '484ac049-02c4-4961-8103-881fbc30bf9f',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'c26d371f-3147-4752-8a23-1f6e33e86294',
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
      ],
      [
        {
          id: '4b38a4a4-fdcb-4ca3-9605-7222a84dcaa8',
          title: 'PET Scan',
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            questionId: null,
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          sidebar: {},
        },
        {
          id: '4842a282-c1ff-4df5-9f3d-1244ba8d356a',
          titleLarge: 'Number per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '4b38a4a4-fdcb-4ca3-9605-7222a84dcaa8',
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
        {
          id: '5885a62a-033d-4d98-8ae4-98bc90a2eb61',
          title: 'David',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '4b38a4a4-fdcb-4ca3-9605-7222a84dcaa8',
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
        {
          id: '5939aeb4-da13-4d7f-86a9-2d9c0a6f422d',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '4b38a4a4-fdcb-4ca3-9605-7222a84dcaa8',
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
      ],
      [
        {
          id: '7e70c685-b492-44cb-91dd-b1c4e1d1938b',
          title: 'X-ray',
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            questionId: null,
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          sidebar: {},
        },
        {
          id: 'b0c673b0-15d3-4c92-8f6f-aeb41594d1ef',
          titleLarge: 'Number per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '7e70c685-b492-44cb-91dd-b1c4e1d1938b',
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
        {
          id: '6d7c718a-418d-4eac-bd20-ad05b7145c98',
          title: 'David',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '7e70c685-b492-44cb-91dd-b1c4e1d1938b',
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
        {
          id: '3d7393b9-576a-4c7f-854e-9e0211a0571c',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '7e70c685-b492-44cb-91dd-b1c4e1d1938b',
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
      ],
      [
        {
          id: '946529ce-46ae-4516-9275-193ebe360e52',
          title: 'None of these ',
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            questionId: null,
            answerId: null,
            disableOnQuestionIds: [
              '7e70c685-b492-44cb-91dd-b1c4e1d1938b',
              '4b38a4a4-fdcb-4ca3-9605-7222a84dcaa8',
              'c26d371f-3147-4752-8a23-1f6e33e86294',
              '913ce029-c19e-494f-892b-42d643e11462',
              'e361dbc6-6bcb-44f1-9c14-07e610ba831d',
            ],
          },
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText: `<b>Based on your surgeries, you might also need these services...</b>`,
      bodyText: `<b style="margin-top: 16px; display: block">MRI</b> <span>This is usually recommended whenever you get a head & neck surgery.</span> <b style="margin-top: 16px; display: block">X-RAY</b><span>This is usually recommended whenever you get a head & neck surgery.</span>`,
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
