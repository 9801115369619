// @flow
import produce from 'immer';

import Base from 'models/base';
import User from 'models/user';
import {
  USER_ASYNC_START,
  USER_ASYNC_FAIL,
  USER_ASYNC_SUCCESS,
  USER_ACTION_SUCCESS,
} from 'actions/user';

export const initialState = Base({
  data: User(),
  loading: false,
  errorMessage: '',
});

const actionsMap = {
  [USER_ASYNC_START]: (state) => {
    const nextState = produce(state, (draft) => {
      draft.loading = true;
      draft.errorMessage = '';
    });
    return nextState;
  },
  [USER_ASYNC_SUCCESS]: (state, action) => {
    const nextState = produce(state, (draft) => {
      draft.loading = false;
      draft.errorMessage = '';
      draft.data = User(action.data);
    });
    return nextState;
  },
  [USER_ASYNC_FAIL]: (state, action) => {
    const nextState = produce(state, (draft) => {
      draft.loading = false;
      draft.errorMessage = action.errorMessage;
    });
    return nextState;
  },
  [USER_ACTION_SUCCESS]: (state, action) => {
    const nextState = produce(state, (draft) => {
      draft.data = User(action.data);
    });
    return nextState;
  },
};

export default function user(
  state: Base = initialState,
  action: { type: string, data?: any } = {},
) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
