export const SHERPA_FORM_ID_HAS_DEPENDENTS =
  '375ea701-3dff-466d-89c0-b8f483c1a610';
export const SHERPA_FORM_ID_DEPENDENTS_AGE =
  'd28adf93-06a7-40fc-8fa0-d17ef42a32e6';
export const SHERPA_FORM_ID_WHOS_ON_INSURANCE_PLAN =
  '4238738c-9447-439b-a7e1-7f3e32c393c4';
export const SHERPA_FORM_ID_LIVING_SITUATION =
  '1cf1821c-4239-464a-ad8e-850823fe76bb';
export const SHERPA_FORM_ID_GROSS_ANNUAL_INCOME =
  'a869029b-d874-4dd0-ab04-48a7c5242b2c';
export const SHERPA_FORM_ID_MONTHLY_BASIC_ESSENTIAL_EXPENSES =
  'e48a7f40-f220-44ac-82ef-e43e289a052c';
export const SHERPA_FORM_ID_EMERGENCY_SAVINGS =
  'c75669e8-e02c-4794-999b-fa6b8bdec212';
export const SHERPA_FORM_ID_MORTGAGE_PAYMENTS =
  '9e998a11-3af4-46b9-8498-9e7c0bdc3009';
export const SHERPA_FORM_ID_MONTHLY_RENT =
  'ec279197-ff57-40ad-a472-a07dc3a94c09';
export const SHERPA_FORM_ID_INSURANCE_TYPES =
  '139f711d-fb77-435d-83fb-24803d570344';
export const SHERPA_FORM_ID_CRITICAL_ILLNESS =
  '203e0b77-a624-41b9-aba2-b64e08fa08e5';
export const SHERPA_FORM_ID_HOSPITAL_INSURANCE =
  '85d5e9f3-5405-4b87-98f5-f8056992ceec';
export const SHERPA_FORM_ID_ACCIDENT_INSURANCE =
  '5331ef0c-5252-4dab-8bf7-623e6813df9a';
export const SHERPA_FORM_ID_ACCIDENTAL_DEATH_DISMEMBERMENT =
  'b8bc815c-5155-47c2-a8fa-d6330ce86efc';
export const SHERPA_FORM_ID_PRIVATE_LIFE_INSURANCE =
  'd0a06002-ad90-4714-acf7-e364624efb64';
export const SHERPA_FORM_ID_FUTURE_EXPENSE_SELECTOR =
  '280e8316-bfb3-40c6-8f55-8dfa63b52ecc';
export const SHERPA_FORM_ID_LEGACY_SUM = '3c779921-bf1d-4824-a6e9-d963e749bdcb';
export const SHERPA_FORM_ID_SHORT_TERM_DISABILITY =
  '7767cbc8-f8af-40ef-956c-382ec3a11899';
export const SHERPA_FORM_ID_LONG_TERM_DISABILITY =
  'ff1afa94-1e0f-4c73-b6b9-be5c95cded5e';
export const SHERPA_FORM_ID_HSA_BALANCE =
  '9d1df1fa-5042-4201-b58a-ddd909292361';

export const sherpaForms = [
  {
    formId: SHERPA_FORM_ID_HAS_DEPENDENTS,
    number: 201,
    title: 'Sherpa - Has Dependents',
  },
  {
    formId: SHERPA_FORM_ID_DEPENDENTS_AGE,
    number: 202,
    title: 'Sherpa - Dependents Ages',
  },
  {
    formId: SHERPA_FORM_ID_WHOS_ON_INSURANCE_PLAN,
    number: 203,
    title: `Sherpa - Who's On Insurance Plan`,
  },
  {
    formId: SHERPA_FORM_ID_LIVING_SITUATION,
    number: 204,
    title: 'Sherpa - Living Situation',
  },
  {
    formId: SHERPA_FORM_ID_GROSS_ANNUAL_INCOME,
    number: 205,
    title: 'Sherpa - Gross Annual Income',
  },
  {
    formId: SHERPA_FORM_ID_MONTHLY_BASIC_ESSENTIAL_EXPENSES,
    number: 206,
    title: 'Sherpa - Monthly Basic Essential Expenses',
  },
  {
    formId: SHERPA_FORM_ID_EMERGENCY_SAVINGS,
    number: 207,
    title: 'Sherpa - Emergency Savings',
  },
  {
    formId: SHERPA_FORM_ID_MORTGAGE_PAYMENTS,
    number: 208,
    title: 'Sherpa - Mortgage Payments',
  },
  {
    formId: SHERPA_FORM_ID_MONTHLY_RENT,
    number: 209,
    title: 'Sherpa - Monthly Rent',
  },
  {
    formId: SHERPA_FORM_ID_INSURANCE_TYPES,
    number: 210,
    title: 'Sherpa - Insurance Types',
  },
  {
    formId: SHERPA_FORM_ID_CRITICAL_ILLNESS,
    number: 211,
    title: 'Sherpa - Critical Illness',
  },
  {
    formId: SHERPA_FORM_ID_HOSPITAL_INSURANCE,
    number: 212,
    title: 'Sherpa - Hospital Insurance',
  },
  {
    formId: SHERPA_FORM_ID_ACCIDENT_INSURANCE,
    number: 213,
    title: 'Sherpa - Accident Insurance',
  },
  {
    formId: SHERPA_FORM_ID_ACCIDENTAL_DEATH_DISMEMBERMENT,
    number: 214,
    title: 'Sherpa - Accidental Death & Dismemberment',
  },
  {
    formId: SHERPA_FORM_ID_PRIVATE_LIFE_INSURANCE,
    number: 215,
    title: 'Sherpa - Private Life Insurance',
  },
  {
    formId: SHERPA_FORM_ID_FUTURE_EXPENSE_SELECTOR,
    number: 216,
    title: 'Sherpa - Future Expense Selector',
  },
  {
    formId: SHERPA_FORM_ID_LEGACY_SUM,
    number: 217,
    title: 'Sherpa - Legacy Sum & Outstanding Debts',
  },
  {
    formId: SHERPA_FORM_ID_SHORT_TERM_DISABILITY,
    number: 218,
    title: 'Sherpa - Short Term Disability',
  },
  {
    formId: SHERPA_FORM_ID_LONG_TERM_DISABILITY,
    number: 219,
    title: 'Sherpa - Long Term Disability',
  },
  {
    formId: SHERPA_FORM_ID_HSA_BALANCE,
    number: 220,
    title: 'Sherpa - HSA Balance',
  },
];
