import { rest } from 'msw';
import { FORM_ID_DENTAL_VISION } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN } from 'constants/questionTypes';

export const formDentalVision = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_DENTAL_VISION}`,
  (req, res, ctx) => {
    const formId = FORM_ID_DENTAL_VISION;
    const formTitle = `Are you interested in your company's dental or vision plans?`;
    const formSubtitle = `Select as many as you'd like and I'll include them on the final report.`;
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: '62ea8ceb-5095-4cc2-b543-7aad2a7a2ac3',
          title: 'Dental',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['7416d11c-2c3a-4c03-9c26-f524eff290d5'],
          },
        },
      ],
      [
        {
          id: '80b96942-4344-4fc4-8922-5e41f3e3e98d',
          title: 'Vision',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['7416d11c-2c3a-4c03-9c26-f524eff290d5'],
          },
        },
      ],
      [
        {
          id: '7416d11c-2c3a-4c03-9c26-f524eff290d5',
          title: `I'm not interested.`,
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: [
              '62ea8ceb-5095-4cc2-b543-7aad2a7a2ac3',
              '80b96942-4344-4fc4-8922-5e41f3e3e98d',
            ],
          },
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'Testing - Theo summary text for Dental/Vision combined question screen.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
