import { rest } from 'msw';
import { FORM_ID_ALLERGIES } from 'constants/mocks/formIds';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { SECONDARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 25
export const form25 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_ALLERGIES}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_ALLERGIES;
    const formTitle =
      'Does anyone in your household need treatment for allergies?';
    const formSubtitle = '';
    const formContent = '';
    const groupedQuestions = [
      [
        {
          id: 'a827dbc6-6bcb-44f1-9c14-07e610ba7351',
          title: 'Testing',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'ecdce6b2-cd81-4ac6-a52e-e75fb822aa88',
          titleLarge: 'How many tests will each person need this year?',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a827dbc6-6bcb-44f1-9c14-07e610ba7351',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'f0d0334a-4094-4fcd-ad78-520e8be7f955',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a827dbc6-6bcb-44f1-9c14-07e610ba7351',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '0700bf3e-4876-4337-8a3d-1dd6b9b79eff',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a827dbc6-6bcb-44f1-9c14-07e610ba7351',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'b10bbfa9-2ce0-4774-a165-6172215b1c71',
          title: 'Injections',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '80f526f4-ff8b-4546-8d4c-7bc316986555',
          titleLarge: 'How many injections will each person need per month?',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'b10bbfa9-2ce0-4774-a165-6172215b1c71',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '4bd095a2-45ee-48aa-a239-2b0c2152efab',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'b10bbfa9-2ce0-4774-a165-6172215b1c71',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '8617d535-13c8-4c78-ae74-79f3a9e06ee7',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'b10bbfa9-2ce0-4774-a165-6172215b1c71',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        "Don't worry, at the end of this section, you can add or remove services. You can also update your responses once you get your results.",
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formSubtitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
