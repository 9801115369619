import { rest } from 'msw';
import { SHERPA_FORM_ID_PRIVATE_LIFE_INSURANCE } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_NUMBER } from 'constants/questionTypes';

export const sherpaForm215 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_PRIVATE_LIFE_INSURANCE}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_PRIVATE_LIFE_INSURANCE;
    const formHeader = 'LIFE INSURANCE';
    const formTitle = 'What is the payout of your private life insurance?';
    const formSubtitle = 'If you’re not sure, give us your best guess!';
    const formContent = null;
    const formIsSherpa = true;
    const allowNull = true;
    const groupedQuestions = [
      [
        {
          id: 'c00f313f-6f66-49d5-b6b7-24515414dfb9',
          titleLarge: '',
          title: 'Total Life Insurance Payout',
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          multipleChoiceOptions: null,
          dependency: null,
          max: 5000000,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '$',
          suffix: '',
          shownDefaultValue: 0,
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'Stuff about this',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formHeader,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
        allowNull,
      }),
    );
  },
);
