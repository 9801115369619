import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import rootReducer from 'reducers';

import logger from 'middlewares/logger';

const persistConfig = {
  key: 'root',
  whitelist: ['auth', 'user'],
  storage: storageSession,
};

const enhancer =
  process.env.LOGGER_ON === 'true'
    ? compose(applyMiddleware(createDebounce(), thunk, logger))
    : compose(applyMiddleware(createDebounce(), thunk));
export default function configureStore(initialState) {
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(persistedReducer, initialState, enhancer);
  const persistor = persistStore(store);
  if (module.hot) {
    module.hot.accept('../reducers', () =>
      store.replaceReducer(require('../reducers')),
    );
  }
  return { store, persistor };
}
