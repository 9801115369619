import { rest } from 'msw';

import { FORM_ID_COVERAGE_CONFIRMATION } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { FORM_TYPE_COVERAGE_CONFIRMATION } from 'constants/formTypes';

export const formCoverageConfirmation = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_COVERAGE_CONFIRMATION}`,
  async (req, res, ctx) => {
    const theoCallout = {
      summaryText:
        'Testing - Theo summary text for combined Dental & Vision coverage confirmation.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId: FORM_ID_COVERAGE_CONFIRMATION,
        formType: FORM_TYPE_COVERAGE_CONFIRMATION,
        theoCallout,
        selectedDental: true,
        selectedVision: true,
      }),
    );
  },
);
