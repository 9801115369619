import { rest } from 'msw';
import { FORM_ID_ONLY_DENTAL } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_RADIO } from 'constants/questionTypes';

export const formOnlyDental = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_ONLY_DENTAL}`,
  (req, res, ctx) => {
    const formId = FORM_ID_ONLY_DENTAL;
    const formTitle = `Are you interested in learning more about your company's dental plan?`;
    const formSubtitle = `I can include information about these plans in your plan guidance.`;
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: 'eb3b4ef1-f3fa-484c-bc89-12495620a8f7',
          title: '',
          type: INPUT_RADIO,
          multipleChoiceOptions: [
            {
              id: 'a5b38f5b-4437-42c2-8bef-ad0c8f8f80c4',
              title: 'Yes',
            },
            {
              id: '00051ddf-235a-47ae-a2ec-41149b8cc7be',
              title: 'No',
            },
          ],
          dependency: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'Testing - Theo summary text for Dental Only question screen.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
