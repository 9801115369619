import { rest } from 'msw';
import {
  DATA_TYPE_BOOLEAN,
  DATA_TYPE_DATE,
  DATA_TYPE_NUMBER,
  DATA_TYPE_STRING,
} from 'constants/dataTypes';
import { PROGRESS_RESULTS } from 'constants/progressStatuses';

export const sidebarResults = rest.get(
  `/api/sidebar/${PROGRESS_RESULTS}`,
  (req, res, ctx) => {
    const sidebar = {
      title: 'Results',
      cards: [
        {
          title: 'Personal Info',
          urlPath: '/',
          savedItems: [
            {
              name: 'DOB',
              value: '1987-04-06',
              type: DATA_TYPE_DATE,
            },
            {
              name: 'Sex',
              value: 'Male',
              type: DATA_TYPE_STRING,
            },
          ],
        },
        {
          title: 'Coverage Options',
          urlPath: '/',
          savedItems: [
            {
              name: 'Open to Lower Costs',
              value: true,
              type: DATA_TYPE_BOOLEAN,
            },
            {
              name: 'Wellness Coverage',
              value: true,
              type: DATA_TYPE_BOOLEAN,
            },
          ],
        },
        {
          title: 'Dependents',
          urlPath: '/',
          savedItems: [
            {
              name: 'Partner/Spouse',
              value: 'Jane',
              type: DATA_TYPE_STRING,
            },
            {
              name: 'Child',
              value: 'Dave',
              type: DATA_TYPE_STRING,
            },
            {
              name: 'Child',
              value: 'Mira',
              type: DATA_TYPE_STRING,
            },
          ],
        },
        {
          title: 'Dependent Info',
          urlPath: '/',
          savedItems: [
            {
              name: "Jane's age",
              value: 33,
              type: DATA_TYPE_NUMBER,
            },
            {
              name: "Dave's age",
              value: 4,
              type: DATA_TYPE_NUMBER,
            },
            {
              name: "Mira's age",
              value: 5,
              type: DATA_TYPE_NUMBER,
            },
          ],
        },
      ],
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        sidebar,
      }),
    );
  },
);
