import { rest } from 'msw';
import { FORM_ID_HIP_LEG_AND_FOOT_SURGERY } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_RADIO } from 'constants/questionTypes';
import { SECONDARY, TERTIARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 19
export const form19 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_HIP_LEG_AND_FOOT_SURGERY}`,
  (req, res, ctx) => {
    const formId = FORM_ID_HIP_LEG_AND_FOOT_SURGERY;
    const formTitle = 'What kind of hip, leg, and foot surgery?';
    const formSubtitle = 'Add as many as you need';
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: '741fcfd0-d46e-4342-8eb0-3025922fe2bc',
          title: 'Bunion surgery',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '96d0319c-ac17-417f-8bdd-8d6d4653019c',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '741fcfd0-d46e-4342-8eb0-3025922fe2bc',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '5167c230-e42d-4343-a51f-6fe22f1a6b3b',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '741fcfd0-d46e-4342-8eb0-3025922fe2bc',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'aa215b25-6e2d-45cd-b053-4d9dca595d47',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '741fcfd0-d46e-4342-8eb0-3025922fe2bc',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '3d0b64a5-e0f0-42fc-b1dd-e45cec1ddb30',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '741fcfd0-d46e-4342-8eb0-3025922fe2bc',
          },
          multipleChoiceOptions: [
            {
              id: 'b5a05979-1130-49f1-b46c-a7557e8032a7',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '0298db8f-20e1-4c6f-a4d5-19e115d50b46',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'fba8bda8-f885-4c73-bd0e-9ab134156f78',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '97f8affe-55b5-4be3-80e8-c7a2c05b2ccc',
          title: 'Hip Replacement',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'd4dd4e5d-d4d0-4003-9c11-04ba7ac6d13c',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '97f8affe-55b5-4be3-80e8-c7a2c05b2ccc',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'f9703f33-825a-4015-a3a7-c871e8722006',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '97f8affe-55b5-4be3-80e8-c7a2c05b2ccc',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'df30a9a6-2c0e-447b-81be-28be901f1aab',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '97f8affe-55b5-4be3-80e8-c7a2c05b2ccc',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'cbdca9b9-0637-401d-8e4e-f804dd09d6e2',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '97f8affe-55b5-4be3-80e8-c7a2c05b2ccc',
          },
          multipleChoiceOptions: [
            {
              id: '50189201-250d-40bc-bc2b-c4db57da7bbf',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '9f11660c-2a3b-4010-aa32-4182806d25bb',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: '84ac6a1a-f392-411e-9e01-8d7996264ae7',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '15670660-d3ee-4fe9-9b22-027fbc988f48',
          title: 'Knee Arthroscopy with ACL Surgery',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '4f59928e-68a3-47f5-832c-61c125b1ad72',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '15670660-d3ee-4fe9-9b22-027fbc988f48',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'df144d1e-8d83-4805-9dd7-75aad5531a1c',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '15670660-d3ee-4fe9-9b22-027fbc988f48',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'cd34135e-771c-41d7-a468-fb4ff904c3d7',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '15670660-d3ee-4fe9-9b22-027fbc988f48',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'b7e8d183-5197-4b0f-83a8-9f0bbc1f85fe',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '15670660-d3ee-4fe9-9b22-027fbc988f48',
          },
          multipleChoiceOptions: [
            {
              id: '9c0328fe-9993-48c8-b47a-57abf9c37d38',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: 'b5035308-7699-4e35-93b5-97d8cf689a4f',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: '5295c240-1349-4b7b-a261-9f882afafeaf',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '56b0149c-5632-4dd5-9a4b-3b756018ec48',
          title: 'Knee Arthroscopy with Meniscus Surgery',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '99ea64f8-ebc9-491f-b84a-dd9f407a6709',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '56b0149c-5632-4dd5-9a4b-3b756018ec48',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'db414b80-8e9e-49cc-9f26-47a31362bfae',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '56b0149c-5632-4dd5-9a4b-3b756018ec48',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'd2c3333a-5a9d-4ba5-92f6-30cac1f00069',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '56b0149c-5632-4dd5-9a4b-3b756018ec48',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '95ea683d-917a-475b-a605-3b06b79d0fee',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '56b0149c-5632-4dd5-9a4b-3b756018ec48',
          },
          multipleChoiceOptions: [
            {
              id: '80040544-3a54-40ec-bc51-3a64d28aa8da',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: 'f89d86ac-1716-4853-9e38-a00d0c751cef',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: '81ee8592-060c-4e76-a053-c8c95e0e5c05',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: 'b741aeab-b001-4ff6-8951-6059f18084b3',
          title: 'Knee Replacement',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'ecf2cf17-6ccd-4742-a48b-afe4e4551ef8',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'b741aeab-b001-4ff6-8951-6059f18084b3',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '1aff33a9-a6f4-4839-9ca5-c1575e7208ff',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'b741aeab-b001-4ff6-8951-6059f18084b3',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'eb5558e3-02f7-4764-b4c0-eed5d9211721',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'b741aeab-b001-4ff6-8951-6059f18084b3',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '789cbf26-2cf7-4444-bde1-d19638f353df',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: 'b741aeab-b001-4ff6-8951-6059f18084b3',
          },
          multipleChoiceOptions: [
            {
              id: '44edf3ba-70a0-4d03-9334-c4c3aa62c1fb',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '5bd49a61-f35a-4ec1-aa9e-e03d8e931cf1',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: '63248ab8-c68b-4bdc-b807-5fd6aa17518b',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '3a1752c2-c4a7-4f3a-89f7-6ea0d98937dc',
          title: 'General Surgery',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '01eb6554-3e77-4a6b-a90c-e20671d0dc7a',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '3a1752c2-c4a7-4f3a-89f7-6ea0d98937dc',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '63f4a4c5-8f38-4e6c-94ad-541c9b18571a',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '3a1752c2-c4a7-4f3a-89f7-6ea0d98937dc',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '03a78ab7-a1cc-4f5f-a8e7-b2a7f7e87deb',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '3a1752c2-c4a7-4f3a-89f7-6ea0d98937dc',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'f198c040-12c1-478f-b11a-599ea827c674',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '3a1752c2-c4a7-4f3a-89f7-6ea0d98937dc',
          },
          multipleChoiceOptions: [
            {
              id: '38166b57-04da-49b4-817e-2cb5f60cb472',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '07419bf4-3ee8-43c1-b5d8-341935c53c6b',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: '0754be05-40fc-4528-99d0-664bb2f2dd7e',
              title: "I don't know",
            },
          ],
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        "If you don't see a surgery that you're planning or don't know the name, don't worry! Select General Surgery and I will factor an average cost surgery into my recommendation.",
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formSubtitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
