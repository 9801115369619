import { rest } from 'msw';

import { FORM_ID_HSA_CONTRIBUTION } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { FORM_TYPE_HSA } from 'constants/formTypes';

export const formHsa = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_HSA_CONTRIBUTION}`,
  async (req, res, ctx) => {
    const formId = FORM_ID_HSA_CONTRIBUTION;
    const formTitle = "Here's the deal with Health Savings Accounts (HSA)";
    const formContent = `
      A Health Savings Account (HSA is a medical savings account that you own. If you are enrolled in a High Deductible Health plan (HDHP), both you and your employer can contribute pre-tax dollars into an HSA that you can use to pay for qualified medical expenses. This is different from a Flexible Spending Account (FSA), which you can also contribute to on a pre-tax basis.)
      <br><br><br>
      <span><b>Here's a nifty chart of how much certain annual contributions would be broken down by payroll deductions:</b></span><br><br>
      <div className="img-container">
        <img src="${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/table_contributions.svg" alt="HSA contributions table" />
      </div>`;
    const formCssId = null;
    const groupedQuestions = null;
    const theoCallout = {
      summaryText: '',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        groupedQuestions,
        formCssId,
        theoCallout,
        formType: FORM_TYPE_HSA,
      }),
    );
  },
);
