import { rest } from 'msw';
import { SHERPA_FORM_ID_DEPENDENTS_AGE } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_NUMBER } from 'constants/questionTypes';

export const sherpaForm202 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_DEPENDENTS_AGE}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_DEPENDENTS_AGE;
    const formTitle = `What are the ages of the people 
    in your household?`;
    const formSubtitle = ``;
    const formContent = ``;
    const formIsSherpa = true;
    const groupedQuestions = [
      [
        {
          id: 'c00f313f-6f66-49d5-b6b7-24515414dfb9',
          titleLarge: '',
          title: `David's Age`,
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          multipleChoiceOptions: null,
          dependency: null,
          max: 100,
          min: 18,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '',
          suffix: '',
        },
      ],
      [
        {
          id: '4aab4389-efb4-4a3e-98ac-0053bd3e9ba4',
          titleLarge: '',
          title: `Mira's Age`,
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          multipleChoiceOptions: null,
          dependency: null,
          max: 100,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '',
          suffix: '',
        },
      ],
      [
        {
          id: 'b0516269-58cf-4d8c-aa78-0fd46e1d9c8f',
          titleLarge: '',
          title: `Roland's Age`,
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          multipleChoiceOptions: null,
          dependency: null,
          max: 100,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '',
          suffix: '',
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'Knowing your age will help me ask the right questions about your medical needs.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
