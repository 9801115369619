import { rest } from 'msw';
import { FORM_ID_SPECIALISTS_VISITS_SECOND } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';
import { SECONDARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 10
export const form10 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_SPECIALISTS_VISITS_SECOND}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_SPECIALISTS_VISITS_SECOND;
    const formTitle = 'What about any of these specialists?';
    const formSubtitle = '';
    const formContent = '';
    const groupedQuestions = [
      [
        {
          id: 'a631dbc6-6bcb-44f1-9c14-07e610ba831e',
          title: 'Acupuncture',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba8721'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a7188823-82dc-4133-a022-be0591f3d321',
          titleLarge: 'Acupuncture - visits per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a631dbc6-6bcb-44f1-9c14-07e610ba831e',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a27828a0-bee5-4a7e-bfe9-66b2cfc3392a',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a631dbc6-6bcb-44f1-9c14-07e610ba831e',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'da2762547-bee5-4a7e-bfe9-66b2cfc6672e',
          title: 'dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a631dbc6-6bcb-44f1-9c14-07e610ba831e',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'c331dbc6-6bcb-44f1-9c14-07e610ba8311',
          title: 'Chiropractor',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba8721'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'c7177723-82dc-4133-a022-be0591f3d021',
          titleLarge: 'Chiropractor - visits per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'c331dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'c27778a0-bee5-4a7e-bfe9-66b2cfc33021',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'c331dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'c2777547-bee5-4a7e-bfe9-66b2cfc66021',
          title: 'dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'c331dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a371dbc6-6bcb-44f1-9c14-07e610ba8311',
          title: 'Medication Management',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba8721'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a3177723-82dc-4133-a022-be0591f3d3ef',
          titleLarge: 'Medication Management - visits per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a371dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a37778a0-bee5-4a7e-bfe9-66b2cfc3392c',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a371dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a3777547-bee5-4a7e-bfe9-66b2cfc667cf',
          title: 'dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a371dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a571dbc6-6bcb-44f1-9c14-07e610ba8311',
          title: 'Psychiatrist',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba8721'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a5155523-82dc-4133-a022-be0591f3d3ef',
          titleLarge: 'Psychiatrist - visits per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a571dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a55558a0-bee5-4a7e-bfe9-66b2cfc3392c',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a571dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '52555547-bee5-4a7e-bfe9-66b2cfc667cf',
          title: 'dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a571dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'ac71dbc6-6bcb-44f1-9c14-07e610ba8311',
          title: 'Occupational Therapist',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba8721'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'c2144523-82dc-4133-a022-be0591f3d3ef',
          titleLarge: 'Occupational Therapist - visits per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'ac71dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'c24458a0-bee5-4a7e-bfe9-66b2cfc3392c',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'ac71dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'c2445547-bee5-4a7e-bfe9-66b2cfc667cf',
          title: 'dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'ac71dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a271dbc6-6bcb-44f1-9c14-07e610ba8311',
          title: 'Other Specialist',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba8721'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a7176523-82dc-4133-a022-be0591f3d32e',
          titleLarge: 'Other Specialist - visits per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a271dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a27658a0-bee5-4a7e-bfe9-66b2cfc3392e',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a271dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a2765547-bee5-4a7e-bfe9-66b2cfc6672e',
          title: 'dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a271dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a0d1dbc6-6bcb-44f1-9c14-07e610ba8721',
          title: 'None of these',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            questionId: null,
            answerId: null,
            disableOnQuestionIds: [
              'a631dbc6-6bcb-44f1-9c14-07e610ba831e',
              'c331dbc6-6bcb-44f1-9c14-07e610ba8311',
              'a371dbc6-6bcb-44f1-9c14-07e610ba8311',
              'a571dbc6-6bcb-44f1-9c14-07e610ba8311',
              'ac71dbc6-6bcb-44f1-9c14-07e610ba8311',
              'a271dbc6-6bcb-44f1-9c14-07e610ba8311',
            ],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'If you don\'t see your Specialist on this list, that\'s okay! Select "Other Specialist" and I will include that in my calculations.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formSubtitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
