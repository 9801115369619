import { rest } from 'msw';
import { SHERPA_FORM_ID_MONTHLY_RENT } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_NUMBER } from 'constants/questionTypes';

export const sherpaForm209 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_MONTHLY_RENT}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_MONTHLY_RENT;
    const formTitle = `Tell us more about your rent payments...`;
    const formSubtitle = null;
    const formContent = null;
    const formIsSherpa = true;
    const groupedQuestions = [
      [
        {
          id: '95899158-3e03-4bd6-93ab-0304b4cdfe41',
          title: 'How much are your rent payments?',
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          multipleChoiceOptions: null,
          max: 50000,
          min: 100,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '$',
          suffix: 'per month',
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'This is why we ask this question...',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
