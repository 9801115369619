import React from 'react';
import { Modal } from 'react-bootstrap';

const EmailWarningModal = ({ closeModal }) => {
  return (
    <>
      <Modal show={true} onHide={closeModal}>
        <Modal.Body>
          <div>If your email app doesn't open automatically, please email us at questions@letshyke.com for support.</div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmailWarningModal;
