import { rest } from 'msw';
import { FORM_ID_MENTAL_HEALTH_CARE } from 'constants/mocks/formIds';
import { INPUT_BOOLEAN } from 'constants/questionTypes';
import { SECONDARY } from 'constants/dependencyStatuses';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';

// Form ID from the MHM Decision Doc Copy Document: 29
export const form29 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_MENTAL_HEALTH_CARE}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_MENTAL_HEALTH_CARE;
    const formTitle =
      'Could you see anyone in your household staying overnight at a mental health facility?';
    const formSubtitle = '';
    const formContent = '';
    const formCssId = '';
    const groupedQuestions = [
      [
        {
          id: '9865ef63-23ae-4730-ae0b-975ece34e854',
          title: 'Yes',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['74b4107b-12af-4d20-b057-0bdfb49ededd'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '3e0d371a-fa54-4a6f-aa39-99551e495437',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '9865ef63-23ae-4730-ae0b-975ece34e854',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '7ef6760a-e925-476a-99ca-0b58700a1496',
          subtitle: '',
          titleLarge: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '9865ef63-23ae-4730-ae0b-975ece34e854',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '3e2e2c45-e0e2-4cb1-aab2-5de288d7f2a5',
          subtitle: '',
          titleLarge: '',
          title: 'Dave',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '9865ef63-23ae-4730-ae0b-975ece34e854',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '74b4107b-12af-4d20-b057-0bdfb49ededd',
          title: 'No',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            questionId: null,
            answerId: null,
            disableOnQuestionIds: ['9865ef63-23ae-4730-ae0b-975ece34e854'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'I will factor in the average cost of a 2-week stay into my analysis.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formSubtitle,
        formContent,
        formCssId,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
