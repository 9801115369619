import { rest } from 'msw';
import { FORM_ID_SURGERY_SELECTIONS } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN } from 'constants/questionTypes';

// Form ID from the MHM Decision Doc Copy Document: 14
export const form14 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_SURGERY_SELECTIONS}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_SURGERY_SELECTIONS;
    const formTitle =
      "You mentioned you're planning on having some surgery done. What type? Add as many as you need.";
    const formContent = '';
    const displayAsGrid = true;
    const formCssId = 'health-events';
    const formSubtitle = 'Add as many as you need';
    const groupedQuestions = [
      [
        {
          id: '043e8d12-cfad-4d77-b094-49efaae704ff',
          title: 'Head & Neck',
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: [],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Dizziness-3--Health-Medical-Condition-Specialties-Dizziness-Headache-Sickness-Nausea-Dizzy-Eyes+1.svg`,
            alt: 'Head & Neck',
            title: '',
          },
          sidebar: {
            title: 'Head & Neck Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
      [
        {
          id: '657be268-5083-4374-a24e-668747912d65',
          title: 'Shoulder, Arm, Hand',
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: [],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Injuries-Bandaged-3--Health-Injuries-Bandaged-Broken-Fractured-Finger-Cast-Wound-Healing+1.svg`,
            alt: 'Shoulder, Arm, Hand',
            title: '',
          },
          sidebar: {
            title: 'Pregnancy or Fertility Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
      [
        {
          id: 'c55d3c04-2cba-4c67-82e0-34a9c5ee1a5c',
          title: 'Back, Chest, Abdomen',
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: [],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Spine--Health-Medical-Condition-Specialties-Chiropractic-Mechanical-Disorder-Musculoskeletal-System-Spine+1.svg`,
            alt: 'Back, Chest, Abdomen',
            title: '',
          },
          sidebar: {
            title: 'Surgery or Hospital Visits Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
      [
        {
          id: '289c7bca-fb0a-4b8d-a2b8-c3bdd91f8263',
          title: 'Pelvis & Reproductive',
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: [],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Pregnancy-3--Health-Pregnancy-Fetus-Development-Offspring-Pregnant-Belly-Gestation+1.svg`,
            alt: 'Pelvis & Reproductive',
            title: '',
          },
          sidebar: {
            title: 'X-Rays, Imaging, Lab Tests Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
      [
        {
          id: '3251e441-6c9e-4533-ad27-8f00f8431f1f',
          title: 'Hip, Leg, Foot',
          subtitle: 'See Details',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: [],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          image: {
            src: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Bone-Joint-3--Health-Medical-Condition-Specialties-Orthopedic-Musculoskeletal-System-Joint-Articulation+1.svg`,
            alt: 'Hip, Leg, Foot',
            title: '',
          },
          sidebar: {
            title: 'Head & Neck Details',
            cards: [
              {
                title: 'Surgery Types',
                definitions: [
                  {
                    title: 'Neck Pain - Cervical Spine Fusion',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Cataract Removal with Lens Implant',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'Glaucoma',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                  {
                    title: 'General Surgery',
                    bodyText:
                      'FPO here is a definition of this particular surgery and its purpose and how it affects the person getting it.',
                  },
                ],
              },
            ],
          },
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'Surgeries have a big impact on health care costs! Tell me which surgeries I should factor into my recommendation.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        formCssId,
        displayAsGrid,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
