import { rest } from 'msw';
import { forms } from 'constants/mocks/formIds';
import { sherpaForms } from 'constants/mocks/sherpaFormIds';
import routes from 'constants/routes';
import { STORAGE_IS_SHERPA } from 'constants/sessionStorage';

export const formSubmit = rest.post(`/api/answers`, async (req, res, ctx) => {
  const isSherpaEnabled = JSON.parse(
    await sessionStorage.getItem(STORAGE_IS_SHERPA),
  );

  const getNextFormNumber = (nextFormNumber) => {
    if (nextFormNumber === 5) {
      return 111;
    }
    if (nextFormNumber === 10) {
      return 112;
    }
    if (nextFormNumber === 15) {
      return 113;
    }
    if (nextFormNumber === 20) {
      return 114;
    }
    if (nextFormNumber === 25) {
      return 102;
    }
    if (nextFormNumber === 111) {
      return 1;
    }
    if (nextFormNumber === 112) {
      return 5;
    }
    if (nextFormNumber === 113) {
      return 10;
    }
    if (nextFormNumber === 114) {
      return isSherpaEnabled ? 201 : 15;
    }
    if (nextFormNumber === 115) {
      return 20;
    }
    if (nextFormNumber === 103) {
      return 25;
    }
    if (nextFormNumber === 39) {
      return 25;
    }
    if (nextFormNumber === 221) {
      return 115;
    }

    return nextFormNumber;
  };
  const getPreviousFormNumber = (formNumber) => {
    if (formNumber === 111) {
      return 4;
    }
    if (formNumber === 112) {
      return 9;
    }
    if (formNumber === 113) {
      return 14;
    }
    if (formNumber === 114) {
      return 19;
    }
    if (formNumber === 101) {
      return 24;
    }
    if (formNumber === 1) {
      return 110;
    }
    if (formNumber === 4) {
      return 111;
    }
    if (formNumber === 9) {
      return 112;
    }
    if (formNumber === 14) {
      return 113;
    }
    if (formNumber === 19) {
      return 114;
    }
    if (formNumber === 24) {
      return 38;
    }
    if (formNumber === 99) {
      return 24;
    }
    return formNumber;
  };

  const getNextPath = (nextFormNumber) => {
    if ([111, 112, 113, 114].includes(nextFormNumber)) return routes.PROGRESS;
    if (nextFormNumber === 102) return routes.HSA_CONTRIBUTION;
    if (nextFormNumber === 116) return routes.RESULTS;
    return routes.FORM;
  };
  const getBackPath = (nextFormNumber) => {
    if ([6, 11, 16, 21].includes(nextFormNumber)) return routes.PROGRESS;
    if (nextFormNumber === 26) return routes.HSA_CONTRIBUTION;
    return routes.FORM;
  };

  const body =
    typeof req.body === 'object' ? req.body : await JSON.parse(req.body);

  const { formId } = body;
  const combinedForms = [...forms, ...sherpaForms];
  const currentForm = combinedForms.find((form) => form.formId === formId);
  const incrementedFormNumber = currentForm.number + 1;
  const nextFormNumber = getNextFormNumber(incrementedFormNumber);
  const nextForm = combinedForms.find((form) => form.number === nextFormNumber);
  const nextFormId = nextForm ? nextForm.formId : '';
  const subtractedFormNumber = currentForm.number - 1;
  const previousFormNumber = getPreviousFormNumber(subtractedFormNumber);
  const previousForm = combinedForms.find(
    (form) => form.number === previousFormNumber,
  );
  const previousFormId = previousForm ? previousForm.formId : 110;

  return res(
    ctx.delay(300),
    ctx.status(200),
    ctx.json({
      nextPath: getNextPath(nextFormNumber),
      nextFormId,
      previousPath: getBackPath(nextFormNumber),
      previousFormId,
    }),
  );
});
