import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';

const ScheduleOnceModal = ({ closeModal, embed }) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://cdn.oncehub.com/mergedjs/so.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <Modal show={true} onHide={closeModal}>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: embed }} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ScheduleOnceModal;
