import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import routes, { routeTypes } from 'constants/routes';
import AsyncComponentHOC from 'components/HOC/AsyncComponentHOC';
import TrackableRoute from 'components/RouteAuthentication/TrackableRoute';
import { SidebarProvider } from 'contexts/sidebar';
import App from 'containers/App';

const AsyncAbout = AsyncComponentHOC(() => import('containers/About'));
const AsyncContributionsHsa = AsyncComponentHOC(() =>
  import('containers/HsaContribution'),
);
const AsyncCoverageConfirmation = AsyncComponentHOC(() =>
  import('containers/CoverageConfirmation'),
);
const AsyncLearnMoreHSA = AsyncComponentHOC(() =>
  import('containers/LearnMoreHSAPage'),
);
const AsyncLearnMoreFSA = AsyncComponentHOC(() =>
  import('containers/LearnMoreFSAPage'),
);
const AsyncLearnMoreRX = AsyncComponentHOC(() =>
  import('containers/LearnMoreRXPage'),
);
const AsyncForm = AsyncComponentHOC(() => import('containers/FormPage'));
const AsyncGetStarted = AsyncComponentHOC(() =>
  import('containers/GetStarted'),
);
const AsyncHome = AsyncComponentHOC(() => import('containers/Home'));
const AsyncLandingPage = AsyncComponentHOC(() =>
  import('containers/LandingPage'),
);
const AsyncGlossaryPage = AsyncComponentHOC(() =>
  import('containers/GlossaryPage'),
);

// @TODO Mock Route for SSO
const AsyncMockSsoLoginPage = AsyncComponentHOC(() =>
  import('components/MockSsoLoginPage'),
);
// @TODO Mock Route for Sherpa
const AsyncMockSherpaResults = AsyncComponentHOC(() =>
  import('components/MockSherpaResults'),
);

const AsyncLogout = AsyncComponentHOC(() => import('containers/Logout'));
const AsyncJobNotFound = AsyncComponentHOC(() =>
  import('containers/JobNotFound/jobnotfound'),
);
const AsyncNotFound = AsyncComponentHOC(() => import('containers/NotFound'));
const AsyncProgress = AsyncComponentHOC(() => import('containers/Progress'));
const AsyncResults = AsyncComponentHOC(() => import('containers/Results'));
const AsyncCbr = AsyncComponentHOC(() => import('containers/Cbr'));
const AsyncSubscriber = AsyncComponentHOC(() =>
  import('containers/Subscriber'),
);
const AsyncFormLinks = AsyncComponentHOC(() => import('containers/FormLinks'));

const mapStateToProps = (state) => ({
  userData: state.user.data,
});

// eslint-disable-next-line no-unused-vars
const RoutesContainer = ({ userData, dispatch }) => {
  const { PUBLIC, REGISTERED_GUEST } = routeTypes;

  return (
    <Router>
      <SidebarProvider>
        <App>
          <Switch>
            <TrackableRoute
              path={routes.HOME}
              exact
              component={AsyncHome}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute
              path={routes.GET_STARTED}
              exact
              component={AsyncGetStarted}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute
              path={routes.ABOUT}
              exact
              component={AsyncAbout}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute
              path={routes.LOGOUT}
              exact
              component={AsyncLogout}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute
              path={routes.HSA_CONTRIBUTION}
              exact
              component={AsyncContributionsHsa}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute
              path={routes.COVERAGE_CONFIRMATION}
              exact
              component={AsyncCoverageConfirmation}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute
              path={routes.LEARN_MORE_HSA_PAGE}
              exact
              component={AsyncLearnMoreHSA}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute
              path={routes.LEARN_MORE_FSA_PAGE}
              exact
              component={AsyncLearnMoreFSA}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute
              path={routes.LEARN_MORE_RX_PAGE}
              exact
              component={AsyncLearnMoreRX}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute
              path={routes.FORM}
              exact
              component={AsyncForm}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute
              path={`${routes.FORM}/:formNumber`}
              exact
              component={AsyncForm}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute
              path={routes.PROGRESS}
              exact
              component={AsyncProgress}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute
              path={routes.RESULTS}
              exact
              component={AsyncResults}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute
              path={routes.CBR}
              exact
              component={AsyncCbr}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute
              path={routes.SUBSCRIBER}
              exact
              component={AsyncSubscriber}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute
              path={`${routes.LANDING_PAGE}/:landingPageSlug?`}
              exact
              component={AsyncLandingPage}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute
              path={`${routes.GLOSSARY_PAGE}`}
              exact
              component={AsyncGlossaryPage}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute // @TODO: Remove this mock route during integration.
              path={`${routes.MOCK_SSO_LOGIN_PAGE}`}
              exact
              component={AsyncMockSsoLoginPage}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute // @TODO: Remove this mock route during integration.
              path={`${routes.MOCK_SHERPA_RESULTS}`}
              exact
              component={AsyncMockSherpaResults}
              type={PUBLIC}
              userData={userData}
            />
            <TrackableRoute // Used for dev and test when a slug is invalid to reduce google analytics from counting when we hit the gateway page
              path={`${routes.JOB_NOT_FOUND}`}
              exact
              component={AsyncJobNotFound}
              type={PUBLIC}
              userData={userData}
            />
            {process.env.REACT_APP_SHOULD_MOCK === 'true' ? (
              <TrackableRoute
                path={routes.FORM_LINKS}
                exact
                component={AsyncFormLinks}
                type={PUBLIC}
                auth={userData}
                userData={userData}
              />
            ) : null}
            <Route component={AsyncNotFound} />
          </Switch>
        </App>
      </SidebarProvider>
    </Router>
  );
};

const Routes = connect(mapStateToProps)(RoutesContainer);

export default Routes;
