import { rest } from 'msw';
import { SHERPA_FORM_ID_HOSPITAL_INSURANCE } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN } from 'constants/questionTypes';

export const sherpaForm212 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_HOSPITAL_INSURANCE}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_HOSPITAL_INSURANCE;
    const formHeader = 'HOSPITAL INDEMNITY';
    const formTitle = `Who’s covered by your hospital indemnity policy?`;
    const formSubtitle = null;
    const formContent = null;
    const formIsSherpa = true;
    const allowNull = true;
    const groupedQuestions = [
      [
        {
          id: 'fa915980-9bcc-4b0f-ba7e-78c0978b9e35',
          title: 'Spouse',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: null,
        },
      ],
      [
        {
          id: '1509fe2e-0c53-47d1-8679-0f02ba700a79',
          title: 'Child(ren)',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'Stuff about this',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formHeader,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
        allowNull,
      }),
    );
  },
);
