import { rest } from 'msw';
import { SHERPA_FORM_ID_FUTURE_EXPENSE_SELECTOR } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN } from 'constants/questionTypes';

export const sherpaForm216 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_FUTURE_EXPENSE_SELECTOR}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_FUTURE_EXPENSE_SELECTOR;
    const formHeader = 'LIFE INSURANCE';
    const formTitle = `Do you plan to pay for any of the following for your family?`;
    const formSubtitle = ``;
    const formContent = ``;
    const formIsSherpa = true;
    const allowNull = true;
    const groupedQuestions = [
      [
        {
          id: 'c3bc8b92-7c42-495a-8fdf-8eba390e3528',
          title: 'Private School Fees',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: null,
        },
      ],
      [
        {
          id: '73d42f4e-afae-4aa8-8c03-5a10eed3c0fd',
          title: 'In-state University Fees',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: null,
        },
      ],
      [
        {
          id: '5f8fc346-b074-4580-bec8-d7997e90a712',
          title: 'Out-of-state University Fees',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'Stuff about this',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formHeader,
        formTitle,
        allowNull,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
