import * as answers from './answers';
import * as auth from './auth';
import * as cbr from './cbr';
import * as forms from './forms';
import * as employers from './employers';
import * as questionnaires from './questionnaires';
import * as results from './results';
import * as user from './user';
import * as ssoAuth from './ssoAuth';
import * as sherpa from './sherpa';
import * as magicAuth from './magicAuth';

export default {
  answers,
  auth,
  ssoAuth,
  cbr,
  forms,
  employers,
  questionnaires,
  results,
  user,
  sherpa,
  magicAuth,
};
