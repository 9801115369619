import React from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';

const AuthenticatedOnlyRoute = ({
  component,
  exact,
  path,
  userData = {},
}) => {
  const renderComponent = () => {
    const { auth } = userData;
    const authenticationData = auth ? auth : {};
    const { authenticated } = authenticationData;
    const C = component;

    if (authenticated) return <C />;
  };

  return <Route exact={exact} path={path} render={() => renderComponent()} />;
};

const WrappedComponent = withRouter(AuthenticatedOnlyRoute);
export default WrappedComponent;
