import { rest } from 'msw';
import { FORM_ID_LAST_YEAR_PRIMARY_CARE } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_NUMBER, INPUT_RADIO } from 'constants/questionTypes';
import { SECONDARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 22
export const form22 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_LAST_YEAR_PRIMARY_CARE}`,
  (req, res, ctx) => {
    const formId = FORM_ID_LAST_YEAR_PRIMARY_CARE;
    const formTitle =
      'Do you think anyone in your household might visit a <span class="dotted-underline--trigger">Primary Care Provider </span>(PCP) outside of their routine physical?';
    const formCssId = null;
    const formContent = null;
    const theoCallout = {
      summaryText:
        "Your routine physical typically costs you nothing. I'll factor any additional visits into my analysis.",
      bodyText:
        'Not sure how to answer this? Think of how many visits you had last year and start there!',
    };
    const groupedQuestions = [
      [
        {
          id: '91d4bbf0-7ff6-4858-ba07-0e1c57236e55',
          title: '',
          type: INPUT_RADIO,
          multipleChoiceOptions: [
            {
              id: '5b5561cc-6d21-4d3e-8e73-afe84fb21ea7',
              title: 'Yes',
            },
            {
              id: 'b42a751b-a77f-4495-b869-fcea1059a8dc',
              title: 'No',
            },
          ],
          dependency: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'c4d61570-3eaa-4736-ad74-f98225d9f433',
          titleLarge: 'How many times?',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          dependency: {
            status: SECONDARY,
            questionId: '91d4bbf0-7ff6-4858-ba07-0e1c57236e55',
            answerId: '5b5561cc-6d21-4d3e-8e73-afe84fb21ea7',
          },
          max: 26,
          min: 0,
        },
        {
          id: 'aaaaeda2-64b7-417a-8c2d-8d232c952908',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          dependency: {
            status: SECONDARY,
            questionId: '91d4bbf0-7ff6-4858-ba07-0e1c57236e55',
            answerId: '5b5561cc-6d21-4d3e-8e73-afe84fb21ea7',
          },
          max: 26,
          min: 0,
        },
        {
          id: 'bd1dc081-6573-488d-b9b5-851f34904969',
          title: 'David',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          dependency: {
            status: SECONDARY,
            questionId: '91d4bbf0-7ff6-4858-ba07-0e1c57236e55',
            answerId: '5b5561cc-6d21-4d3e-8e73-afe84fb21ea7',
          },
          max: 26,
          min: 0,
        },
      ],
    ];
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formCssId,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
