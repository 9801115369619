import { rest } from 'msw';
import { FORM_ID_SURGERY_BACK_CHEST_ABDOMEN } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_RADIO } from 'constants/questionTypes';
import { SECONDARY, TERTIARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 17
export const form17 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_SURGERY_BACK_CHEST_ABDOMEN}`,
  (req, res, ctx) => {
    const formId = FORM_ID_SURGERY_BACK_CHEST_ABDOMEN;
    const formTitle = 'What kind of back, chest, or abdomen surgery?';
    const formSubtitle = 'Add as many as you need';
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: '06414d67-86b5-4dbc-afd5-813d2c834c25',
          title: 'Back Surgery Fusion',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '35961910-89c7-4715-b6bc-4f904e1a5af5',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '06414d67-86b5-4dbc-afd5-813d2c834c25',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a1e4fec3-1023-49a1-9f63-cf5e8c73f766',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '06414d67-86b5-4dbc-afd5-813d2c834c25',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'af6c0236-66f4-4e75-b612-e1eef64ce3d7',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '06414d67-86b5-4dbc-afd5-813d2c834c25',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'e106924f-5640-4c2b-a445-92d2236d19bd',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '06414d67-86b5-4dbc-afd5-813d2c834c25',
          },
          multipleChoiceOptions: [
            {
              id: '178bdef0-6b9f-4a92-8f2d-511cce9a9ef8',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '9831faca-a222-4865-9c73-9ac4b5f3bbe8',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'd597d547-d3d8-4dd5-a7b3-28cdfd6eb566',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '07f8ff5f-6433-4ff5-cd66-abc7bb2423d4',
          title: 'Back Surgery - Diskectomy',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '7f3cb251-dce2-4a4c-af3f-c23f787878fb',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '07f8ff5f-6433-4ff5-cd66-abc7bb2423d4',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'e97c8ae0-7f04-4418-bad6-e5ba9cd9b6c5',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '07f8ff5f-6433-4ff5-cd66-abc7bb2423d4',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '75489823-e446-4638-805c-c33e258364a4',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '07f8ff5f-6433-4ff5-cd66-abc7bb2423d4',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'f1a1200d-ed38-4ff2-9942-cbbd8b2ff33e',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '07f8ff5f-6433-4ff5-cd66-abc7bb2423d4',
          },
          multipleChoiceOptions: [
            {
              id: '276bdef0-6b9f-4a92-8f2d-511cce9a9ef8',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '3571faca-a222-4865-9c73-9ac4b5f3bbe8',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'd87fd547-d3d8-4dd5-a7b3-28cdfd6eb566',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '76dafee4-6133-4064-b6d1-735056ea9a2c',
          title: 'Gallbladder Removal',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'c462b6a5-2760-43c5-90c6-b5c84d428abe',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '76dafee4-6133-4064-b6d1-735056ea9a2c',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '762579c2-bc2a-4608-a55d-fc4591eebbe0',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '76dafee4-6133-4064-b6d1-735056ea9a2c',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a3621283-32f4-4698-ac34-13afeadee87c',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '76dafee4-6133-4064-b6d1-735056ea9a2c',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '59d332af-a33f-4b31-9f2c-2357bd7688ac',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '76dafee4-6133-4064-b6d1-735056ea9a2c',
          },
          multipleChoiceOptions: [
            {
              id: '179bdef0-6b9f-4a92-8f2d-511cce9d83f8',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '9871faca-a222-4865-9c73-9ac4b5f3b320',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'd59fd547-d3d8-4dd5-a7b3-28cdfd6eb512',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '8c089e37-dd8f-41e7-a89e-2a53f3aa5d62',
          title: 'Mastectomy',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '6e5bb6b7-e7b2-4a0a-b91e-51d20cc35f21',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '8c089e37-dd8f-41e7-a89e-2a53f3aa5d62',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '7c99e8f1-6b88-4ad8-b1ec-80e3a019fd70',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '8c089e37-dd8f-41e7-a89e-2a53f3aa5d62',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '6ef0c578-96a4-4d03-9bfa-ae72db65ead8',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '8c089e37-dd8f-41e7-a89e-2a53f3aa5d62',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '4a2b239a-77fe-45c6-b8ee-84a6c21b93d6',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '8c089e37-dd8f-41e7-a89e-2a53f3aa5d62',
          },
          multipleChoiceOptions: [
            {
              id: '179bdef0-6b9f-4a92-8f2d-511cce9a9ef8',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '9871faca-a222-4865-9c73-9ac4b5f3bbe8',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'd59fd547-d3d8-4dd5-a7b3-28cdfd6eb566',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '6c089e37-dd8f-41e7-a89e-2a53f3aa4d64',
          title: 'Weight Loss Surgery - Gastric Bypass',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '6ecc86b7-e7b2-4a0a-b91e-51d20cc35f21',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '6c089e37-dd8f-41e7-a89e-2a53f3aa4d64',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '7c99e8f1-6b88-4ad8-cc8c-80e3a019fd70',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '6c089e37-dd8f-41e7-a89e-2a53f3aa4d64',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '6ef0c578-96a4-cc83-9bfa-ae72db65ead8',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '6c089e37-dd8f-41e7-a89e-2a53f3aa4d64',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '4acc839a-77fe-45c6-b8ee-84a6c21b93d6',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '6c089e37-dd8f-41e7-a89e-2a53f3aa4d64',
          },
          multipleChoiceOptions: [
            {
              id: '179cc8f0-6b9f-4a92-8f2d-511cce9a9ef8',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '9871cc8a-a222-4865-9c73-9ac4b5f3bbe8',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'd59cc847-d3d8-4dd5-a7b3-28cdfd6eb566',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '7386f646-2eaa-4b99-86d4-3b3df3afc399',
          title: 'Weight Loss Surgery - Laparoscopic Band',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '2678604e-dc7d-4f4e-8c44-2813d68ef2c8',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '7386f646-2eaa-4b99-86d4-3b3df3afc399',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'c42505c6-47dc-428e-a6a0-3ffe89a520b7',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '7386f646-2eaa-4b99-86d4-3b3df3afc399',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '88f505ca-bf34-4c24-be42-4f6acea7b089',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '7386f646-2eaa-4b99-86d4-3b3df3afc399',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '548e1c93-2017-4f87-b5c5-0a9266ee67cf',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '7386f646-2eaa-4b99-86d4-3b3df3afc399',
          },
          multipleChoiceOptions: [
            {
              id: '179bdef0-6b9f-4a92-8f2d-908cce9a9ef8',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '9871faca-a222-4865-9c73-9084b5f3bbe8',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'd59fd547-d3d8-4dd5-a7b3-908dfd6eb566',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '7386f646-2eaa-4b99-86d4-3b3df3afc786',
          title: 'General Surgery',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '2678ba6e-dc7d-4f4e-8c44-9033d68ef2c8',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '7386f646-2eaa-4b99-86d4-3b3df3afc786',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'c4288cc6-47dc-a03e-a6a0-3ffe89a520b7',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '7386f646-2eaa-4b99-86d4-3b3df3afc786',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '88f133ca-bf34-4c24-be42-4f6a7037b089',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '7386f646-2eaa-4b99-86d4-3b3df3afc786',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '548e1c93-b267-4f87-b5c5-0a9703ee67cf',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '7386f646-2eaa-4b99-86d4-3b3df3afc786',
          },
          multipleChoiceOptions: [
            {
              id: '179bdef0-6b9f-4a92-8f2d-5117039a9ef8',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '9871faca-a222-4865-9c73-9ac703f3bbe8',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'd59fd547-d3d8-4dd5-a7b3-28cd703eb566',
              title: "I don't know",
            },
          ],
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        "If you don't see a surgery that you're planning or don't know the name, don't worry! Select General Surgery and I will factor an average cost surgery into my recommendation.",
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formSubtitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
