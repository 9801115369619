import { rest } from 'msw';
import { FORM_ID_SPECIALISTS_VISITS } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';
import { SECONDARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 9
export const form9 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_SPECIALISTS_VISITS}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_SPECIALISTS_VISITS;
    const formTitle = 'Should I add any of these common specialists?';
    const formSubtitle = 'Add as many as you need.';
    const formContent = '';
    const groupedQuestions = [
      [
        {
          id: 'a831dbc6-6bcb-44f1-9c14-07e610ba831d',
          title: 'Dermatologist',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba872c'],
          },
          sidebar: {},
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a7188823-82dc-4133-a022-be0591f3d3ef',
          titleLarge: 'Dermatologist - visits per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a831dbc6-6bcb-44f1-9c14-07e610ba831d',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a27828a0-bee5-4a7e-bfe9-66b2cfc3392c',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a831dbc6-6bcb-44f1-9c14-07e610ba831d',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a2762547-bee5-4a7e-bfe9-66b2cfc667cf',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a831dbc6-6bcb-44f1-9c14-07e610ba831d',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a331dbc6-6bcb-44f1-9c14-07e610ba8311',
          title: 'Gastroenterologist',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba872c'],
          },
          sidebar: {},
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a7177723-82dc-4133-a022-be0591f3d3ef',
          titleLarge: 'Gastroenterologist - visits per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a331dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a27778a0-bee5-4a7e-bfe9-66b2cfc3392c',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a331dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a2777547-bee5-4a7e-bfe9-66b2cfc667cf',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a331dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a771dbc6-6bcb-44f1-9c14-07e610ba8311',
          title: 'Individual Therapy',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba872c'],
          },
          sidebar: {},
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a7177723-82dc-4133-a022-be0591f3d3ef',
          titleLarge: 'Individual Therapy - visits per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a771dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a27778a0-bee5-4a7e-bfe9-66b2cfc3392c',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a771dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a2777547-bee5-4a7e-bfe9-66b2cfc667cf',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a771dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a771dbc6-6bcb-44f1-9c14-07e610ba8311',
          title: 'Neurologist',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba872c'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a7155523-82dc-4133-a022-be0591f3d3ef',
          titleLarge: 'Neurologist - visits per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a771dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a25558a0-bee5-4a7e-bfe9-66b2cfc3392c',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a771dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a2555547-bee5-4a7e-bfe9-66b2cfc667cf',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a771dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'ab71dbc6-6bcb-44f1-9c14-07e610ba8311',
          title: 'OB-GYN',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba872c'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a7144523-82dc-4133-a022-be0591f3d3ef',
          titleLarge: 'OB-GYN - visits per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'ab71dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a24458a0-bee5-4a7e-bfe9-66b2cfc3392c',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'ab71dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a2445547-bee5-4a7e-bfe9-66b2cfc667cf',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'ab71dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a271dbc6-6bcb-44f1-9c14-07e610ba8311',
          title: 'Physical Therapist',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a0d1dbc6-6bcb-44f1-9c14-07e610ba872c'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a7176523-82dc-4133-a022-be0591f3d3ef',
          titleLarge: 'Physical Therapist - visits per member',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a271dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a27658a0-bee5-4a7e-bfe9-66b2cfc3392c',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a271dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a2765547-bee5-4a7e-bfe9-66b2cfc667cf',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a271dbc6-6bcb-44f1-9c14-07e610ba8311',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a0d1dbc6-6bcb-44f1-9c14-07e610ba872c',
          title: 'None of these',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            questionId: null,
            answerId: null,
            disableOnQuestionIds: [
              'a831dbc6-6bcb-44f1-9c14-07e610ba831d',
              'a331dbc6-6bcb-44f1-9c14-07e610ba8311',
              'a771dbc6-6bcb-44f1-9c14-07e610ba8311',
              'a771dbc6-6bcb-44f1-9c14-07e610ba8311',
              'ab71dbc6-6bcb-44f1-9c14-07e610ba8311',
              'a271dbc6-6bcb-44f1-9c14-07e610ba8311',
            ],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'There are more specialists on the next page!',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formSubtitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
