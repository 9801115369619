import { rest } from 'msw';
import { SHERPA_FORM_ID_ACCIDENTAL_DEATH_DISMEMBERMENT } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_NUMBER } from 'constants/questionTypes';

export const sherpaForm214 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_ACCIDENTAL_DEATH_DISMEMBERMENT}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_ACCIDENTAL_DEATH_DISMEMBERMENT;
    const formHeader = 'ACCIDENTAL DEATH & DISMEMBERMENT (AD&D)';
    const formTitle =
      'What is the payout of your accidental death and dismemberment policy?';
    const formSubtitle = null;
    const formContent = null;
    const formIsSherpa = true;
    const groupedQuestions = [
      [
        {
          id: 'c00f313f-6f66-49d5-b6b7-24515414dfb9',
          titleLarge: '',
          title: 'Total Payout',
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          multipleChoiceOptions: null,
          dependency: null,
          max: 5000000,
          min: 1000,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '$',
          suffix: '',
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'Stuff about this',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formHeader,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
