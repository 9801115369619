import { rest } from 'msw';
import { SHERPA_FORM_ID_LEGACY_SUM } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';
import { SECONDARY } from 'constants/dependencyStatuses';

export const sherpaForm217 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_LEGACY_SUM}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_LEGACY_SUM;
    const formHeader = 'LIFE INSURANCE';
    const formTitle =
      'Do you plan to pay for any of the following for your family?';
    const formSubtitle = null;
    const formContent = null;
    const formIsSherpa = true;
    const allowNull = true;
    const groupedQuestions = [
      [
        {
          id: 'c041fb0b-4627-494d-9745-08e073cf4333',
          title: 'I would like to leave a legacy sum',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          required: false,
          selectedAnswer: null,
          wipeValueWhenUnchecked: true,
          img: null,
        },
        {
          id: '7051fec3-ca9f-4f50-a136-d05025199d54',
          title: 'Amount Left to Loved Ones',
          subtitle: 'If you’re not sure, give us your best guess!',
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          answerLabelData: {
            prefix: '$',
          },
          dependency: {
            status: SECONDARY,
            questionId: 'c041fb0b-4627-494d-9745-08e073cf4333',
            answerId: null,
          },
          required: true,
          selectedAnswer: null,
          img: null,
          min: 0,
          prefix: '$',
          suffix: '',
        },
      ],
      [
        {
          id: 'd589025a-2bb4-4fde-9edb-c10853d183f0',
          title: 'I want to pay my outstanding debts',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          wipeValueWhenUnchecked: true,
          img: null,
        },
        {
          id: '68c83296-bca1-43e7-a684-c1c8c15db063',
          title: 'Total Sum',
          subtitle: 'If you’re not sure, give us your best guess!',
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          answerLabelData: {
            prefix: '$',
            suffix: '',
          },
          dependency: {
            status: SECONDARY,
            questionId: 'd589025a-2bb4-4fde-9edb-c10853d183f0',
            answerId: null,
          },
          required: true,
          selectedAnswer: null,
          img: null,
          min: 0,
          prefix: '$',
          suffix: '',
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'Stuff about this',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formHeader,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
        allowNull,
      }),
    );
  },
);
