import routes from '../routes';

// Navigation-Engine Page IDs
export const PROGRESS_ID_INTRO = '39814ae7-f2a9-4187-9470-6a3c45ad1c90';
export const PROGRESS_ID_ABOUT_YOU = '39814ae7-f2a9-4187-9470-6a3c45ad1c91';
export const PROGRESS_ID_HEALTH_EVENTS = '39814ae7-f2a9-4187-9470-6a3c45ad1c92';
export const PROGRESS_ID_ROUTINE_CARE = '39814ae7-f2a9-4187-9470-6a3c45ad1c93';
export const PROGRESS_ID_RESULTS = '39814ae7-f2a9-4187-9470-6a3c45ad1c94';
export const PROGRESS_ID_ANCILLARY_BENEFITS =
  'cc289950-4161-4952-b932-7df5ba371dbc';
export const FORM_ID_HSA_CONTRIBUTION = '103bd708-7b80-4623-9cd7-b70585ce09c2';
export const FORM_ID_DENTAL_VISION = '1eca888d-4311-4b71-b994-7056d1a172fe';
export const FORM_ID_ONLY_DENTAL = 'c9bea67a-b7be-4536-8c8e-ad8688d3a42b';
export const FORM_ID_ONLY_VISION = 'c8cdb739-156d-4e79-9f1b-5c9fff235a03';
export const FORM_ID_DEPENDENTS_DENTAL = '1fbba2cc-1848-4f5d-91eb-0cd01f8e3608';
export const FORM_ID_DEPENDENTS_DENTAL_PARTNER =
  'fbd1a979-e4cc-4f71-9d40-848aeb9334df';
export const FORM_ID_DEPENDENTS_DENTAL_CHILDREN =
  'd44e79f0-2be4-40c4-90e5-42c826f2efeb';
export const FORM_ID_DEPENDENTS_VISION_COMBINED =
  '77270417-032c-451c-a2be-1da8b8a8523e';
export const FORM_ID_DEPENDENTS_VISION_COMBINED_PARTNER =
  'ddf4a91f-55c5-4359-b5ff-ebb32ce0f70a';
export const FORM_ID_DEPENDENTS_VISION_COMBINED_CHILDREN =
  'fc75d499-8b8c-44f9-a20d-d1fae08eae52';
export const FORM_ID_DEPENDENTS_VISION_ONLY =
  'f9a18c79-8aa0-478c-baa3-c0db0df963ba';
export const FORM_ID_SUBSCRIBER = '58d7ef16-0ee4-4552-87ce-3930f49f5c65';
export const FORM_ID_COVERAGE_CONFIRMATION =
  '6ade4bf5-f639-41bc-b969-873530331b0c';
export const FORM_ID_FSA_LEARN_MORE = '1fc8ef8b-7606-4916-98d9-306b43611074';
export const FORM_ID_HSA_LEARN_MORE = 'b295ff50-6175-4f25-a0ad-a9c5ec131488';
export const FORM_ID_RX_LEARN_MORE = '21bc14c3-aaad-49ab-ad4f-bfcbacb94f9a';

// Navigation-Engine Form IDs
export const FORM_ID_CHILD_SERVICES = 'f5bb2a45-e066-42ae-b3ff-e42127d34011';
export const FORM_ID_EARLY_INTERVENTION_SERVICES =
  '66448858-327a-4d35-8850-e09c2bb7bb61';
export const FORM_ID_EARLY_INTERVENTION_PAYMENT_TYPES =
  'f97daa29-7100-4159-bd66-dca1a33dd2ce';
export const FORM_ID_EMPLOYMENT_RATES = '2ee1058f-59c1-42bc-a347-b27e9d6885ec';
export const FORM_ID_EXPECTING_CHILD = '2b305596-7d84-45d5-882b-dc7892cf1a4c';
export const FORM_ID_FERTILITY = 'f3352a45-e066-42ae-b3ff-e42127d34045';
export const FORM_ID_HEAD_AND_NECK_SURGERY =
  '97c9eb9b-9739-49c6-bb3c-23f48d0e6462';
export const FORM_ID_HEALTH_EVENTS = 'a2717a32-dbf2-40b0-befe-85a75a1fe5c1';
export const FORM_ID_HOUSEHOLD_DYNAMIC = 'c51f9cb0-6e38-4489-bf71-b51a09054006';
export const FORM_ID_HOUSEHOLD_AGES = '3125ccfe-2fbb-45af-b5f8-e89e8961f3d0';
export const FORM_ID_LAST_YEAR_COVERAGE =
  '08414ae7-f2a9-4187-9470-6a3c45ad1c69';
export const FORM_ID_LAST_YEAR_CLINICS = '392b2729-7fbd-41e5-b567-751279772fd8';
export const FORM_ID_LAB_WORK_OTHER = 'af3c365c-32b0-4fbb-8c34-8f017bf9380f';
export const FORM_ID_LAB_WORK_IMAGING = '946529ce-46ae-4516-9275-193ebe360e52';
export const FORM_ID_LAST_YEAR_PRIMARY_CARE =
  '9982ea7b-fd33-465d-980c-6bd86a942f83';
export const FORM_ID_PREGNANCY_SERVICES =
  'ea3b9a9a-3506-4b2a-81be-d27ca437d6ad';
export const FORM_ID_PROVIDERS = '14b0d560-2a66-4a5f-8b2f-31759a6fe04e';
export const FORM_ID_SPECIALISTS_VISITS =
  'a7352a45-e066-42ae-b3ff-e42127d34024';
export const FORM_ID_SPECIALISTS_VISITS_SECOND =
  'a2ee2a45-e066-42ae-b3ff-e42127d34032';
export const FORM_ID_SURGERY_SELECTIONS =
  '53e468ec-696a-4498-8957-6e5d62b489ee';
export const FORM_ID_SURGERY_BACK_CHEST_ABDOMEN =
  '82e193d3-267a-7496-6952-8e8772b48921';
export const FORM_ID_HIP_LEG_AND_FOOT_SURGERY =
  'fe6369ef-8d24-408a-bb17-1ead77eebad6';
export const FORM_ID_PELVIS_REPRODUCTIVE =
  '1b78f4d0-b16f-4a9b-86bb-9bc29444c934';
export const FORM_ID_SHOULDER_ARM_AND_HAND_SURGERY =
  '9deb0395-f607-448a-ac88-24b87c12c981';
export const FORM_ID_HEALTH_SELECTIONS = 'afce153b-a83f-48a7-9ccc-01f83ed2e50e';
export const FORM_ID_ALLERGIES = '5c890f10-fc20-11eb-9a03-0242ac130003';
export const FORM_ID_MEDICAL_EQUIPMENT = '8814b6ef-46a6-4dc0-b31a-17bc09a83c80';
export const FORM_ID_DIABETIC_SUPPLIES = '12558857-ac36-4e94-b63d-32509bfef12d';
export const FORM_ID_MENTAL_HEALTH_CARE =
  '72893857-ac36-4e94-b63d-32509bfef12d';
export const FORM_ID_SUBSTANCE_USE = 'ada29793-3073-4ef6-93ad-7c76c36f3460';
export const FORM_ID_MEDICARE_ELIGIBILITY =
  'bf1417c6-0aaa-4d6a-a47b-ed1f558350ca';
export const FORM_ID_HSA_ACCOUNT = 'ea3c21c7-08ba-4ffc-9968-4ddbbf99851e';
export const FORM_ID_PAIN_MANAGEMENT = '6acc709d-8a1f-4f32-930d-9bb57e2be99d';

export const FORM_ID_PRESCRIPTIONS_GENERIC =
  'c78a0f63-3523-4b32-83a7-a79490db2d24';
export const FORM_ID_PRESCRIPTIONS_BRAND =
  '54b74d58-3d08-4c3a-a837-57c17f246e14';
export const FORM_ID_PRESCRIPTIONS_SPECIALTY =
  'f5925202-2616-46ab-8894-4c0b5fdc44d7';

export const PROGRESS_ID_INTRO_NUMBER = 110;

export const forms = [
  {
    formId: FORM_ID_EMPLOYMENT_RATES,
    number: 1,
    title: 'Employment Rates',
  },
  {
    formId: FORM_ID_PROVIDERS,
    number: 2,
    title: 'Lower Cost Physician',
  },
  {
    formId: FORM_ID_HOUSEHOLD_DYNAMIC,
    number: 3,
    title: 'Household',
  },
  {
    formId: FORM_ID_HOUSEHOLD_AGES,
    number: 4,
    title: 'Household Ages',
  },
  {
    formId: FORM_ID_CHILD_SERVICES,
    number: 5,
    title: 'Child Services',
  },
  {
    formId: FORM_ID_EARLY_INTERVENTION_PAYMENT_TYPES,
    number: 6,
    title: 'Early Intervention Payment Types',
  },
  {
    formId: FORM_ID_EARLY_INTERVENTION_SERVICES,
    number: 7,
    title: 'Early Intervention Services',
  },
  {
    formId: FORM_ID_HEALTH_EVENTS,
    number: 8,
    title: 'Health Events',
  },
  {
    formId: FORM_ID_SPECIALISTS_VISITS,
    number: 9,
    title: 'Specialists Visits',
  },
  {
    formId: FORM_ID_SPECIALISTS_VISITS_SECOND,
    number: 10,
    title: 'Specialists Visits Part 2',
  },
  {
    formId: FORM_ID_EXPECTING_CHILD,
    number: 11,
    title: 'Household Expecting Child',
  },
  {
    formId: FORM_ID_PREGNANCY_SERVICES,
    number: 12,
    title: 'Pregnancy services',
  },
  {
    formId: FORM_ID_FERTILITY,
    number: 13,
    title: 'Fertility',
  },
  {
    formId: FORM_ID_SURGERY_SELECTIONS,
    number: 14,
    title: 'Surgery Group',
  },
  {
    formId: FORM_ID_HEAD_AND_NECK_SURGERY,
    number: 15,
    title: 'Head and Neck Surgeries',
  },
  {
    formId: FORM_ID_SHOULDER_ARM_AND_HAND_SURGERY,
    number: 16,
    title: 'Shoulder, Arm and Hand Surgeries',
  },
  {
    formId: FORM_ID_SURGERY_BACK_CHEST_ABDOMEN,
    number: 17,
    title: 'Back, Chest, Abdomen Surgeries',
  },
  {
    formId: FORM_ID_PELVIS_REPRODUCTIVE,
    number: 18,
    title: 'Pelvis and Reproductive Surgeries',
  },
  {
    formId: FORM_ID_HIP_LEG_AND_FOOT_SURGERY,
    number: 19,
    title: 'Hip, Leg & Foot Surgeries',
  },
  {
    formId: FORM_ID_LAB_WORK_IMAGING,
    number: 20,
    title: 'LabWork - Imaging',
  },
  {
    formId: FORM_ID_LAB_WORK_OTHER,
    number: 21,
    title: 'LabWork - Other',
  },
  {
    formId: FORM_ID_LAST_YEAR_PRIMARY_CARE,
    number: 22,
    title: 'Last Year Primary Care',
  },
  {
    formId: FORM_ID_LAST_YEAR_CLINICS,
    number: 23,
    title: 'Last Year - ER Clinics',
  },
  {
    formId: FORM_ID_HEALTH_SELECTIONS,
    number: 24,
    title: 'Health Care- Selections',
  },
  {
    formId: FORM_ID_ALLERGIES,
    number: 25,
    title: 'Allergies or Allergy Treatment',
  },
  {
    formId: FORM_ID_PAIN_MANAGEMENT,
    number: 26,
    title: 'Daily Pain and Pain Management',
  },
  {
    formId: FORM_ID_MEDICAL_EQUIPMENT,
    number: 27,
    title: 'Medical Equipment',
  },
  {
    formId: FORM_ID_DIABETIC_SUPPLIES,
    number: 28,
    title: 'Diabetic Supplies',
  },
  {
    formId: FORM_ID_MENTAL_HEALTH_CARE,
    number: 29,
    title: 'Mental Health',
  },
  {
    formId: FORM_ID_SUBSTANCE_USE,
    number: 30,
    title: 'Substance Use and Recovery',
  },
  {
    formId: FORM_ID_PRESCRIPTIONS_GENERIC,
    number: 31,
    title: 'Prescriptions - Generic',
  },
  {
    formId: FORM_ID_PRESCRIPTIONS_BRAND,
    number: 32,
    title: 'Prescriptions - Brand',
  },
  {
    formId: FORM_ID_PRESCRIPTIONS_SPECIALTY,
    number: 33,
    title: 'Prescriptions - Specialty',
  },
  {
    formId: FORM_ID_MEDICARE_ELIGIBILITY,
    number: 34,
    title: 'Medicare Eligibility',
  },
  {
    formId: FORM_ID_HSA_ACCOUNT,
    number: 35,
    title: 'HSA - Contribution',
  },
  {
    formId: FORM_ID_DENTAL_VISION,
    number: 36,
    title: 'Dental and Vision',
  },
  {
    formId: FORM_ID_ONLY_DENTAL,
    number: '36a',
    title: 'Dental Only',
  },
  {
    formId: FORM_ID_ONLY_VISION,
    number: '36b',
    title: 'Vision Only',
  },
  {
    formId: FORM_ID_DEPENDENTS_DENTAL,
    number: 37,
    title: 'Dependents - Dental',
  },
  {
    formId: FORM_ID_DEPENDENTS_DENTAL_PARTNER,
    number: '37a',
    title: 'Dependents - Dental (with Partner)',
  },
  {
    formId: FORM_ID_DEPENDENTS_DENTAL_CHILDREN,
    number: '37b',
    title: 'Dependents - Dental (with Children)',
  },
  {
    formId: FORM_ID_DEPENDENTS_VISION_COMBINED,
    number: 38,
    title: 'Dependents - Vision',
  },
  {
    formId: FORM_ID_DEPENDENTS_VISION_COMBINED_PARTNER,
    number: '38a',
    title: 'Dependents - Vision (with Partner)',
  },
  {
    formId: FORM_ID_DEPENDENTS_VISION_COMBINED_CHILDREN,
    number: '38b',
    title: 'Dependents - Vision (with Children)',
  },
  {
    formId: FORM_ID_DEPENDENTS_VISION_ONLY,
    number: '38c',
    title: 'Dependents - Vision (no Dental)',
  },
  {
    formId: FORM_ID_COVERAGE_CONFIRMATION,
    number: 100,
    title: 'Coverage Confirmation',
    path: routes.COVERAGE_CONFIRMATION,
  },
  {
    formId: FORM_ID_HSA_CONTRIBUTION,
    number: 102,
    title: 'HSA - Contribution',
    path: routes.HSA_CONTRIBUTION,
  },
  {
    formId: FORM_ID_HSA_LEARN_MORE,
    number: 103,
    title: 'HSA - Learn More',
    path: routes.LEARN_MORE_HSA_PAGE,
  },
  {
    formId: FORM_ID_FSA_LEARN_MORE,
    number: 104,
    title: 'FSA - Learn More',
    path: routes.LEARN_MORE_FSA_PAGE,
  },
  {
    formId: FORM_ID_RX_LEARN_MORE,
    number: 105,
    title: 'RX - Learn More',
    path: routes.LEARN_MORE_RX_PAGE,
  },
  {
    formId: PROGRESS_ID_INTRO,
    number: PROGRESS_ID_INTRO_NUMBER,
    title: 'Form Start',
    path: routes.PROGRESS,
  },
  {
    formId: PROGRESS_ID_ABOUT_YOU,
    number: 111,
    title: 'Progress About You',
    path: routes.PROGRESS,
  },
  {
    formId: PROGRESS_ID_HEALTH_EVENTS,
    number: 112,
    title: 'Progress Health Events',
    path: routes.PROGRESS,
  },
  {
    formId: PROGRESS_ID_ROUTINE_CARE,
    number: 113,
    title: 'Progress Routine Care',
    path: routes.PROGRESS,
  },
  {
    formId: PROGRESS_ID_RESULTS,
    number: 114,
    title: 'Progress Results',
    path: routes.PROGRESS,
  },
  {
    formId: PROGRESS_ID_ANCILLARY_BENEFITS,
    number: 115,
    title: 'Progress Other Benefits',
    path: routes.PROGRESS,
  },
];
