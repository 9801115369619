import { rest } from 'msw';
import { FORM_ID_PELVIS_REPRODUCTIVE } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_RADIO } from 'constants/questionTypes';
import { SECONDARY, TERTIARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 18
export const form18 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_PELVIS_REPRODUCTIVE}`,
  (req, res, ctx) => {
    const formId = FORM_ID_PELVIS_REPRODUCTIVE;
    const formTitle = 'What kind of pelvis and reproductive surgery?';
    const formSubtitle = 'Add as many as you need';
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: '9cf7f8d3-4cbe-430f-b220-6387490e3d01',
          title: 'Enlarged Prostate Surgery',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'e847588f-1bb5-4750-872a-021df56feeac',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '9cf7f8d3-4cbe-430f-b220-6387490e3d01',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '079e111f-85f8-4023-918c-a78a8d40b0a8',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '9cf7f8d3-4cbe-430f-b220-6387490e3d01',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '49530036-d690-40a0-9524-260c512b4506',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '9cf7f8d3-4cbe-430f-b220-6387490e3d01',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '0907b50d-a4a9-499c-b480-2ce16a01ff2b',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '9cf7f8d3-4cbe-430f-b220-6387490e3d01',
          },
          multipleChoiceOptions: [
            {
              id: '964ac44a-76e3-4205-b608-4cc2444320dc',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '46ac3ebf-9b11-404f-9acf-6911a5d4b58b',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'd6ae6666-c5d0-4ec8-beb8-956665d62f10',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '93c2eef1-b539-4e01-9182-0ffa19506252',
          title: 'Dilation and Curettage (D&C)',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '63cf9f11-be4a-4dd6-b613-010770089cca',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '93c2eef1-b539-4e01-9182-0ffa19506252',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'ab0a1dd4-c852-45fc-988d-c3522f0f863f',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '93c2eef1-b539-4e01-9182-0ffa19506252',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '54df9013-3372-407f-ba04-ff2046e7cf11',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '93c2eef1-b539-4e01-9182-0ffa19506252',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '3444978c-43b2-4fed-a276-84ecdcf8b440',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '93c2eef1-b539-4e01-9182-0ffa19506252',
          },
          multipleChoiceOptions: [
            {
              id: 'e7aed4c8-3948-4b42-a908-cfef0363cd3d',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '1650a2f9-1f4a-4ef9-bd28-498818a278b0',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'b43ac341-eb5f-4f2d-b1fa-be900a3fdcfb',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '7946711b-9a64-4efb-9be8-9b4ec15aa259',
          title: 'Hemorrhoid Surgery',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a0b31dbc-265f-4e93-a730-573528108ba0',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '7946711b-9a64-4efb-9be8-9b4ec15aa259',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '9bbbc05c-a519-45bc-86e9-ccac00ffc3d2',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '7946711b-9a64-4efb-9be8-9b4ec15aa259',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'cf09e35d-b024-4044-80c7-0609e0d9001d',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '7946711b-9a64-4efb-9be8-9b4ec15aa259',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'eaac9003-a9d2-4a85-95c7-7d6b787d9d2a',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '7946711b-9a64-4efb-9be8-9b4ec15aa259',
          },
          multipleChoiceOptions: [
            {
              id: '85018257-b73d-45d0-9148-5ec21586ec23',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: 'de82e99e-b571-43c2-8835-5da0d0d13e92',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: '294b7004-d16f-40b2-935c-594ac3bb0341',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '975a67b6-fbbf-4a35-b204-b2bc1b60efc8',
          title: 'Hysterectomy - Abdominal',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '0cb0081e-642c-4e80-8557-418fc71d769d',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '975a67b6-fbbf-4a35-b204-b2bc1b60efc8',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'db4de71c-0f9e-4b6c-9a14-20ab3d97f1c7',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '975a67b6-fbbf-4a35-b204-b2bc1b60efc8',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a2995828-ce7e-4d4a-8461-26632020baac',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '975a67b6-fbbf-4a35-b204-b2bc1b60efc8',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '55dd565b-2796-446d-be99-b089e1264c1d',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '975a67b6-fbbf-4a35-b204-b2bc1b60efc8',
          },
          multipleChoiceOptions: [
            {
              id: '8414f301-1bf7-48cf-a2d9-8625f87f4b83',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: 'fcf49685-66ea-4b30-9bdd-562349ff29f1',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: '953d5e53-23a5-47b9-8349-60791e64aa49',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '5d21be96-9a96-4840-b601-b34852583f71',
          title: 'Hysterectomy - Vaginal',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '6734efa4-8c81-4b4f-b89b-a0a38139f045',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '5d21be96-9a96-4840-b601-b34852583f71',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '16a914fc-1a26-4acb-8ad6-c659b224464b',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '5d21be96-9a96-4840-b601-b34852583f71',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a8790f51-fe32-486c-980f-57e3d3fa43f8',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '5d21be96-9a96-4840-b601-b34852583f71',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'd9062a48-dda6-4ae3-a8ac-45332169fce2',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '5d21be96-9a96-4840-b601-b34852583f71',
          },
          multipleChoiceOptions: [
            {
              id: 'fab42bff-52f8-4274-84ac-6e990fdaabce',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '8b192807-632f-4831-9058-05dcb145c131',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'ba04dc89-f98b-43c2-96dd-3e619454df9d',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '2a2fb281-837f-44e7-ac18-55eba1dce4b0',
          title: 'Ovary and Fallopian Tube Removal',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'd159f689-09c2-4096-9999-396f0640cbb9',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '2a2fb281-837f-44e7-ac18-55eba1dce4b0',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'caee278f-6de8-45a1-b2dd-0587b483596b',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '2a2fb281-837f-44e7-ac18-55eba1dce4b0',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '5976dd37-10ae-4302-8fa4-82e3bcb2e9c1',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '2a2fb281-837f-44e7-ac18-55eba1dce4b0',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'd8cf5421-fa1f-4b7d-b4bd-929f0b21095a',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '2a2fb281-837f-44e7-ac18-55eba1dce4b0',
          },
          multipleChoiceOptions: [
            {
              id: '170cca0a-3436-4d58-ae2e-4d2506023914',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '6c372f4e-750b-4526-898a-ec39d13120b0',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'b4aa432a-9103-407b-926a-ce0452dd483e',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '967f25d6-c0c7-42c0-8b9c-a92a84e32de6',
          title: 'Uterine Fibroid Removal',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '5e0a55af-584c-466f-af06-c07a754a8912',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '967f25d6-c0c7-42c0-8b9c-a92a84e32de6',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'ef333fb7-8ab3-4906-ab21-acabe6e51992',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '967f25d6-c0c7-42c0-8b9c-a92a84e32de6',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '927fea10-97d4-4575-adb0-ada7fdaad2a5',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '967f25d6-c0c7-42c0-8b9c-a92a84e32de6',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'cb6456e6-e8d7-4774-b2b5-78a2c93f0ae2',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '967f25d6-c0c7-42c0-8b9c-a92a84e32de6',
          },
          multipleChoiceOptions: [
            {
              id: 'b9d5e56d-cf96-4ea0-894f-08e0db22e22f',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: 'd66de1d5-ef03-444a-bace-19011aae0ab9',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'cbb5f1ac-f194-4465-8b48-eca331655149',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '16dcf5b5-93b1-4aa6-b578-81fd275730a4',
          title: 'General Surgery',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '4c837a17-e9ad-41ff-a95c-5e90bed420d8',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '16dcf5b5-93b1-4aa6-b578-81fd275730a4',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '42514852-83d4-480c-81fb-abd2745da600',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '16dcf5b5-93b1-4aa6-b578-81fd275730a4',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'd178b733-d8e3-4857-80f3-db69bcd60de3',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '16dcf5b5-93b1-4aa6-b578-81fd275730a4',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'fffb1d3c-685f-424f-b6f6-fd8bf707ea8b',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '16dcf5b5-93b1-4aa6-b578-81fd275730a4',
          },
          multipleChoiceOptions: [
            {
              id: '159636a0-5fff-4f9e-83f3-ef864f65e3e3',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '47ca291e-dba2-40d6-9dd1-93eee74d3f98',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: '66b23978-ffc4-41e4-afa2-4fab4a13ba3d',
              title: "I don't know",
            },
          ],
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        "If you don't see a surgery that you're planning or don't know the name, don't worry! Select General Surgery and I will factor an average cost surgery into my recommendation.",
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formSubtitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
