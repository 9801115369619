import api from 'api';

export const USER_ASYNC_START = 'USER_ASYNC_START';
export const USER_ASYNC_FAIL = 'USER_ASYNC_FAIL';
export const USER_ASYNC_SUCCESS = 'USER_ASYNC_SUCCESS';
export const USER_ACTION_SUCCESS = 'USER_ACTION_SUCCESS';

function userAsyncStart() {
  return {
    type: USER_ASYNC_START,
  };
}

function userAsyncFail(errorMessage) {
  return {
    type: USER_ASYNC_FAIL,
    errorMessage,
  };
}

export function userAsyncSuccess(data) {
  return {
    type: USER_ASYNC_SUCCESS,
    data,
  };
}

export function userActionSuccess(data) {
  return {
    type: USER_ACTION_SUCCESS,
    data,
  };
}

export const authenticate = (email, password) => {
  return async (dispatch) => {
    dispatch(userAsyncStart());
    try {
      const response = await api({
        path: `authenticate`,
        method: 'POST',
        body: { email, password },
      });
      dispatch(userAsyncSuccess(response));
      return response;
    } catch (errorMessage) {
      dispatch(userAsyncFail(errorMessage));
      return null;
    }
  };
};

export const deauthenticate = () => {
  return async (dispatch) => {
    dispatch(userActionSuccess());
  };
};
