import { rest } from 'msw';
import { FORM_ID_EARLY_INTERVENTION_PAYMENT_TYPES } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_RADIO } from 'constants/questionTypes';

// Form ID from the MHM Decision Doc Copy Document: 6
export const form6 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_EARLY_INTERVENTION_PAYMENT_TYPES}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_EARLY_INTERVENTION_PAYMENT_TYPES;
    const formContent = '';
    const formTitle = `Will you be using your health insurance to pay for any <br /> <span class="dotted-underline--trigger">Early Intervention Services?</span>`;
    const formCssId = 'early-intervention';
    const groupedQuestions = [
      [
        {
          id: '7bd2e009-e74f-415d-9d38-bcda7f3cbaea',
          title: '',
          subtitle: '',
          titleLarge: null,
          type: INPUT_RADIO,
          multipleChoiceOptions: [
            {
              id: 'eb415ad2-c14b-452c-a573-c3a6bd675e85',
              title:
                'Yes, I would use the health insurance my employer offers to pay for these services.',
              sidebar: null,
              nextPageLink: 'temp-page-early-intervention-services',
            },
            {
              id: '2dfc5d4f-0c5e-4303-ac02-653c468cbfb9',
              title: 'No, I would only use state or school funded programs',
              sidebar: null,
              nextPageLink: '/',
            },
          ],
          dependency: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'Only choose the services that you pay for with your health insurance. Choose whichever services fit your family’s needs.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formCssId,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
