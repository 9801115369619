const routes = {
  ABOUT: '/about',
  COMPONENT_LIBRARY: '/component-library',
  HSA_CONTRIBUTION: '/hsa-contribution',
  COVERAGE_CONFIRMATION: '/coverage-confirmation',
  FORM: '/form',
  GET_STARTED: '/get-started',
  HOME: '/',
  LANDING_PAGE: '/landing-page',
  LEARN_MORE_HSA_PAGE: '/hsa-learn-more',
  LEARN_MORE_FSA_PAGE: '/fsa-learn-more',
  LEARN_MORE_RX_PAGE: '/rx-learn-more',
  LOGOUT: '/logout',
  PROGRESS: '/progress',
  RESULTS: '/results',
  CBR: '/cbr',
  SUBSCRIBER: '/subscriber',
  GLOSSARY_PAGE: '/glossary',
  JOB_NOT_FOUND: '/jobnotfound',
  FORM_LINKS: '/form-links', // Mock Route
  MOCK_SSO_LOGIN_PAGE: '/mock-sso-login', // Mock Route
  MOCK_SHERPA_RESULTS: '/mock-sherpa-results', // Mock Route
};

export const routeTypes = {
  AUTHENTICATED_ONLY: 'authenticated',
  UNAUTHENTICATED_ONLY: 'unauthenticated',
  PUBLIC: 'public',
  REGISTERED_GUEST: 'guest',
};

export default routes;
