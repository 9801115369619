import { rest } from 'msw';
import { FORM_ID_EARLY_INTERVENTION_SERVICES } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN } from 'constants/questionTypes';
import { SECONDARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 7
export const form7 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_EARLY_INTERVENTION_SERVICES}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_EARLY_INTERVENTION_SERVICES;
    const formContent = '';
    const formTitle = `Which Early Intervention Services apply?`;
    const formCssId = 'early-intervention';
    const groupedQuestions = [
      [
        {
          id: 'f6cc1ea0-304d-4492-a114-50dc1b33795d',
          title: 'Assistive Technology Devices and Services',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: [],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '325af5fd-143e-4828-87c5-afa265367c99',
          title: 'Mira',
          subtitle: '',
          titleLarge: "Who's it for?",
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'f6cc1ea0-304d-4492-a114-50dc1b33795d',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '325af6fd-143e-4828-87c5-afa265367c00',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'f6cc1ea0-304d-4492-a114-50dc1b33795d',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '93db0614-f151-47db-b7f3-e0fb42124808',
          title: 'Audiology and/or Speech Language Services',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: [],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '18fc0c52-448e-47de-9191-cc53d9e9c3e3',
          title: 'Mira',
          subtitle: '',
          titleLarge: "Who's it for?",
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '93db0614-f151-47db-b7f3-e0fb42124808',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '044bc8bd-d59f-4dc9-ac30-ca188ad1b55c',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '93db0614-f151-47db-b7f3-e0fb42124808',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '9f4e02a0-4af5-4223-b0f6-0bf4d81bd301',
          title: 'Nutrition Services',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: [],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '5ad65c0c-7d7a-461e-8863-19eb85113843',
          title: 'Mira',
          subtitle: '',
          titleLarge: "Who's it for?",
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '9f4e02a0-4af5-4223-b0f6-0bf4d81bd301',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '8eb188d4-07fa-4f5d-bfb4-7bedf6eed039',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '9f4e02a0-4af5-4223-b0f6-0bf4d81bd301',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '837af5ef-34d8-4a27-be5d-3544491e8010',
          title: 'Home Nursing Services',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: [],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '940ece8b-21da-4bc6-9f20-dfa55eb4da5e',
          title: 'Mira',
          subtitle: '',
          titleLarge: "Who's it for?",
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '837af5ef-34d8-4a27-be5d-3544491e8010',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '27e457dc-5c21-4b0a-949a-9883ac1388de',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '837af5ef-34d8-4a27-be5d-3544491e8010',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'afbe53e7-4ec5-48ac-847f-9699c5ef18cb',
          title: 'Physical or Occupational Therapy',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: [],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'bda4f0a5-c6e5-4d38-a17c-ee9eff0617b1',
          title: 'Mira',
          subtitle: '',
          titleLarge: "Who's it for?",
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'afbe53e7-4ec5-48ac-847f-9699c5ef18cb',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '3d497c82-fed1-4ae4-8880-663198871a22',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'afbe53e7-4ec5-48ac-847f-9699c5ef18cb',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'cbb047a7-c1d9-4017-99fc-28f6414541ca',
          title: 'Psychological Evaluation and/or Counseling',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: [],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '871b095a-3dd8-4196-91a5-455fafb24649',
          title: 'Mira',
          subtitle: '',
          titleLarge: "Who's it for?",
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'cbb047a7-c1d9-4017-99fc-28f6414541ca',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'aa85d38f-39a4-4a08-bd3a-867a7dd1d247',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'cbb047a7-c1d9-4017-99fc-28f6414541ca',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'afbe53e7-4ec5-48ac-847f-9699c5ef1221',
          title: 'Vision Services',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: [],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '161996da-9348-427c-917e-837258fe8c72',
          title: 'Mira',
          subtitle: '',
          titleLarge: "Who's it for?",
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'afbe53e7-4ec5-48ac-847f-9699c5ef1221',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'b4dcf6ce-12dc-470b-ae56-2e2b1d752948',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'afbe53e7-4ec5-48ac-847f-9699c5ef1221',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'afbe53e7-4ec5-48ac-847f-9699c5ef4384',
          title: 'Other Early Intervention Services',
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: [],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '0bdd3c4b-1ef4-48c2-a9d3-c75e0781331a',
          title: 'Mira',
          subtitle: '',
          titleLarge: "Who's it for?",
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'afbe53e7-4ec5-48ac-847f-9699c5ef4384',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '5d7ffa3a-50ce-4f10-b5a6-bc2c72546b21',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'afbe53e7-4ec5-48ac-847f-9699c5ef4384',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        "Only choose the services that you pay for with your health insurance. Choose whichever services fit your family's needs.",
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formCssId,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
