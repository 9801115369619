import { rest } from 'msw';

import { FORM_ID_FSA_LEARN_MORE } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { FORM_TYPE_FSA_LEARN_MORE } from 'constants/formTypes';

export const formLearnMoreFSA = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_FSA_LEARN_MORE}`,
  async (req, res, ctx) => {
    const hsaFsaData = {
      bottomTheoCard: {
        title:
          'You will be able to see how this contribution impacts total cost in the final results.',
        subtitle:
          'You will also be able to change your contributions to see different results.',
        body: 'Dynamic body text rendered here.',
        smallText: 'Dynamic small text rendered here.',
      },
      educationTheoText:
        'A Flexible Spending Account (FSA) is a medical savings account that you own. If you are enrolled in a High Deductible Health Plan (HDHP), both you and your employer can contribute pre-tax dollars into an FSA that you can use to pay for qualified medical expenses.',
      maxContributionAmount: 2750,
      taxRate: 0.26,
    };

    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId: FORM_ID_FSA_LEARN_MORE,
        formType: FORM_TYPE_FSA_LEARN_MORE,
        hsaFsaData,
      }),
    );
  },
);
