import React from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';

const PublicRoute = ({ component: Component, exact, path }) => {
  const renderComponent = () => {
    return <Component />;
  };

  return <Route exact={exact} path={path} render={() => renderComponent()} />;
};

const WrappedComponent = withRouter(PublicRoute);
export default WrappedComponent;
