export const STORAGE_JOB_ID = 'jobId';
export const STORAGE_IS_SHERPA = 'isSherpaTF';
export const STORAGE_JOB_HAS_CBR = 'jobHasCbr';
export const STORAGE_SCHEDULE_CODE = 'scheduleCode';
export const STORAGE_AUTH_TOKEN = 'authToken';
export const STORAGE_FIRST_NAME = 'firstName';
export const STORAGE_FORM_ID = 'formId';
export const STORAGE_HAS_SUBSCRIBER_ERROR = 'hasSubscriberError';
export const STORAGE_CBR_ID = 'cbrId';
export const STORAGE_QUESTIONNAIRE_ID = 'questionnaireId';
export const STORAGE_QUESTIONNAIRE_IS_COMPLETE = 'questionnaireIsComplete';
export const STORAGE_AUTH0_CONNECTION = 'auth0connection';
export const STORAGE_AUTH0_ENABLED = 'auth0enabled';
export const STORAGE_AUTH0_USER = 'auth0user';
export const STORAGE_AUTH0_LOCAL_STORE_EXPIRATION = 'localStorageExpirationDate';
export const STORAGE_SLUG = 'slug';
export const STORAGE_EDITING_RESPONSES = 'editingResponses';
export const STORAGE_USER_EXISTS_IN_DECISION_DOC = 'userExistsInDecisionDoc';
export const STORAGE_SHERPA_CUSTOM_AUTH = 'sherpaCustomAuth';
export const STORAGE_SHERPA_ENCRYPTED_CONTENT = 'sherpaEncryptedContent';
export const STORAGE_WHITELABEL_CSS_LOCATION = 'whiteLabelCssLocation';
