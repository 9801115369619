import { rest } from 'msw';
import { SHERPA_FORM_ID_MONTHLY_BASIC_ESSENTIAL_EXPENSES } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_NUMBER } from 'constants/questionTypes';

export const sherpaForm206 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_MONTHLY_BASIC_ESSENTIAL_EXPENSES}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_MONTHLY_BASIC_ESSENTIAL_EXPENSES;
    const formTitle = `How much are your average monthly household expenses?`;
    const formSubtitle = `If you’re not sure, give us your best guess!`;
    const formContent = null;
    const formIsSherpa = true;
    const groupedQuestions = [
      [
        {
          id: 'a2762547-bee5-4a7e-bfe9-66b2cfc667cf',
          title: 'Average Monthly Expenses',
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          multipleChoiceOptions: null,
          max: 50000,
          min: 100,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '$',
          suffix: '',
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'These include things like food, transportation, utility bills, etc.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
