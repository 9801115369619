import { rest } from 'msw';
import { FORM_ID_ONLY_VISION } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_RADIO } from 'constants/questionTypes';

export const formOnlyVision = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_ONLY_VISION}`,
  (req, res, ctx) => {
    const formId = FORM_ID_ONLY_VISION;
    const formTitle = `Are you interested in learning more about your company's vision plan?`;
    const formSubtitle = `I can include information about these plans in your plan guidance.`;
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: 'dfc17f11-ad23-4ac7-bdf7-1d112c1c49f9',
          title: '',
          type: INPUT_RADIO,
          multipleChoiceOptions: [
            {
              id: '5321780f-d7f8-423b-88cb-263904e928ed',
              title: 'Yes',
            },
            {
              id: '7bd2563d-2347-41bb-a5dc-8b04363e8f9c',
              title: 'No',
            },
          ],
          dependency: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'Testing - Theo summary text for Vision Only question screen.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
