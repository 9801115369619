// src/mocks/handlers.js
import getObjConvertedToArray from 'helpers/getObjConvertedToArray';
import * as sidebars from './sidebars';
import * as forms from './forms/';
import * as formSubmit from './formSubmit';
import * as landingPages from './landingPages';
import * as results from './results';

const sidebarArray = getObjConvertedToArray(sidebars);
const formArray = getObjConvertedToArray(forms);
const formSubmitArray = getObjConvertedToArray(formSubmit);
const landingPagesArray = getObjConvertedToArray(landingPages);
const resultsArray = getObjConvertedToArray(results);

export const handlers = [
  ...sidebarArray,
  ...formArray,
  ...formSubmitArray,
  ...landingPagesArray,
  ...resultsArray,
];
