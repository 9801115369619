import { rest } from 'msw';
import { FORM_ID_HSA_ACCOUNT } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_NUMBER, INPUT_RADIO } from 'constants/questionTypes';
import { SECONDARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 35
export const form35 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_HSA_ACCOUNT}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_HSA_ACCOUNT;
    const formTitle =
      'Would you be interested in a Health Savings Account (HSA)?';
    const formSubtitle = '';
    const formContent = '';
    const formCssId = '';
    const groupedQuestions = [
      [
        {
          id: '9865ef63-23ae-4730-ae0b-975ece34e854',
          title: '',
          type: INPUT_RADIO,
          multipleChoiceOptions: [
            {
              id: '5b5561cc-6d21-4d3e-8e73-afe84fb21ea7',
              title: 'Yes',
            },
            {
              id: '74b4107b-12af-4d20-b057-0bdfb49ededd',
              title: 'No',
            },
          ],
          dependency: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '090bb50d-a4a9-499c-b480-2ce16a01ff2b',
          title: "Do you think you'd maximize your contribution?",
          type: INPUT_RADIO,
          dependency: {
            status: SECONDARY,
            questionId: '9865ef63-23ae-4730-ae0b-975ece34e854',
            answerId: '5b5561cc-6d21-4d3e-8e73-afe84fb21ea7',
          },
          multipleChoiceOptions: [
            {
              id: '964ac44a-76e3-4205-b608-4cc2444320dc',
              title: 'Maximize to',
              titleSuffix: '- $400',
            },
            {
              id: '46ac3ebf-9b11-404f-9acf-6911a5d4b58b',
              title: 'Do not maximize contribution',
            },
          ],
          max: 26,
          min: 0,
        },
        {
          id: '7d333fa2-39e8-4103-9e31-c75fc1a12b51',
          title: 'Contribution amount:',
          type: INPUT_NUMBER,
          dependency: {
            status: SECONDARY,
            questionId: '9865ef63-23ae-4730-ae0b-975ece34e854',
            answerId: '74b4107b-12af-4d20-b057-0bdfb49ededd',
          },
          multipleChoiceOptions: null,
          max: 80,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        "Don't worry, saying yes or no doesn't mean you need to get or contribute to an HSA. However, I'll take your selection (yes or no) into consideration in my analysis.",
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formSubtitle,
        formCssId,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
