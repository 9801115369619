import client from './client';

const getPath = ({ path, pathSuffix, id }) => {
  if (id) {
    return pathSuffix ? `${path}/${pathSuffix}/${id}` : `${path}/${id}`;
  }
  return pathSuffix ? `${path}/${pathSuffix}` : path;
};

const restGet = (additionalParams) => async (filters) => {
  try {
    const { path, pathSuffix, authToken } = additionalParams;
    const { id } = filters;
    const response = await client.get({
      ...additionalParams,
      path: getPath({ path, pathSuffix, id }),
      authToken,
    });

    return response;
  } catch (error) {
    console.error(error, 'error');
    return;
  }
};

const restList = (additionalParams) => async (filters) => {
  try {
    const { path, pathSuffix, authToken } = additionalParams;
    const response = await client.list({
      ...additionalParams,
      authToken,
      body: filters,
      path: getPath({ path, pathSuffix }),
    });

    return response;
  } catch (error) {
    console.error(error, 'error');
    return;
  }
};

const restPost = (additionalParams) => async (filters) => {
  try {
    const { path, pathSuffix, authToken } = additionalParams;
    const response = await client.post({
      body: JSON.stringify(filters),
      authToken,
      path: getPath({ path, pathSuffix }),
    });

    return response;
  } catch (error) {
    console.error(error, 'error');
    return;
  }
};

const restPatch = (additionalParams) => async (filters) => {
  try {
    const { path, pathSuffix, authToken } = additionalParams;
    const response = await client.patch({
      ...additionalParams,
      path: getPath({ path, pathSuffix }),
      authToken,
      body: JSON.stringify(filters),
    });

    return response;
  } catch (error) {
    console.error(error, 'error');
    return;
  }
};

const restPut = (additionalParams) => async (filters) => {
  try {
    const { path, pathSuffix, authToken } = additionalParams;
    const response = await client.put({
      body: JSON.stringify(filters),
      authToken,
      path: getPath({ path, pathSuffix }),
    });

    return response;
  } catch (error) {
    console.error(error, 'error');
    return;
  }
};

const restDelete = (additionalParams) => async (filters) => {
  try {
    const { path, pathSuffix, authToken } = additionalParams;
    const response = await client.patch({
      ...additionalParams,
      path: getPath({ path, pathSuffix }),
      authToken,
      body: filters,
    });

    return response;
  } catch (error) {
    console.error(error, 'error');
    return;
  }
};

export default {
  restGet,
  restList,
  restPost,
  restPatch,
  restPut,
  restDelete,
};
