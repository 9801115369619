import { rest } from 'msw';
import { SHERPA_FORM_ID_CRITICAL_ILLNESS } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';

export const sherpaForm211 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_CRITICAL_ILLNESS}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_CRITICAL_ILLNESS;
    const formHeader = 'CRITICAL ILLNESS';
    const formTitle = 'Who’s covered by your critical illness policy?';
    const formSubtitle = null;
    const formContent = null;
    const formIsSherpa = true;
    const groupedQuestions = [
      [
        {
          id: '431e4c87-ca37-43b3-b0dd-b71669d4a833',
          title: 'Spouse',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: null,
          allowNullQuestions: true,
        },
      ],
      [
        {
          id: '1ba7db1b-94f8-4a3b-ad09-721ec9089eb4',
          title: 'Child(ren)',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: null,
          allowNullQuestions: true,
        },
      ],
      [
        {
          id: '41cc1bcb-3da9-4f0a-9583-3f91afb353b3',
          title: 'Total Payout',
          subtitle: '',
          titleLarge:
            'How much would your insurance pay out if you were diagnosed with a covered condition?',
          type: INPUT_NUMBER,
          dependency: null,
          max: 6000000,
          min: 1000,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '$',
          suffix: '',
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'Stuff about this',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formHeader,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
