import { rest } from 'msw';
import { FORM_ID_PAIN_MANAGEMENT } from 'constants/mocks/formIds';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { SECONDARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 26
export const form26 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_PAIN_MANAGEMENT}`,
  (req, res, ctx) => {
    const formId = FORM_ID_PAIN_MANAGEMENT;
    const formTitle =
      'Is it likely that anyone in your household will get injections for their pain?';
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: '4964dcd5-5857-440f-a7d2-c782c23b3710',
          title: 'Yes',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['c069f259-8955-457a-b6ae-8e489fece01f'],
          },
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '52bf905f-c8a2-495c-a182-0efafe7f64e0',
          titleLarge: 'How many injections will each person need in a year?',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '4964dcd5-5857-440f-a7d2-c782c23b3710',
            answerId: null,
            disableOnQuestionIds: ['c069f259-8955-457a-b6ae-8e489fece01f'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
        {
          id: '8b8aea20-f471-4a13-8d69-5f4e3558c5f2',
          title: 'David',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '5621d033-1eb8-4b76-bfc0-7acb08fa409b',
            answerId: null,
            disableOnQuestionIds: ['c069f259-8955-457a-b6ae-8e489fece01f'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
        {
          id: '0484629d-6358-421e-82d9-5ee47cba5c00',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'e361dbc6-6bcb-44f1-9c14-07e610ba831d',
            answerId: null,
            disableOnQuestionIds: ['946529ce-46ae-4516-9275-193ebe360e52'],
          },
          max: 26,
          min: 0,
          required: false,
          selectedAnswer: null,
          hideAnswer: false,
          img: null,
        },
      ],
      [
        {
          id: 'c069f259-8955-457a-b6ae-8e489fece01f',
          title: 'No',
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['4964dcd5-5857-440f-a7d2-c782c23b3710'],
          },
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          sidebar: {},
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'Some people receive injections, like Cortisone or Botox, for pain ' +
        'management. Select yes if this applies to you or your family. Then, ' +
        'tell me how many injections per year!',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
