import { rest } from 'msw';
import { FORM_ID_PREGNANCY_SERVICES } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN } from 'constants/questionTypes';
import { SECONDARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 12
export const form12 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_PREGNANCY_SERVICES}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_PREGNANCY_SERVICES;
    const formTitle =
      'Do you expect to need any of these pregnancy-related services?';
    const formSubtitle = '';
    const formContent = '';
    const formCssId = '';
    const groupedQuestions = [
      [
        {
          id: '2cd5072e-e445-4a94-ae0c-d075a6bde5d7',
          title: 'Prenatal Care',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['43338aff-49fe-45cc-8a2e-0be1b3b45fbe'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          sidebar: {},
        },
        {
          id: 'dac71583-fa92-476d-a8b4-acfdaaa2cfc7',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '2cd5072e-e445-4a94-ae0c-d075a6bde5d7',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '0ae050a6-a4b3-496b-ab10-9ec3057725dd',
          subtitle: '',
          titleLarge: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '2cd5072e-e445-4a94-ae0c-d075a6bde5d7',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'f7e98156-abc8-467c-ac2a-9a8f27152905',
          subtitle: '',
          titleLarge: '',
          title: 'Dave',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '2cd5072e-e445-4a94-ae0c-d075a6bde5d7',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '04a919b0-0f91-4f3f-a55d-ff4f86faf8aa',
          title: 'C-Section Delivery and postnatal care',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['43338aff-49fe-45cc-8a2e-0be1b3b45fbe'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          sidebar: {},
        },
        {
          id: '91acfde5-06d0-4025-a461-157ec531b248',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '04a919b0-0f91-4f3f-a55d-ff4f86faf8aa',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '798a1625-cb03-4212-ade0-5d107bb0f466',
          subtitle: '',
          titleLarge: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '04a919b0-0f91-4f3f-a55d-ff4f86faf8aa',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'fd8a6281-7093-431d-81aa-131f2daf504d',
          subtitle: '',
          titleLarge: '',
          title: 'Dave',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '04a919b0-0f91-4f3f-a55d-ff4f86faf8aa',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '5df26690-d855-4540-85ed-18e839463507',
          title: 'Vaginal Delivery and postnatal care',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['43338aff-49fe-45cc-8a2e-0be1b3b45fbe'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          sidebar: {},
        },
        {
          id: '5a791805-f5a7-4d58-8936-b33427a19159',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '5df26690-d855-4540-85ed-18e839463507',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '8f9633dc-a462-4d96-873a-9bb9f523bd2a',
          subtitle: '',
          titleLarge: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '5df26690-d855-4540-85ed-18e839463507',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'd56cd285-e22e-4cfb-a282-2d8e78f664d1',
          subtitle: '',
          titleLarge: '',
          title: 'Dave',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '5df26690-d855-4540-85ed-18e839463507',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '80869cfa-75fc-4699-ac41-001ac0a0cf2c',
          title: 'Unknown Delivery and postnatal care',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['43338aff-49fe-45cc-8a2e-0be1b3b45fbe'],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          sidebar: {},
        },
        {
          id: 'e36cca3f-c1ab-4cea-a713-0d7bc3261039',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '80869cfa-75fc-4699-ac41-001ac0a0cf2c',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'f5b170e3-0c04-48a9-ae2d-6696688b608c',
          subtitle: '',
          titleLarge: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '80869cfa-75fc-4699-ac41-001ac0a0cf2c',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '29692579-3402-4a1a-84c1-65aa7465ec24',
          subtitle: '',
          titleLarge: '',
          title: 'Dave',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '80869cfa-75fc-4699-ac41-001ac0a0cf2c',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '43338aff-49fe-45cc-8a2e-0be1b3b45fbe',
          title: 'None of these',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            questionId: null,
            answerId: null,
            disableOnQuestionIds: [
              '2cd5072e-e445-4a94-ae0c-d075a6bde5d7',
              '04a919b0-0f91-4f3f-a55d-ff4f86faf8aa',
              '5df26690-d855-4540-85ed-18e839463507',
              '80869cfa-75fc-4699-ac41-001ac0a0cf2c',
            ],
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        "Select only one type of delivery. If you're not sure, take your best guess and you can change your answers later.",
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formSubtitle,
        formContent,
        formCssId,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
