import { rest } from 'msw';
import { FORM_ID_DIABETIC_SUPPLIES } from 'constants/mocks/formIds';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { SECONDARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 28
export const form28 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_DIABETIC_SUPPLIES}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_DIABETIC_SUPPLIES;
    const formTitle =
      'You mentioned needing diabetic supplies. Do any of these apply?';
    const formSubtitle = '';
    const formContent = '';
    const groupedQuestions = [
      [
        {
          id: '6ad4c5e4-3757-4830-8c24-f9986cd2f070',
          title: 'Insulin Pump',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '3c6f6ddc-019d-4366-91a6-aa80f233b3cc',
          titleLarge: 'Who will need a new insulin pump?',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '6ad4c5e4-3757-4830-8c24-f9986cd2f070',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '3d5cb136-9dbf-44e5-b469-ad0ea8bd3ec5',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '6ad4c5e4-3757-4830-8c24-f9986cd2f070',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '27e3ca7b-5cc0-4bbe-acbc-c9bbd147020e',
          title: 'David',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '6ad4c5e4-3757-4830-8c24-f9986cd2f070',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a3ae79da-83ff-4f35-ac45-96d3d2c3d688',
          title: 'Blood Glucose Monitor',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'bc27c624-d025-49d4-b365-2f21220d700b',
          titleLarge: 'Who will need a new blood glucose monitor?',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a3ae79da-83ff-4f35-ac45-96d3d2c3d688',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'dcb8e4ab-872a-4acb-8a76-635c72a08d0d',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a3ae79da-83ff-4f35-ac45-96d3d2c3d688',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '11f42a68-18e7-43da-8784-7637597a0331',
          title: 'David',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a3ae79da-83ff-4f35-ac45-96d3d2c3d688',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'd4e47761-8473-4edd-afc3-407b7334431d',
          title: 'Test Strips',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'd9854936-b194-498a-b945-4a0c8f3a89a2',
          titleLarge:
            'How many boxes of test strips will each person need per month?',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'd4e47761-8473-4edd-afc3-407b7334431d',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'd1bf3d86-7cfc-4f39-8d34-ba0998f14892',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'd4e47761-8473-4edd-afc3-407b7334431d',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'b094e6d8-4b2c-4225-a235-d38cf6f1d4ca',
          title: 'David',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'd4e47761-8473-4edd-afc3-407b7334431d',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'f8bc9213-2ef0-406a-9e64-a1256d2cd4c2',
          title: 'Lancets',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'dd0c8376-aba3-4346-9fed-b34f0491239f',
          titleLarge:
            'How many boxes of lancets will each person need per month?',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'f8bc9213-2ef0-406a-9e64-a1256d2cd4c2',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '6bc6f67b-0f19-4a7c-9498-279e60bc405a',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'f8bc9213-2ef0-406a-9e64-a1256d2cd4c2',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '8173bfb4-aa1f-49e6-8c16-24c08de8744a',
          title: 'David',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'f8bc9213-2ef0-406a-9e64-a1256d2cd4c2',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'Only select Insulin Pump and Blood Glucose Monitor if you might need new ones! ',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formSubtitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
