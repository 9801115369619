export const MOCK_PROGRESS_INTRO = 1;
export const MOCK_PROGRESS_ABOUT_YOU = 2;
export const MOCK_PROGRESS_HEALTH_EVENTS = 3;
export const MOCK_PROGRESS_ROUTINE_CARE = 4;
export const MOCK_PROGRESS_RESULTS = 5;

export const MOCK_CBR_ID = '44c3eb0d-1e34-49d8-bb43-8e6e1d3d5044';
export const MOCK_QUESTIONNAIRE_ID = '22c3eb0d-1e34-49d8-bb43-8e6e1d3d50c6';
export const MOCK_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjNjU0MTk5YS1jNTQ5LTRkMTItODYzMS04ZGJiMzBlNzRmYzAiLCJlbWFpbCI6InJpY29AcHJheGVudC5jb20iLCJnaXZlbl9uYW1lIjoiUmljbyIsImZhbWlseV9uYW1lIjoiVGVzdCIsImJpcnRoZGF0ZSI6IjA5LzAxLzE5OTAiLCJqdGkiOiJjZDhkNzE4ZC0yNGZhLTRkMGEtOGU1Yi0xOWRiMzZjMTQzMjAiLCJqb2IiOiIxMDAwMzU4IiwibmJmIjoxNjMyMjY4MDk5LCJleHAiOjE2MzIyNzE2OTksImlhdCI6MTYzMjI2ODA5OSwiaXNzIjoiaHR0cHM6Ly9taG0tZGV2LWRkLm15aGVhbHRobWF0aC5jb20iLCJhdWQiOiJodHRwczovL21obS1kZXYtZGQubXloZWFsdGhtYXRoLmNvbSJ9.tV_RiKFBJVuIZwJrqpZWcFh7-njJJKrVfmMp6wytaVo';
export const MOCK_SCHEDULE_ONCE_EMBED = `<div id="SOIDIV_MyHealthMathDemo" data-so-page="MyHealthMathDemo" data-height="550" data-style="border: 1px solid #d8d8d8; min-width: 290px; max-width: 900px;" data-psz="00"></div>`;
