import React from 'react';
import Navbar from 'components/Navbar';

const App = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="layout-container resetViewWhenLoading">
        <div className="layout-wrapper resetViewWhenLoading">{children}</div>
      </div>
    </>
  );
};

export default App;
