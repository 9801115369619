import { rest } from 'msw';
import { FORM_ID_EXPECTING_CHILD } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_RADIO } from 'constants/questionTypes';

// Form ID from the MHM Decision Doc Copy Document: 11
export const form11 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_EXPECTING_CHILD}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_EXPECTING_CHILD;
    const formTitle = 'Is anyone in your household expecting a child?';
    const formSubtitle = '';
    const formContent = '';
    const formCssId = '';
    const groupedQuestions = [
      [
        {
          id: '516c0d1a-e941-8326-8a4a-20cfb52c4b76',
          title: '',
          subtitle: '',
          titleLarge: null,
          type: INPUT_RADIO,
          multipleChoiceOptions: [
            {
              id: 'a843d1b9-32be-8b5c-a650-94c3a0a7a80f',
              title: 'Yes',
            },
            {
              id: '93c25f5b-2644-453a-9257-0404b65a439f',
              title: "Yes, and we'll be seeking Fertility Treatment",
            },
            {
              id: '93c25f5b-2644-453a-9257-040476da4e01',
              title: 'No',
            },
          ],
          dependency: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        "A pregnancy can have a big impact on your health care costs. Let me know if you'd like me to factor any of these services into my recommendation. Only select services that you think you'll use during your plan year.",
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formSubtitle,
        formContent,
        formCssId,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
