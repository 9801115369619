import React from 'react';

export default function AsyncComponentHOC(importComponent) {
  class AsyncComponent extends React.Component {
    isCancelled: boolean;

    constructor(props: {}) {
      super(props);

      this.state = {
        component: null,
      };
      this.isCancelled = false;
    }

    async componentDidMount() {
      const { default: component } = await importComponent();
      if (!this.isCancelled) {
        this.setState({
          component,
        });
      }
    }

    componentWillUnmount() {
      this.isCancelled = true;
    }

    render() {
      const Component: React.ComponentType | null = this.state.component;
      return Component ? <Component /> : null;
    }
  }

  return AsyncComponent;
}
