import { rest } from 'msw';
import { FORM_ID_PRESCRIPTIONS_SPECIALTY } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';
import { SECONDARY } from '../../constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 33 - Specialty Prescriptions
export const form33 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_PRESCRIPTIONS_SPECIALTY}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_PRESCRIPTIONS_SPECIALTY;
    const formTitle = 'Will anyone be getting specialty prescriptions?';
    const formSubtitle =
      'If so, select the combination of how often you fill the prescription(s) (every 30 or 90 days), and whether you fill the prescription(s) at the pharmacy or if receive them in the mail (retail or mail). Then select how many and for who.';
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: '6c4a8988-d9ec-42a7-9044-9f9d0b75111b',
          title: '30-Day Mail',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['f9c359bf-217f-4357-9f7a-b6a0d582ef63'],
          },
        },
        {
          id: 'bf857c19-6175-460c-b078-e75e09fc5ab4',
          titleLarge: 'Add how many specialty prescriptions per person',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '6c4a8988-d9ec-42a7-9044-9f9d0b75111b',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '6f1b8077-8389-464d-bc95-3e4e72729b2d',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '6c4a8988-d9ec-42a7-9044-9f9d0b75111b',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '43996171-7705-4cf3-94b8-6119f9e2babd',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '6c4a8988-d9ec-42a7-9044-9f9d0b75111b',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '87ce1664-29b8-4b44-9def-aef83fc9311b',
          title: '30-Day Retail',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['f9c359bf-217f-4357-9f7a-b6a0d582ef63'],
          },
        },
        {
          id: '81e5ed0b-571e-48af-a4b8-3de4852fe947',
          titleLarge: 'Add how many specialty prescriptions per person',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '87ce1664-29b8-4b44-9def-aef83fc9311b',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '9430686a-acf6-41b1-9649-a2085866bc5a',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '87ce1664-29b8-4b44-9def-aef83fc9311b',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '50f75e52-37cd-4de8-a62a-6c5b50bd609d',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '87ce1664-29b8-4b44-9def-aef83fc9311b',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '11ce709c-55a9-4ff1-932c-56732b980eac',
          title: '90-Day Mail',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['f9c359bf-217f-4357-9f7a-b6a0d582ef63'],
          },
        },
        {
          id: '1e3e3f9a-15db-4ac2-a683-008da388f1e3',
          titleLarge: 'Add how many specialty prescriptions per person',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '11ce709c-55a9-4ff1-932c-56732b980eac',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '0ae5b2f9-3ddb-4e2c-8063-942862d95bd2',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '11ce709c-55a9-4ff1-932c-56732b980eac',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '31495746-15e7-432c-aa35-ab19197605fc',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '11ce709c-55a9-4ff1-932c-56732b980eac',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'e1602c24-654d-436d-a80f-66fd6221b547',
          title: '90-Day Retail',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['f9c359bf-217f-4357-9f7a-b6a0d582ef63'],
          },
        },
        {
          id: '4debddf0-e4db-4f86-8abc-e28852292245',
          titleLarge: 'Add how many specialty prescriptions per person',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'e1602c24-654d-436d-a80f-66fd6221b547',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a8215d42-04ae-4098-afd6-43cf615b9193',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'e1602c24-654d-436d-a80f-66fd6221b547',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '75924669-844c-44d9-95ab-3a5adcfbe55f',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'e1602c24-654d-436d-a80f-66fd6221b547',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'f9c359bf-217f-4357-9f7a-b6a0d582ef63',
          title: 'No Specialty Prescriptions',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: [
              '6c4a8988-d9ec-42a7-9044-9f9d0b75111b',
              '87ce1664-29b8-4b44-9def-aef83fc9311b',
              '11ce709c-55a9-4ff1-932c-56732b980eac',
              'e1602c24-654d-436d-a80f-66fd6221b547',
            ],
          },
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'Specialty prescriptions are the highest tier and highest cost drugs used to treat complicated chronic conditions. Take your best guess on how often you fill.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
