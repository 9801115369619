import { rest } from 'msw';
import { SHERPA_FORM_ID_INSURANCE_TYPES } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN } from 'constants/questionTypes';

export const sherpaForm210 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_INSURANCE_TYPES}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_INSURANCE_TYPES;
    const formTitle = `<h2>Out of the benefits your employer offers select the ones that you have <em>private</em> coverage for.</h2>`;
    const formSubtitle = `Add as many as you need.`;
    const formContent = ``;
    const formIsSherpa = true;
    const allowNull = true;
    const groupedQuestions = [
      [
        {
          id: '623e3498-e600-4822-a2a6-126487aa3465',
          title: 'Accident Insurance',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: null,
        },
      ],
      [
        {
          id: 'ae2f4aa1-4c3f-4abd-a899-7b49976bc31b',
          title: 'Accidental Death & Dismemberment Insurance',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: null,
        },
      ],
      [
        {
          id: 'a3f24997-dd76-4aa0-a070-179610fd58ec',
          title: 'Critical Illness Insurance',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: null,
        },
      ],
      [
        {
          id: '1f510bc3-5c60-43c7-9e48-a7265280ca47',
          title: 'Dental Insurance',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: null,
        },
      ],
      [
        {
          id: '615864a9-fca5-4164-b3bd-94e9ab8a99dd',
          title: 'Disability Insurance',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: null,
        },
      ],
      [
        {
          id: '7370aa07-fc4c-45eb-a291-3bebdb348120',
          title: 'Hospital Indemnithy Insurance',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: null,
        },
      ],
      [
        {
          id: '578b0ad9-31c3-46e8-b4ea-5487f3b1ba5a',
          title: 'Life Insurance',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: null,
        },
      ],
      [
        {
          id: '103ce5ca-a476-4aae-8de1-1094473c9a09',
          title: 'Vision Insurance',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'Stuff about this',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        allowNull,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
