import { rest } from 'msw';
import { SHERPA_FORM_ID_WHOS_ON_INSURANCE_PLAN } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN } from 'constants/questionTypes';

export const sherpaForm203 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_WHOS_ON_INSURANCE_PLAN}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_WHOS_ON_INSURANCE_PLAN;
    const formTitle = `Who will be on your insurance plan this year?`;
    const formSubtitle = ``;
    const formContent = ``;
    const formIsSherpa = true;
    const groupedQuestions = [
      [
        {
          id: 'e834123d-1192-4365-af53-0d4f6efd3713',
          title: 'Just Me',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableWhenIdContains: 'e834123d-1192-4365-af53-0d4f6efd3713',
          },
        },
      ],
      [
        {
          id: 'e834123d-1192-4365-af53-0d4f6efd3713a',
          title: 'David',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['e834123d-1192-4365-af53-0d4f6efd3713'],
          },
        },
      ],
      [
        {
          id: 'e834123d-1192-4365-af53-0d4f6efd3713b',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['e834123d-1192-4365-af53-0d4f6efd3713'],
          },
        },
      ],
      [
        {
          id: 'e834123d-1192-4365-af53-0d4f6efd3713c',
          title: 'Roland',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['e834123d-1192-4365-af53-0d4f6efd3713'],
          },
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'Your costs are impacted by how many people are going to need medical coverage. I’ll factor your answers into my analysis, but this will not tie you to an enrollment decision.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
