import { rest } from 'msw';
import { SHERPA_FORM_ID_LIVING_SITUATION } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_RADIO } from 'constants/questionTypes';

export const sherpaForm204 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_LIVING_SITUATION}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_LIVING_SITUATION;
    const formTitle = `What is your current living situation?`;
    const formSubtitle = ``;
    const formContent = ``;
    const formIsSherpa = true;
    const groupedQuestions = [
      [
        {
          id: '254d4b2a-ce70-469c-87ee-ce52f0452c5a',
          title: '',
          type: INPUT_RADIO,
          multipleChoiceOptions: [
            {
              id: '5e5e54a2-9fbe-4bab-85ef-f16235d37b88',
              title: 'Own With Mortgage',
            },
            {
              id: '03ef9f23-c898-4ba0-94d2-e30cf1d391d2',
              title: 'Own Outright',
            },
            {
              id: 'ba89c248-8603-430a-b768-47d0466018e9',
              title: 'Rent',
            },
            {
              id: '1d5a063f-29f0-4dc5-ba8a-93d7cab8c307',
              title: 'Rent Free',
            },
          ],
          dependency: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'This is why we ask this question...',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
