import { rest } from 'msw';

export const sherpa = rest.get(
  `/api/employers/landing-page/sherpa`,
  (req, res, ctx) => {
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        jobId: 1000358,
        isSherpaTF: true,
        auth0_SSO_Enabled: true,
      }),
    );
  },
);
