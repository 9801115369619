import { rest } from 'msw';
import { FORM_ID_SUBSTANCE_USE } from 'constants/mocks/formIds';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';
import { SECONDARY } from 'constants/dependencyStatuses';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';

// Form ID from the MHM Decision Doc Copy Document: 30
export const form30 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_SUBSTANCE_USE}`,
  (req, res, ctx) => {
    const formId = FORM_ID_SUBSTANCE_USE;
    const formTitle =
      'Could you see anyone in your household getting any of the following treatments for substance use and recovery?';
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: 'a1772156-eb49-4848-b5e8-a381f6537b4f',
          title: 'Inpatient Stay',
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '8ff33fa2-39e8-4103-9e31-c75fc1a12b2d',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a1772156-eb49-4848-b5e8-a381f6537b4f',
            answerId: null,
          },
          max: 80,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'af97aef7-1138-49e3-99f3-e4dbb71bce39',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a1772156-eb49-4848-b5e8-a381f6537b4f',
            answerId: null,
          },
          max: 80,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '0c1ab503-1f82-43bc-bd2c-5012cf7830c1',
          title: 'Dave',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a1772156-eb49-4848-b5e8-a381f6537b4f',
            answerId: null,
          },
          max: 80,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '5dfa22f0-d435-44b0-9ea6-a09674c441b9',
          title: 'Medication Management',
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '1b5489dd-b001-4bab-ba77-aefea0666f26',
          titleLarge: 'How many visits will each person need?',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '5dfa22f0-d435-44b0-9ea6-a09674c441b9',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '42d35cf4-df98-404f-9832-4dfd37f05a46',
          titleLarge: '',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '5dfa22f0-d435-44b0-9ea6-a09674c441b9',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '69d28650-7f3e-4cbc-85da-0944e2d54d9c',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '5dfa22f0-d435-44b0-9ea6-a09674c441b9',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'If you select Inpatient Stay I will factor in the average cost of a 2 week stay into my analysis.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
