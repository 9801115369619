import { rest } from 'msw';
import { FORM_ID_PROVIDERS } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_RADIO } from 'constants/questionTypes';

// Form ID from the MHM Decision Doc Copy Document: 2
export const form2 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_PROVIDERS}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_PROVIDERS;
    const formContent = '';
    const formTitle = `Would you consider switching to a lower cost physician or medical facility to reduce your <span class="dotted-underline--trigger"> out-of-pocket costs?</span>`;
    const formCssId = 'page-providers';
    const groupedQuestions = [
      [
        {
          id: '516c0d1a-e941-4775-8a4a-20cfb52c4b76',
          title: '',
          subtitle: '',
          titleLarge: null,
          type: INPUT_RADIO,
          multipleChoiceOptions: [
            {
              id: 'a401c0a5-66be-4e5c-a650-94c3a0a7a80f',
              title:
                'Yes, please factor in the lower cost providers and facilities.',
            },
            {
              id: '86c25f5b-2644-453a-9257-0404fcfbca9f',
              title:
                'No, I will stick with my providers and facilities regardless of cost.',
            },
          ],
          dependency: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'Some of your plans may offer lower costs for certain providers and facilities. Your answer here will not impact your enrollment or limit what doctors you can see.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formCssId,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
