import { rest } from 'msw';
import { FORM_ID_PRESCRIPTIONS_GENERIC } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';
import { SECONDARY } from '../../constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 31 - Generic Prescriptions
export const form31 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_PRESCRIPTIONS_GENERIC}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_PRESCRIPTIONS_GENERIC;
    const formTitle = `You mentioned some prescriptions.<br>Will anyone be getting generic prescriptions?`;
    const formSubtitle =
      'Select the combination of how often you fill the prescription(s) (every 30 or 90 days), and whether you fill the prescription(s) at the pharmacy or receive them in the mail (retail or mail). Then select how many and for whom.';
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: '0bb6d7ea-36b4-46d7-b0f4-7a5bf059bab8',
          title: '30-Day Mail',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['3107746b-3398-4e23-81a0-88da7cc6ec1e'],
          },
        },
        {
          id: 'ecdce6b2-cd81-4ac6-a52e-e75fb822aa88',
          titleLarge: 'Add how many generic prescriptions per person',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '0bb6d7ea-36b4-46d7-b0f4-7a5bf059bab8',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'f0d0334a-4094-4fcd-ad78-520e8be7f955',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '0bb6d7ea-36b4-46d7-b0f4-7a5bf059bab8',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '0700bf3e-4876-4337-8a3d-1dd6b9b79eff',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '0bb6d7ea-36b4-46d7-b0f4-7a5bf059bab8',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '30af9395-f309-49c1-84a1-23f0f4140140',
          title: '30-Day Retail',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['3107746b-3398-4e23-81a0-88da7cc6ec1e'],
          },
        },
        {
          id: '80f526f4-ff8b-4546-8d4c-7bc316986555',
          titleLarge: 'Add how many generic prescriptions per person',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '30af9395-f309-49c1-84a1-23f0f4140140',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '4bd095a2-45ee-48aa-a239-2b0c2152efab',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '30af9395-f309-49c1-84a1-23f0f4140140',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '8617d535-13c8-4c78-ae74-79f3a9e06ee7',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '30af9395-f309-49c1-84a1-23f0f4140140',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'bb72017c-ba09-4f64-814a-381bc83ce757',
          title: '90-Day Mail',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['3107746b-3398-4e23-81a0-88da7cc6ec1e'],
          },
        },
        {
          id: '1e734267-5469-459f-893f-3e6031be2923',
          titleLarge: 'Add how many generic prescriptions per person',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'bb72017c-ba09-4f64-814a-381bc83ce757',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '9750334a-4094-4fcd-ad78-520e8be7f955',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'bb72017c-ba09-4f64-814a-381bc83ce757',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '8640bf3e-4876-4337-8a3d-1dd6b9b79eff',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'bb72017c-ba09-4f64-814a-381bc83ce757',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '9c0cc819-8eff-4a2b-ba2f-1e938fb6f2b6',
          title: '90-Day Retail',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['3107746b-3398-4e23-81a0-88da7cc6ec1e'],
          },
        },
        {
          id: 'c7b3ffb1-6523-4aa6-98e2-6a5cdb7a3de5',
          titleLarge: 'Add how many generic prescriptions per person',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '9c0cc819-8eff-4a2b-ba2f-1e938fb6f2b6',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'ddf8a256-e85f-4ef9-b417-061365f24f5e',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '9c0cc819-8eff-4a2b-ba2f-1e938fb6f2b6',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '7767fdf1-2a12-42ba-804a-bcb00e64e376',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '9c0cc819-8eff-4a2b-ba2f-1e938fb6f2b6',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '3107746b-3398-4e23-81a0-88da7cc6ec1e',
          title: 'No Generic Prescriptions',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: [
              '0bb6d7ea-36b4-46d7-b0f4-7a5bf059bab8',
              '30af9395-f309-49c1-84a1-23f0f4140140',
              'bb72017c-ba09-4f64-814a-381bc83ce757',
              '9c0cc819-8eff-4a2b-ba2f-1e938fb6f2b6',
            ],
          },
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        "Generic prescriptions are the lowest tier, cheaper than brand or specialty drugs, and are more likely to be covered by insurance. Take your best guess on how often you fill. You'll have the option to select Brand and Specialty drugs in the next few pages!",
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
