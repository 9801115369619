import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import rootReducer from 'reducers';

const persistConfig = {
  key: 'root',
  whitelist: ['auth', 'user'],
  storage: storageSession,
};

const enhancer = compose(applyMiddleware(createDebounce(), thunk));

export default function configureStore(initialState) {
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(persistedReducer, initialState, enhancer);
  const persistor = persistStore(store);
  return { store, persistor };
}
