import React, { useState } from 'react';

const SidebarContext = React.createContext();

// @TODO: Descoped - Temporarily disables Sidebar.
export const SidebarProvider = ({ children }) => {
  const [sidebar, setSidebar] = useState({});
  // const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  // const toggleSidebar = () => setIsSidebarVisible(!isSidebarVisible);
  const toggleSidebar = () => null;

  return (
    <SidebarContext.Provider
      value={{ sidebar, setSidebar, toggleSidebar, isSidebarVisible: false }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => React.useContext(SidebarContext);
