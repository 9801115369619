import { rest } from 'msw';
import { FORM_ID_PRESCRIPTIONS_BRAND } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';
import { SECONDARY } from '../../constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 32 - Brand Prescriptions
export const form32 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_PRESCRIPTIONS_BRAND}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_PRESCRIPTIONS_BRAND;
    const formTitle = 'Will anyone be getting brand name prescriptions?';
    const formSubtitle =
      'If so, select the combination of how often you fill the prescription(s) (every 30 or 90 days), and whether you fill the prescription(s) at the pharmacy or receive them in the mail (retail or mail). Then select how many and for who.';
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: 'fc3e214e-b462-483f-9f38-df3f2ea0ad96',
          title: '30-Day Mail',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['963a2350-21d6-422d-aaf9-129aa1378f8f'],
          },
        },
        {
          id: 'd2b680f8-cd87-4ab3-99f3-190bcbf5d4e8',
          titleLarge: 'Add how many brand prescriptions per person',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'fc3e214e-b462-483f-9f38-df3f2ea0ad96',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '97bac623-954c-4870-b14d-91db2137d2da',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'fc3e214e-b462-483f-9f38-df3f2ea0ad96',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'f758e51e-f8b1-4a2e-bfe6-1f1d57047851',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'fc3e214e-b462-483f-9f38-df3f2ea0ad96',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'b04d9379-4f34-431d-a7c0-1a362068910f',
          title: '30-Day Retail',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['963a2350-21d6-422d-aaf9-129aa1378f8f'],
          },
        },
        {
          id: '3ec3002d-742b-431b-8bdd-2faa28645992',
          titleLarge: 'Add how many brand prescriptions per person',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'b04d9379-4f34-431d-a7c0-1a362068910f',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '1a86c1fb-de2d-4917-bb10-b3849924df23',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'b04d9379-4f34-431d-a7c0-1a362068910f',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '9fa0c767-451c-4bc6-9d65-f48979e78a86',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'b04d9379-4f34-431d-a7c0-1a362068910f',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '032dbd24-8abe-4e4c-80cc-92bc9bba1326',
          title: '90-Day Mail',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['963a2350-21d6-422d-aaf9-129aa1378f8f'],
          },
        },
        {
          id: '7c178afc-34dc-49b6-a67c-1913e5ea1673',
          titleLarge: 'Add how many brand prescriptions per person',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '032dbd24-8abe-4e4c-80cc-92bc9bba1326',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '5234789e-f37a-4167-95f0-ac3618e3d55e',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '032dbd24-8abe-4e4c-80cc-92bc9bba1326',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '0fecca37-fb31-4954-8f9b-b01ca7a1a108',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '032dbd24-8abe-4e4c-80cc-92bc9bba1326',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'cf5a0cb9-f65d-4c52-9f37-2c976e54a2b6',
          title: '90-Day Retail',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['963a2350-21d6-422d-aaf9-129aa1378f8f'],
          },
        },
        {
          id: '14a4d202-a763-4650-a85d-4f6a578f81c2',
          titleLarge: 'Add how many brand prescriptions per person',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'cf5a0cb9-f65d-4c52-9f37-2c976e54a2b6',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '1182a3c0-86fc-425a-8d9c-baa6ff784f55',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'cf5a0cb9-f65d-4c52-9f37-2c976e54a2b6',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '073d464c-58b9-4752-8b28-b1704f2c98a7',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'cf5a0cb9-f65d-4c52-9f37-2c976e54a2b6',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '963a2350-21d6-422d-aaf9-129aa1378f8f',
          title: 'No Brand Prescriptions',
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: [
              'fc3e214e-b462-483f-9f38-df3f2ea0ad96',
              'b04d9379-4f34-431d-a7c0-1a362068910f',
              '032dbd24-8abe-4e4c-80cc-92bc9bba1326',
              'cf5a0cb9-f65d-4c52-9f37-2c976e54a2b6',
            ],
          },
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        "Brand name prescriptions have specific names, like ones you would hear on commercials, and they are more expensive than generic prescriptions. Take your best guess on how often you fill. You'll have the option to select Specialty drugs on the next page!",
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
