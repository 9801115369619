import { rest } from 'msw';
import { FORM_ID_HOUSEHOLD_AGES } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_NUMBER } from 'constants/questionTypes';

// Form ID from the MHM Decision Doc Copy Document: 4
export const form4 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_HOUSEHOLD_AGES}/`,
  (req, res, ctx) => {
    const formId = FORM_ID_HOUSEHOLD_AGES;
    const formTitle = 'What are the ages of the people in your household?';
    const formContent = '';
    const formCssId = '';
    const groupedQuestions = [
      [
        {
          id: '75a10776-82dc-4133-a022-be0591f3d3ef',
          title: "David's Age",
          subtitle: null,
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          multipleChoiceOptions: null,
          dependency: null,
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'f138b32e-f124-4c4e-a88e-e3909272463c',
          title: "Mira's Age",
          subtitle: null,
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          multipleChoiceOptions: null,
          dependency: null,
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'Knowing your age will help me ask the right questions about your medical needs.',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formCssId,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
