import { PENDING, FULFILLED, ERROR } from 'constants/apiStatus';
import clearUser from 'helpers/clearUser';
import clearJob from 'helpers/clearJob';

const base = process.env.REACT_APP_API_URL;
const shouldMock = process.env.REACT_APP_SHOULD_MOCK === 'true';
const defaultContentType = 'application/json';

const api = (method) => async (payload) => {
  const { path, body, cType, header, authToken, apiStatus } = payload;
  const baseUrl = shouldMock ? '' : base;
  const url = `${baseUrl}/api/${path}`;
  const contentType = cType || defaultContentType;

  const headers = {
    ...header,
    'Content-Type': `${contentType}`,
    Authorization: authToken ? `Bearer ${authToken}` : '',
  };

  const callStatusManager = (status) => {
    if (!apiStatus) return;

    switch (status) {
      case PENDING:
        apiStatus.set.pending();
        break;
      case ERROR:
        apiStatus.set.error('Unable to reach API');
      case FULFILLED:
        apiStatus.set.fulfilled();
    }
  };

  try {
    callStatusManager(PENDING);
    const response = await fetch(url, {
      method,
      headers,
      body,
    });
    const responseBody = await response.json().catch((err) => {
      if (process.env.LOGGER_ON) {
        console.error(`Response Body is likely empty.`, err);
      }
      return null;
    });

    const { status } = response;
    if (status === 401) {
      clearUser();
      clearJob();
      callStatusManager(ERROR);
      apiStatus.set.error();
      throw 'Not Authorized';
    } else if (status >= 400 && status <= 499) {
      return { ...responseBody, error: 'Unknown error' };
    } else if (status !== 200 && status !== 201) {
      callStatusManager(ERROR);
      apiStatus.set.error();
      throw 'Error fetching API';
    }
    callStatusManager(FULFILLED);

    return responseBody ? responseBody : { success: true };
  } catch (error) {
    throw Error(error);
  }
};

export default {
  get: api('get'),
  list: api('GET'),
  post: api('POST'),
  patch: api('PATCH'),
  put: api('PUT'),
  del: api('DELETE'),
};
