import { createLogger } from 'redux-logger';

const logger = createLogger({
  // State transformer
  // transforms Proxies from reducers
  // to the plain JS objects
  stateTransformer: (state) => {
    const newState = {};

    Object.keys(state).forEach((key) => {
      const stateItem = state[key];

      if (stateItem._logger) {
        newState[key] = stateItem._logger;
      } else {
        newState[key] = stateItem;
      }
    });

    return newState;
  },
});

export default logger;
