import { rest } from 'msw';
import { SHERPA_FORM_ID_MORTGAGE_PAYMENTS } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_NUMBER } from 'constants/questionTypes';

export const sherpaForm208 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_MORTGAGE_PAYMENTS}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_MORTGAGE_PAYMENTS;
    const formTitle = `Tell us more about your home payments...`;
    const formSubtitle = `If you’re not sure, give us your best guess!`;
    const formContent = ``;
    const formIsSherpa = true;
    const groupedQuestions = [
      [
        {
          id: '58d12f87-ec6e-49b0-9ce1-4d84bcd27d9b',
          title: 'How much are your mortgage payments?',
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          multipleChoiceOptions: null,
          dependency: null,
          max: 50000,
          min: 100,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '$',
          suffix: 'per month',
        },
      ],
      [
        {
          id: '7ea2c8e9-80a8-4506-9930-2c2a45385e96',
          titleLarge: '',
          title: 'How much is outstanding on your mortgage?',
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          multipleChoiceOptions: null,
          dependency: null,
          max: 2000000,
          min: 1000,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '$',
          suffix: '',
        },
      ],
      [
        {
          id: '27db2fcb-e733-4709-a16c-7095affb54bd',
          title: 'How long do you have left on your mortgage?',
          type: INPUT_NUMBER,
          showInputNumberButtons: false,
          multipleChoiceOptions: null,
          max: 50,
          min: 1,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '',
          suffix: 'years',
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'This is why we ask this question...',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
