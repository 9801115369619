export const FORM_TYPE_QUESTION = 0;
export const FORM_TYPE_HOUSEHOLD = 1;
export const FORM_TYPE_HOUSEHOLD_AGES = 2;
export const FORM_TYPE_PROGRESS = 3;
export const FORM_TYPE_LEADERBOARD = 4;
export const FORM_TYPE_HSA = 5;
export const FORM_TYPE_COVERAGE_CONFIRMATION = 6;
export const FORM_TYPE_HSA_LEARN_MORE = 7;
export const FORM_TYPE_FSA_LEARN_MORE = 8;
export const FORM_TYPE_DENTAL_HOUSEHOLD = 9;
export const FORM_TYPE_VISION_HOUSEHOLD = 10;
export const FORM_TYPE_SHERPA_HOUSEHOLD = 11;
export const FORM_TYPE_SHERPA_MEDICAL_HOUSEHOLD = 12;
export const FORM_TYPE_RX_LEARN_MORE = 13;
export const FORM_TYPE_RESULTS = 999;
