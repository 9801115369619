import { rest } from 'msw';
import { FORM_ID_DEPENDENTS_DENTAL_PARTNER } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';
import { SECONDARY } from '../../constants/dependencyStatuses';

export const formDependentsDentalWithPartner = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_DEPENDENTS_DENTAL_PARTNER}`,
  (req, res, ctx) => {
    const formId = FORM_ID_DEPENDENTS_DENTAL_PARTNER;
    const formTitle = `Will the same people on your health insurance be on your dental insurance as well?`;
    const formSubtitle = null;
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: '5f947071-701a-408c-b17f-b45140f78c68',
          title: 'My Partner/Spouse',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: ['true'],
          img: null,
          dependency: {
            disableOnQuestionIds: ['263c51f4-8cfb-483e-965e-18619b731d96'],
          },
        },
      ],
      [
        {
          id: '6832a09e-00e4-4b31-baae-6431e7ed3a78',
          title: 'My Child(ren)',
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: ['263c51f4-8cfb-483e-965e-18619b731d96'],
          },
        },
        {
          id: 'b809d746-c9c7-4af4-bcc3-c371e06fd354',
          title: 'Add Children',
          subtitle: 'How many children do you have?',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          answerLabelData: {
            singular: 'child',
            plural: 'children',
          },
          dependency: {
            status: SECONDARY,
            questionId: '6832a09e-00e4-4b31-baae-6431e7ed3a78',
            answerId: null,
          },
          max: 26,
          min: 1,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '263c51f4-8cfb-483e-965e-18619b731d96',
          title: `Just Me`,
          sidebar: {},
          subtitle: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
          dependency: {
            disableOnQuestionIds: [
              '5f947071-701a-408c-b17f-b45140f78c68',
              '6832a09e-00e4-4b31-baae-6431e7ed3a78',
            ],
          },
        },
      ],
    ];
    const theoCallout = {
      summaryText: `Here is some text about why I'm asking this again here. And telling them that they have the option to have dependents on dental even if they didn't on their health care.`,
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
