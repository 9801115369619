import React, { useEffect, useState } from 'react';
import sessionStorage from 'redux-persist/es/storage/session';
import { Link, withRouter } from 'react-router-dom';
import {
  Navbar as BSNavbar,
  Nav,
  NavDropdown,
  Container,
} from 'react-bootstrap';
import routes from 'constants/routes';
import { SUPPORT_EMAIL } from 'constants/config';
import useScheduleCall from 'hooks/useScheduleCall';
import ScheduleOnceModal from 'components/ScheduleOnceModal';
import EmailWarningModal from 'components/EmailWarningModal';
import {
  STORAGE_IS_SHERPA,
  STORAGE_SCHEDULE_CODE,
  STORAGE_SLUG,
  STORAGE_JOB_ID,
  STORAGE_AUTH0_USER,
  STORAGE_WHITELABEL_CSS_LOCATION
} from 'constants/sessionStorage';
import { useAuth0 } from '@auth0/auth0-react';
import eventBus from 'helpers/eventBus';
import localStorage from 'redux-persist/es/storage';
import { EVENT_AUTH0_STORED, EVENT_STORAGE } from 'constants/events';


const Navbar = ({ history }) => {
  const [scheduleCallEmbedCode, setScheduleCallEmbedCode] = useState('');
  const [open, closeModal, setOpen] = useScheduleCall();
  const [emailWarningOpen, closeEmailWarningModal, setEmailWarningOpen] = useScheduleCall();
  const [storedUser, setStoredUser] = useState(null);
  const [slug, setSlug] = useState('');
  const [logoButtonClasses, setLogoButtonClasses] = useState('');
  const [jobId, setJobId] = useState('');
  const [auth0Updated, setAuth0Updated] = useState(false);
  const [hasCustomPng, setHasCustomPng] = useState(false);
  const [hasCustomSvg, setHasCustomSvg] = useState(false);

 

  useEffect(async () => {
    // attach whitelabel stylesheet if there's one saved in local storage
    let savedStylesheetUrl = await localStorage.getItem(STORAGE_WHITELABEL_CSS_LOCATION);

    if (savedStylesheetUrl) {
      var head = document.head;
      var link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = savedStylesheetUrl;
      head.appendChild(link);
    }

    await updateScheduleCallEmbedCode();
    await updateSlug();
    updateLogos();

    const user = await localStorage.getItem(STORAGE_AUTH0_USER);
    if (user) {
      setStoredUser(JSON.parse(user));
    }
    eventBus.on(EVENT_STORAGE, async () => {
      updateLogos();
      updateSlug();
      updateScheduleCallEmbedCode();
    });

    eventBus.on(EVENT_AUTH0_STORED, async () => {
      setAuth0Updated(true);
    });

    return () => {
      eventBus.remove(EVENT_STORAGE);
      eventBus.remove(EVENT_AUTH0_STORED);
    };
  }, [auth0Updated]);

  // const isAuthenticated = user && user.auth && user.auth.authenticated;
  const handleGlossaryLinkClick = () => {
    if (!history.location.pathname.includes('glossary')) {
      const url = `${window.location.origin}${routes.GLOSSARY_PAGE}`;
      return window.open(url, '_blank').focus();
    }
    return null;
  };

  const updateLogos = async () => {
    const sherpaTF = await sessionStorage.getItem(STORAGE_IS_SHERPA);
    if (sherpaTF && sherpaTF != 'null' && sherpaTF != 'undefined') {
      const isSherpaEnabled = JSON.parse(sherpaTF);
      const logoClasses = "navbar-home-button navbar-sherpa-logo";
      setLogoButtonClasses(logoClasses);
    }

    const jobIdFromStorage = JSON.parse(await sessionStorage.getItem(STORAGE_JOB_ID));
    if (jobIdFromStorage && jobIdFromStorage !== null && jobIdFromStorage !== undefined) {
      setJobId(jobIdFromStorage);

      if (!hasCustomPng && !hasCustomSvg) {
        try {
          let customLogoUrl = "https://mhmdevdd.blob.core.windows.net/logos/" + jobIdFromStorage;

          // check for png first
          let customPngUrl = customLogoUrl + ".png";
          let customPngResult = await fetch(customPngUrl, { method: "HEAD" });
    
          if (customPngResult.ok) {
            console.log("Custom png logo found for job id " + jobIdFromStorage);
            setHasCustomPng(true);
          } else {
            // if no png, check for an svg
            let customSvgUrl = customLogoUrl + ".svg";
            let customSvgResult = await fetch(customSvgUrl, { method: "HEAD" });
      
            if (customSvgResult.ok) {
              console.log("Custom svg logo found for job id " + jobIdFromStorage);
              setHasCustomSvg(true);
            } else {
              // if no svg, we're done
              console.log("No custom logo found for job id " + jobIdFromStorage + ". This isn't a problem unless you were expecting one.");
            }
          }
        }
        catch {}
      }
    }
  };

  const updateSlug = async () => {
    const sessionSlug = await sessionStorage.getItem(STORAGE_SLUG);
    setSlug(sessionSlug);
  };

  const updateScheduleCallEmbedCode = async () => {
    const savedScheduleOnceCode = await sessionStorage.getItem(
      STORAGE_SCHEDULE_CODE,
    );

    setScheduleCallEmbedCode(savedScheduleOnceCode);
  };

  const clearUser = () => {
    history.push(routes.LOGOUT);
  };

  const renderCustomLogo = () => {
    if (!jobId || (!hasCustomPng && !hasCustomSvg)) {
      return null;
    }

    let customLogoUrl = "https://mhmdevdd.blob.core.windows.net/logos/" + jobId;

    if (hasCustomPng) {
      customLogoUrl += ".png";
    } else if (hasCustomSvg) {
      customLogoUrl += ".svg";

    }
    
    return (
      <div className="navbar-home-button navbar-custom-logo" style={{'backgroundImage': `url("${customLogoUrl}")`}}></div>
    );
  };

  const getHelp = (e) => {
    setEmailWarningOpen((o) => !o);
    window.location.href = `mailto:${SUPPORT_EMAIL}`;
    e.preventDefault();
  };

  const openFaqs = (e) => {
    window.open(`https://myhealthmath.com/employee-faqs/`);
    e.preventDefault();
  };

  return (
    <>
      <BSNavbar collapseOnSelect expand="lg" variant="light" fixed="top">
        <Container fluid className="nav-container">
          <Link
            className={logoButtonClasses}
            to={routes.LANDING_PAGE + '/' + slug}
          ></Link>
          {renderCustomLogo()}
          <BSNavbar.Toggle aria-controls="responsive-navbar-nav" />
          <BSNavbar.Collapse id="responsive-navbar-nav">
            <Nav className="navbar-right navbarScroll justify-content-end">
              <Nav.Link
                as="div"
                className="glossary-link nav-link me-0 me-lg-3"
                onClick={handleGlossaryLinkClick}
              >
                <span>Glossary</span>
              </Nav.Link>
              <NavDropdown
                title="Help Center"
                id="collasible-nav-dropdown"
                className={`help-center`}
              >
                <NavDropdown.Item
                   onClick={(e) => {
                    openFaqs(e);
                   }}
                >
                  Employee FAQs
                </NavDropdown.Item>
                <NavDropdown.Item
                  // href={`mailto:${SUPPORT_EMAIL}`}
                  onClick={(e) => {
                    getHelp(e);
                  }}
                >
                  Email Us
                </NavDropdown.Item>
                <NavDropdown.Item
                  as="div"
                  onClick={() => {
                    setOpen((o) => !o);
                  }}
                  className="schedule-call"
                >
                  Schedule a call
                </NavDropdown.Item>

              </NavDropdown>
              {storedUser ? (
                <NavDropdown
                  title={storedUser?.name}
                  id="collasible-nav-dropdown"
                  className={'help-center'}
                >
                  <Nav.Link
                    as="div"
                    className="schedule-call bold-text"
                    onClick={() => {
                      clearUser();
                    }}
                  >
                    Logout
                  </Nav.Link>
                </NavDropdown>
              ) : null}
              {process.env.REACT_APP_SHOULD_MOCK === 'true' ? (
                <Nav.Link href={routes.FORM_LINKS}>Form Links</Nav.Link>
              ) : null}
            </Nav>
          </BSNavbar.Collapse>
        </Container>
      </BSNavbar>
      {open ? (
        <ScheduleOnceModal
          closeModal={closeModal}
          embed={scheduleCallEmbedCode}
        />
      ) : null}
      {emailWarningOpen ? (
        <EmailWarningModal
          closeModal={closeEmailWarningModal}
        />
      ) : null}
    </>
  );
};

export default withRouter(Navbar);
