import { rest } from 'msw';

import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';

export const results = rest.get(
  `/api/results/${MOCK_QUESTIONNAIRE_ID}`,
  async (req, res, ctx) => {
    const questionnaireId = MOCK_QUESTIONNAIRE_ID;

    const planBreakdownData = {
      employeeAnnualPremium: 4050,
      estimatedOutOfPocketCost: 1658,
      outOfPocketLimit: 1658,
      totalOutOfPocketCost: 1658,
      totalCostAmount: 5708,
      employeeMonthlyPremium: 338,
      yearlyDeductible: 5400,
      totalWorstCase: 7380,
    };

    const recommendedPlanData = {
      planName: 'HSA 2700',
      productName: '',
      familyMemberNames: ['You', 'Mira', 'David', 'John'],
      keyFactors: [
        'Head and neck surgery',
        'Imaging & labs',
        'Specialist',
        'Prescription',
        'Head and neck surgery',
        'Imaging & labs',
        'Specialist',
        'Prescription',
        'Head and neck surgery',
        'Imaging & labs',
        'Specialist',
        'Prescription',
      ],
      planStart: '11/15/2023',
      planBreakdownData: planBreakdownData,
      recommendedPlanCallouts: [
        {
          title: "HSA Preventive Drug Benefit",
          body: `<p>One of your high deductible health plans has certain preventive prescriptions that the deductible will be waived for. Look at the Plan Details table below to find out which plan(s). We don't factor this into your DD prescription answers, so if this applies to your specific prescriptions your out-of-pocket costs could be lower!</p>
          Based on your assumptions:
          <ul>
            <li>Amount invested ($2,850) the difference between your 2023 HSA contribution limit ($3,850) and your employer's HSA contribution under the PPO HSA ($1000)</li>
            <li>Interest rate = 6%</li>
          </ul>`
        },
        {
          title: "HSA Preventive Drug Benefit",
          body: `<p>One of your high deductible health plans has certain preventive prescriptions that the deductible will be waived for. Look at the Plan Details table below to find out which plan(s). We don't factor this into your DD prescription answers, so if this applies to your specific prescriptions your out-of-pocket costs could be lower!</p>
          Based on your assumptions:
          <ul>
            <li>Amount invested ($2,850) the difference between your 2023 HSA contribution limit ($3,850) and your employer's HSA contribution under the PPO HSA ($1000)</li>
            <li>Interest rate = 6%</li>
          </ul>`
        },
        {
          title: "HSA Preventive Drug Benefit",
          body: `<p>One of your high deductible health plans has certain preventive prescriptions that the deductible will be waived for. Look at the Plan Details table below to find out which plan(s). We don't factor this into your DD prescription answers, so if this applies to your specific prescriptions your out-of-pocket costs could be lower!</p>
          Based on your assumptions:
          <ul>
            <li>Amount invested ($2,850) the difference between your 2023 HSA contribution limit ($3,850) and your employer's HSA contribution under the PPO HSA ($1000)</li>
            <li>Interest rate = 6%</li>
          </ul>`
        }
      ],
    };

    const planDetailsCallouts = [
      {
        title: "HSA Preventive Drug Benefit",
        body: "<p>One of your high deductible health plans has certain preventive prescriptions that the deductible will be waived for. Look at the Plan Details table below to find out which plan(s). We don't factor this into your DD prescription answers, so if this applies to your specific prescriptions your out-of-pocket costs could be lower!</P",
      },
      {
        title: "HSA Preventive Drug Benefit",
        body: "<p>One of your high deductible health plans has certain preventive prescriptions that the deductible will be waived for. Look at the Plan Details table below to find out which plan(s). We don't factor this into your DD prescription answers, so if this applies to your specific prescriptions your out-of-pocket costs could be lower!</P",
      },
      {
        title: "HSA Preventive Drug Benefit",
        body: "<p>One of your high deductible health plans has certain preventive prescriptions that the deductible will be waived for. Look at the Plan Details table below to find out which plan(s). We don't factor this into your DD prescription answers, so if this applies to your specific prescriptions your out-of-pocket costs could be lower!</P",
      },
    ]

    const planComparisonData = {
      "totalSavings": [
        {
          "name": "HMO HSA 2800",
          "amount": 1851
        },
        {
          "name": "Min-Match-Defined",
          "amount": 1135
        },
        {
          "name": "MinContribution",
          "amount": 0
        }
      ],
      "totalCosts": [
        {
          "name": "HMO HSA 2800",
          "amount": -217
        },
        {
          "name": "Min-Match-Defined",
          "amount": 498
        },
        {
          "name": "MinContribution",
          "amount": 1634
        }
      ],
      "worstCaseScenarios": [
        {
          "name": "HMO HSA 2800",
          "amount": 2783
        },
        {
          "name": "Min-Match-Defined",
          "amount": 2998
        },
        {
          "name": "MinContribution",
          "amount": 5134
        }
      ],
      "totalHealthCost": [
        {
          "name": "HMO HSA 2800",
          "total": -217,
          "premiums": 733,
          "oopExpenses": -950,
          "worstCaseAmount": 2783,
          "estMedicalCost": 0,
          "hsaFsaHra": 950,
          "worstCaseMedicalCost": 3000,
          "worstCaseHsaFsaHra": 950
        },
        {
          "name": "Min-Match-Defined",
          "total": 499,
          "premiums": 1229,
          "oopExpenses": 230,
          "worstCaseAmount": 2998,
          "estMedicalCost": 0,
          "hsaFsaHra": 730,
          "worstCaseMedicalCost": 3000,
          "worstCaseHsaFsaHra": 1230
        },
        {
          "name": "MinContribution",
          "total": 1634,
          "premiums": 2534,
          "oopExpenses": -900,
          "worstCaseAmount": 5134,
          "estMedicalCost": 0,
          "hsaFsaHra": 900,
          "worstCaseMedicalCost": 3500,
          "worstCaseHsaFsaHra": 900
        }
      ],
      "totalHsaHraFsaBenefits": [
        {
          "name": "HMO HSA 2800",
          "total": 0,
          "employerHsaContribution": 0,
          "estimatedHsaTaxSavings": 0,
          "employerFsaContribution": 0,
          "estimatedFsaTaxSavings": 0,
          "employerHraReimbursement": 0,
          "employeeHSAContribution": 0,
          "hsaTF": true,
          "fsaTF": false,
          "hraTF": false
        },
        {
          "name": "Min-Match-Defined",
          "total": 0,
          "employerHsaContribution": 0,
          "estimatedHsaTaxSavings": 0,
          "employerFsaContribution": 0,
          "estimatedFsaTaxSavings": 0,
          "employerHraReimbursement": 0,
          "employeeHSAContribution": 0,
          "hsaTF": false,
          "fsaTF": true,
          "hraTF": true
        },
        {
          "name": "MinContribution",
          "total": 0,
          "employerHsaContribution": 0,
          "estimatedHsaTaxSavings": 0,
          "employerFsaContribution": 0,
          "estimatedFsaTaxSavings": 0,
          "employerHraReimbursement": 0,
          "employeeHSAContribution": 0,
          "hsaTF": false,
          "fsaTF": true,
          "hraTF": false
        }
      ],
      "currentPlanName": "None"
    }
    const theoCallout = null;
    const expandableCards = [
      {
        title: 'Maximize Your Savings.',
        subtitle: `Under an HSA-Eligible plan you can save more by making tax-free contributions.`,
        body: `This breakdown shows you the numbers I think are most important
          Under an HSA-eligible plan, you can save even more by making
          tax-free contributions to an HSA to help pay for qualified
          medical expenses.
          <br />
          <br />
          If you enroll in HSA 2700 (the top-ranked HSA-eligible plan on
          your report), consider contributing at least $1,928 to an HSA
          for an additional $521* in tax savings!
          <br />
          <br />
          You can also generate tax savings if you contribute to a
          Flexible Spending Account (FSA) if your employer offers one.
          <a href='/'>Learn more here.</a>
          <br />
          <br />`,
        smallText: `* Based on a tax rate of 27%.`,
      },
      {
        title: 'Your Winning Plan Breakdown.',
        subtitle: 'Your winning plan has the lowest total health care cost and highest potential savings, based on the health information you shared with me.',
        body: 'Placeholder 2',
        smallText: '* Placeholder 2',
      },
      {
        title: 'Plan Details.',
        subtitle: `There's more to picking a plan than just looking at your total costs! Above are some details I think are important.`,
        body: 'Placeholder 3',
        smallText: '* Placeholder 3',
      },
      {
        title: 'Cost Overview.',
        subtitle: `Don't forget - your Total Cost for each plan is calculated by adding your annual premiums and net out-of-pocket costs together.`,
        body: 'Placeholder 4',
        smallText: '* Placeholder 4',
      },
      {
        title: 'Cost Details.',
        subtitle: 'Hey! Review these details if you want to know how I calculated your annual premiums and net out-of-pocket costs.',
        body: 'Placeholder 5',
        smallText: '* Placeholder 5',
      },
      {
        title: 'Read Our Assumptions.',
        subtitle:
          'We make certain assumptions about your costs and care to create this report. You can read more about them here.',
        body: `<p>Assumptions – This report is based on the utilization you provided to HYKE. We applied these costs to all of
          the plans that your employer is offering for the upcoming plan year. This comparison is based on the following key
          assumptions:</p>
          <ol>
            <li>All services in the upcoming plan year will be with in-network providers;</li>
            <li>All services will start and end in the same coverage period;</li>
            <li>Some of the insurance plans available to you for next
            year may have a limited number of visits for copays, after which coinsurance applies (which could increase your cost),
            and some plans may have Chronic Illness Support with a waiver of deductible and more favorable coinsurance
            (which could reduce your cost);</li>
            <li>Prescription drug tiers are determined by the drug formula provided by the employer.</li>
          </ol>`,
        smallText: `Disclaimer – This report includes general information and calculations regarding medical and pharmaceutical charges
          based on reported usage and is intended to provide assistance with decisions about health insurance plan choices
          for the upcoming plan year. Consider that your medical and pharmaceutical needs in the upcoming plan year could
          differ from your reported usage. This report is for informational purposes only and may not reflect all of the
          pricing factors and variables for all circumstances in the coming year. This report and any accompanying information or
          materials, written or verbal, are not intended, and must not be taken as professional advice or recommendations.
          Professional advice on specific insurance and benefits can be obtained by contacting a duly licensed professional.
          While the figures and analyses of plans to be offered herein are believed to be accurate, the medical and pharmaceutical
          costs in the upcoming year may vary dramatically from historical costs, so they are not warranted to be correct, and
          HYKE, Inc. disclaims any and all warranties of any type and shall have no liability for any inaccuracy or incompleteness.`,
      },
    ];
    const costOverviewItems = [
      {
        name: 'HSA 2700',
        annualPremium: 4050,
        totalOutOfPocketCost: 1658,
        totalCostAmount: 5708,
      },
      {
        name: 'HRA 5000',
        annualPremium: 2626,
        totalOutOfPocketCost: 4608,
        totalCostAmount: 7234,
      },
      {
        name: 'FSA 3500',
        annualPremium: 3051,
        totalOutOfPocketCost: 4508,
        totalCostAmount: 7559,
      },
    ];
    const premiumItems = [
      {
        name: 'HSA 2700',
        employerMonthlyPremium: 837,
        employeeMonthlyPremium: 338,
        totalMonthlyPremium: 1174,
        employerAnnualPremium: 10036,
        employeeAnnualPremium: 4050,
        totalAnnualPremium: 14085,
      },
      {
        name: 'HRA 5000',
        employerMonthlyPremium: 542,
        employeeMonthlyPremium: 218,
        totalMonthlyPremium: 761,
        employerAnnualPremium: 6507,
        employeeAnnualPremium: 2626,
        totalAnnualPremium: 9133,
      },
      {
        name: 'FSA 3500',
        employerMonthlyPremium: 630,
        employeeMonthlyPremium: 254,
        totalMonthlyPremium: 884,
        employerAnnualPremium: 7560,
        employeeAnnualPremium: 3051,
        totalAnnualPremium: 10611,
      },
    ];
    const hsaHraBenefitItems = [
      {
        name: 'HSA 2700',
        employerHsaContribution: 800,
        employerFsaContribution: 0,
        estimatedHsaTaxSavings: 1000,
        estimatedFsaTaxSavings: 0,
        employerHraReimbursement: 0,
        totalHsaHraFsaBenefits: 2070,
      },
      {
        name: 'HRA 5000',
        employerHsaContribution: 0,
        employerFsaContribution: 0,
        estimatedHsaTaxSavings: 0,
        estimatedFsaTaxSavings: 0,
        employerHraReimbursement: 15,
        totalHsaHraFsaBenefits: 2970,
      },
      {
        name: 'FSA 3500',
        employerHsaContribution: 0,
        employerFsaContribution: 20,
        estimatedHsaTaxSavings: 0,
        estimatedFsaTaxSavings: 1000,
        employerHraReimbursement: 0,
        totalHsaHraFsaBenefits: 2520,
      },
    ];
    const medicalCostItems = [
      {
        name: 'HSA 2700',
        yearlyDeductible: 3728,
        copaymentAmount: 0,
        coinsuranceRate: 0,
        excludedAmountNotCovered: 0,
        totalCostAmount: 3728,
      },
      {
        name: 'HRA 5000',
        yearlyDeductible: 6028,
        copaymentAmount: 0,
        coinsuranceRate: 1550,
        excludedAmountNotCovered: 0,
        totalCostAmount: 7578,
      },
      {
        name: 'FSA 3500',
        yearlyDeductible: 4528,
        copaymentAmount: 0,
        coinsuranceRate: 2500,
        excludedAmountNotCovered: 0,
        totalCostAmount: 7028,
      },
    ];
    const outOfPocketCostItems = [
      {
        name: 'HSA 2700',
        hsaBenefitsCost: 2070,
        medicalCost: 3728,
        totalOutOfPocketCost: 1658,
      },
      {
        name: 'HRA 5000',
        hsaBenefitsCost: 2970,
        medicalCost: 7578,
        totalOutOfPocketCost: 4608,
      },
      {
        name: 'FSA 3500',
        hsaBenefitsCost: 0,
        medicalCost: 7028,
        totalOutOfPocketCost: 4508,
      },
    ];
    const worstCaseScenarioItems = [
      {
        name: 'HSA 2700',
        maxPremium: 4050,
        maxOutOfPocket: 5400,
        employerHsaContribution: 1800,
        employerFsaContribution: 0,
        hsaTaxSavings: 270,
        fsaTaxSavings: 0,
        maxEmployerReimbursement: 0,
        excludedAmountNotCovered: 0,
        totalWorstCase: 7308,
      },
      {
        name: 'HRA 5000',
        maxPremium: 2626,
        maxOutOfPocket: 13100,
        employerHsaContribution: 0,
        employerFsaContribution: 0,
        hsaTaxSavings: 0,
        fsaTaxSavings: 0,
        maxEmployerReimbursement: 270,
        excludedAmountNotCovered: 0,
        totalWorstCase: 12756,
      },
      {
        name: 'FSA 3500',
        maxPremium: 3051,
        maxOutOfPocket: 12000,
        employerHsaContribution: 0,
        employerFsaContribution: 2250,
        hsaTaxSavings: 0,
        fsaTaxSavings: 270,
        maxEmployerReimbursement: 0,
        excludedAmountNotCovered: 0,
        totalWorstCase: 12531,
      },
    ];
    const planDetailsItems = [
      {
        name: 'HSA 2700',
        combinedIndividualDeductible: 40,
        combinedFamilyDeductible: 40,
        medicalIndividualDeductible: 40,
        medicalFamilyDeductible: 40,
        rxIndividualDeductible: 40,
        rxFamilyDeductible: 40,
        hasEmbeddedDeductible: true,
        combinedIndividualOutOfPocket: 40,
        combinedFamilyOutOfPocket: 40,
        medicalIndividualOutOfPocket: 40,
        medicalFamilyOutOfPocket: 40,
        rxIndividualOutOfPocket: 40,
        rxFamilyOutOfPocket: 40,
        hasEmbeddedOutOfPocketLimit: true,
        needsReferralFromPcp: false,
        coinsuranceRate: 12,
        employeeHsaContribution: 40,
        employeeFsaContribution: 0,
      },
      {
        name: 'HRA 5000',
        combinedIndividualDeductible: 0,
        combinedFamilyDeductible: 0,
        medicalIndividualDeductible: 0,
        medicalFamilyDeductible: 0,
        rxIndividualDeductible: 0,
        rxFamilyDeductible: 0,
        hasEmbeddedDeductible: true,
        combinedIndividualOutOfPocket: 0,
        combinedFamilyOutOfPocket: 0,
        medicalIndividualOutOfPocket: 0,
        medicalFamilyOutOfPocket: 0,
        rxIndividualOutOfPocket: 0,
        rxFamilyOutOfPocket: 0,
        hasEmbeddedOutOfPocketLimit: false,
        needsReferralFromPcp: false,
        coinsuranceRate: 10,
        employeeHsaContribution: 0,
        employeeFsaContribution: 0,
      },
      {
        name: 'FSA 3500',
        combinedIndividualDeductible: 0,
        combinedFamilyDeductible: 0,
        medicalIndividualDeductible: 10,
        medicalFamilyDeductible: 10,
        rxIndividualDeductible: 10,
        rxFamilyDeductible: 10,
        hasEmbeddedDeductible: true,
        combinedIndividualOutOfPocket: 10,
        combinedFamilyOutOfPocket: 10,
        medicalIndividualOutOfPocket: 10,
        medicalFamilyOutOfPocket: 10,
        rxIndividualOutOfPocket: 10,
        rxFamilyOutOfPocket: 10,
        hasEmbeddedOutOfPocketLimit: true,
        needsReferralFromPcp: false,
        coinsuranceRate: 10,
        employeeHsaContribution: 0,
        employeeFsaContribution: 40,
      },
    ];
    const resources = [
      {
        title: 'HSA Retirement 101',
        imgUrl: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Increasing-Line-Bar-Chart--Business-Analytics-Chart-Increasing-Green-Line-Bar-Graph-Up+1.svg`,
        url: 'https://google.com',
      },
      {
        title: 'How to Save in Health',
        imgUrl: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Piggy-Bank-1--Money-Finance-Saving-Piggy-Bank-Coin-Dollar-Cash-Shine+1.svg`,
        url: 'https://google.com',
      },
      {
        title: 'Worst-Case Scenario Planning',
        imgUrl: `${process.env.REACT_APP_AWS_BUCKET_URL}/decision-doc/Safe-Protection-2--Money-Finance-Management-Protection-Umbrella-Secure-Safe-Box-Money-Coin-Hand+1.svg`,
        url: 'https://google.com',
      },
    ];

    const scheduleCallEmbedCode = `<div id="SOIDIV_MyHealthMathDemo" data-so-page="MyHealthMathDemo" data-height="550" data-style="border: 1px solid #d8d8d8; max-width: 900px;" data-psz="00"></div>`;
    const enrollUrl = 'https://google.com';
    const isFamily = true;

    const dentalResults = {
      plans: [
        {
          name: 'Delta Bronze',
          isFamily: true,
          isTiered: true,
          monthlyPremium: 23,
          bestFitTags: [
            {
              html: '<p>Someone who only needs <strong>preventive dental care</strong> (<em>teeth cleanings, x-rays</em>)</p>',
            },
            {
              html: `<p>Someone who doesn't mind <strong>changing dentists</strong> if they need to (<em>this plan has a limited network</em>)</p>`,
            },
          ],
          theoCallout: 'Customizable by employer!',
          coverageDetails: {
            tier1: [
              {
                tierName: 'PPO NETWORK',
                type: 11, //Individual Deductible
                displayValue: '$1,000 then 100% coverage',
              },
              {
                tierName: 'PPO NETWORK',
                type: 12, //Family Deductible
                displayValue: '$4,000 then 100% coverage',
              },
              {
                tierName: 'PPO NETWORK',
                type: 1, //Preventive Care
                displayValue: 'Deductible then $20 copay/visit',
              },
              {
                tierName: 'PPO NETWORK',
                type: 2, //Basic Care
                displayValue: '90% coinsurance/service',
              },
              {
                tierName: 'PPO NETWORK',
                type: 3, //Major Procedures
                displayValue: 'Deductible Only',
              },
              {
                tierName: 'PPO NETWORK',
                type: 4, //Orthodontia
                displayValue: '$1,000 allowance/year',
              },
              {
                tierName: 'PPO NETWORK',
                type: 13, //First Year Annual Max
                displayValue: 'up to $1,500 per person',
              },
              {
                tierName: 'PPO NETWORK',
                type: 14, //Second Year Annual Max
                displayValue: 'N/A',
              },
            ],
            tier2: [
              {
                tierName: 'PREMIER NETWORK',
                type: 11, //Individual Deductible
                displayValue: '$500 then 100% coverage',
              },
              {
                tierName: 'PREMIER NETWORK',
                type: 1, //Preventive Care
                displayValue: 'Deductible then $15 copay/visit',
              },
              {
                tierName: 'PREMIER NETWORK',
                type: 2, //Basic Care
                displayValue: '80% coinsurance/service',
              },
              {
                tierName: 'PREMIER NETWORK',
                type: 3, //Major Procedures
                displayValue: 'Deductible Only',
              },
              {
                tierName: 'PREMIER NETWORK',
                type: 4, //Orthodontia
                displayValue: '$1,500 allowance/year',
              },
              {
                tierName: 'PREMIER NETWORK',
                type: 13, //First Year Annual Max
                displayValue: 'up to $1,000 per person',
              },
              {
                tierName: 'PREMIER NETWORK',
                type: 14, //Second Year Annual Max
                displayValue: 'up to $2,000 per person',
              },
            ],
          },
        },
        {
          name: 'DeltaCareUSA',
          isFamily: true,
          isTiered: false,
          monthlyPremium: 56,
          bestFitTags: [
            {
              html: '<p>Someone who needs <strong>preventive dental care</strong> (<em>teeth cleanings, x-rays</em>)</p>',
            },
            {
              html: '<p>Someone who needs <strong>orthodontia</strong> (<em>this plan contributes to braces and related services</em>)</p>',
            },
            {
              html: '<p>Someone who wants to use their plan <strong>right away</strong> (<em>this plan does not have a waiting period</em>)</p>',
            },
            {
              html: '<p>Someone who wants to <strong>keep their dentist</strong> (<em>this plan has multiple networks to expand coverage of dentists</em>)</p>',
            },
          ],
          theoCallout: 'Customizable by employer!',
          coverageDetails: {
            tier1: [
              {
                tierName: 'PRIMARY NETWORK',
                type: 11, //Individual Deductible
                displayValue: '$1,100 then 100% coverage',
              },
              {
                tierName: 'PRIMARY NETWORK',
                type: 12, //Family Deductible
                displayValue: '$4,200 then 100% coverage',
              },
              {
                tierName: 'PRIMARY NETWORK',
                type: 1, //Preventive Care
                displayValue: 'Deductible then $25 copay/visit',
              },
              {
                tierName: 'PRIMARY NETWORK',
                type: 2, //Basic Care
                displayValue: '80% coinsurance/service',
              },
              {
                tierName: 'PRIMARY NETWORK',
                type: 3, //Major Procedures
                displayValue: 'Deductible Only',
              },
              {
                tierName: 'PRIMARY NETWORK',
                type: 4, //Orthodontia
                displayValue: '$1,200 allowance/year',
              },
              {
                tierName: 'PRIMARY NETWORK',
                type: 13, //First Year Annual Max
                displayValue: 'up to $2,000 per person',
              },
              {
                tierName: 'PRIMARY NETWORK',
                type: 14, //Second Year Annual Max
                displayValue: 'N/A',
              },
            ],
            tier2: [],
          },
        },
      ],
    };

    const visionResults = {
      plans: [
        {
          name: 'EyeMed Healthy',
          isFamily: true,
          isTiered: true,
          monthlyPremium: 5,
          bestFitTags: [
            {
              html: '<p>Someone who <em>wants</em> to get <strong>prescription glasses</strong> in the near future</p>',
            },
            {
              html: '<p>Someone who <em>wants</em> to get a <strong>LASIK procedure</strong> to fix their eyesight in the coming</p>',
            },
            {
              html: '<p>Someone who <em>wants</em> to have their <strong>annual eye exam</strong></p>',
            },
          ],
          theoCallout: 'Customizable by employer!',
          coverageDetails: {
            tier1: [
              {
                tierName: 'IN-NETWORK',
                type: 11, //Individual Deductible
                displayValue: '$150 then 100% coverage',
              },
              {
                tierName: 'IN-NETWORK',
                type: 12, //Family Deductible
                displayValue: '$600 then 100% coverage',
              },
              {
                tierName: 'IN-NETWORK',
                type: 5, //Eye Exam
                displayValue: '$15 copay',
              },
              {
                tierName: 'IN-NETWORK',
                type: 7, //Prescription Lenses
                displayValue: '$25 copay',
              },
              {
                tierName: 'IN-NETWORK',
                type: 6, //Frames
                displayValue: '50% coinsurance',
              },
              {
                tierName: 'IN-NETWORK',
                type: 8, //Prescription Contact Lenses
                displayValue: '50% coinsurance',
              },
              {
                tierName: 'IN-NETWORK',
                type: 9, //LASIK Surgery
                displayValue: '$125 copay',
              },
            ],
            tier2: [
              {
                tierName: 'OUT-OF-NETWORK',
                type: 11, //Individual Deductible
                displayValue: '$300 then 100% coverage',
              },
              {
                tierName: 'OUT-OF-NETWORK',
                type: 5, //Eye Exam
                displayValue: '$30 copay',
              },
              {
                tierName: 'OUT-OF-NETWORK',
                type: 7, //Prescription Lenses
                displayValue: '$50 copay',
              },
              {
                tierName: 'OUT-OF-NETWORK',
                type: 6, //Frames
                displayValue: '40% coinsurance',
              },
              {
                tierName: 'OUT-OF-NETWORK',
                type: 8, //Prescription Contact Lenses
                displayValue: '40% coinsurance',
              },
              {
                tierName: 'OUT-OF-NETWORK',
                type: 9, //LASIK Surgery
                displayValue: '$250 copay',
              },
            ],
          },
        },
      ],
    };

    const medicalResults = {
      recommendedPlanData,
      planComparisonData,
      theoCallout,
      expandableCards,
      planBreakdownData,
      planDetailsCallouts,
      costOverviewItems,
      premiumItems,
      hsaHraBenefitItems,
      medicalCostItems,
      outOfPocketCostItems,
      worstCaseScenarioItems,
      planDetailsItems,
      resources,
      isFamily,
    };

    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        questionnaireId,
        scheduleCallEmbedCode,
        enrollUrl,
        medicalResults,
        dentalResults,
        visionResults,
      }),
    );
  },
);
