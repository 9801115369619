import { rest } from 'msw';
import { FORM_ID_MEDICAL_EQUIPMENT } from 'constants/mocks/formIds';
import { INPUT_BOOLEAN, INPUT_NUMBER } from 'constants/questionTypes';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { SECONDARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 27
export const form27 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_MEDICAL_EQUIPMENT}`,
  (req, res, ctx) => {
    const formId = FORM_ID_MEDICAL_EQUIPMENT;
    const formTitle =
      'You said you may need new medical equipment. Do any of these apply?';
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: '2f409ca6-2b68-4a86-aa2b-22dbbce9bd48',
          title: 'Hearing Aids',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '2ea839a4-c3af-4f33-a62e-1d29ef98c908',
          titleLarge: 'Who will need hearing aids?',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '2f409ca6-2b68-4a86-aa2b-22dbbce9bd48',
            answerId: null,
          },
          max: 2,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '41f0c914-2067-4f2e-ac29-bd808a0f445d',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '2f409ca6-2b68-4a86-aa2b-22dbbce9bd48',
            answerId: null,
          },
          max: 2,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '39b1edb6-d0f6-469c-b1e2-8f7a3879594c',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '2f409ca6-2b68-4a86-aa2b-22dbbce9bd48',
            answerId: null,
          },
          max: 2,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '374cdfb7-d069-4a12-8c8d-5bb1796af90c',
          title: 'CPAP',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'ec91befa-15b9-4e52-a1fe-577644243f2b',
          titleLarge: 'Who will need a new CPAP machine?',
          title: 'You',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '374cdfb7-d069-4a12-8c8d-5bb1796af90c',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '1a526a36-082e-45e2-acaf-271f830252da',
          title: 'Mira',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '374cdfb7-d069-4a12-8c8d-5bb1796af90c',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '37152cba-f3cd-4f5a-9dc8-70ca5ec2b83b',
          title: 'Dave',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '374cdfb7-d069-4a12-8c8d-5bb1796af90c',
            answerId: null,
          },
          max: 26,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        "Only select these if you think you'll need to purchase something new! If you select CPAP, I will include the costs of common supplies you might need for the year.",
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
