import React from 'react';
import { withRouter } from 'react-router';

import { routeTypes } from 'constants/routes';
import PublicRoute from 'components/RouteAuthentication/Public';
import UnauthenticatedOnlyRoute from 'components/RouteAuthentication/UnauthenticatedOnly';
import AuthenticatedOnlyRoute from 'components/RouteAuthentication/AuthenticatedOnly';
import RegisteredGuestRoute from 'components/RouteAuthentication/RegisteredGuest';

const TrackableRoute = ({
  component,
  location,
  exact,
  path,
  type,
  userData = {},
}) => {
  const { PUBLIC, UNAUTHENTICATED_ONLY, REGISTERED_GUEST } = routeTypes;

  if (type === PUBLIC) {
    return <PublicRoute path={path} exact={exact} component={component} />;
  } else if (type === REGISTERED_GUEST) {
    return (
      <RegisteredGuestRoute
        path={path}
        exact={exact}
        component={component}
        userData={userData}
      />
    );
  } else if (type === UNAUTHENTICATED_ONLY) {
    return (
      <UnauthenticatedOnlyRoute
        path={path}
        exact={exact}
        component={component}
        location={location}
        userData={userData}
      />
    );
  }

  return (
    <AuthenticatedOnlyRoute
      path={path}
      exact={exact}
      component={component}
      userData={userData}
    />
  );
};

const WrappedComponent = withRouter(TrackableRoute);
export default WrappedComponent;
