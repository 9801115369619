import React from 'react';
import { Route } from 'react-router-dom';
import { withRouter, Redirect } from 'react-router';

import { decode } from 'query-params';
import routes from 'constants/routes';

const UnauthenticatedOnlyRoute = ({
  location,
  component,
  exact,
  path,
  userData = {},
}) => {
  const querystring = (name) => {
    if (location && location.search) {
      const params = decode(location.search.replace(/\?/g, ''));
      return params[name];
    }
    return null;
  };

  const renderComponent = () => {
    const { auth } = userData;
    const authenticationData = auth ? auth : {};
    const { authenticated } = authenticationData;
    const redirect = querystring('redirect');

    const C = component;
    if (!authenticated) return <C />;

    return (
      <Redirect
        to={redirect && redirect !== '' ? redirect : null}
      />
    );
  };

  return <Route exact={exact} path={path} render={() => renderComponent()} />;
};

const WrappedComponent = withRouter(UnauthenticatedOnlyRoute);
export default WrappedComponent;
