import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Auth0Provider } from '@auth0/auth0-react';
import configureStore from 'store/configureStore';
import Routes from 'containers/Routes';
import 'reactjs-popup/dist/index.css';
import 'assets/styles/app.scss';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ReactGA from 'react-ga4';

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_SHOULD_MOCK,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
} = process.env;
const { store, persistor } = configureStore();
const TRACKING_ID = REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID; // GA Tracking ID

async function main() {
  if (REACT_APP_SHOULD_MOCK && JSON.parse(REACT_APP_SHOULD_MOCK)) {
    const { worker } = require('./mocks/browser');
    await worker.start({
      serviceWorker: {
        url: '/mockServiceWorker.js',
      },
    });
  }
  const bugsnagApiKey = process.env.REACT_APP_BUGSNAG_API_KEY;
  if (bugsnagApiKey && bugsnagApiKey.length > 0) {
    Bugsnag.start({
      apiKey: bugsnagApiKey,
      plugins: [new BugsnagPluginReact()],
    });
  }

  const onRedirectCallback = (appState) => {
    // window.location.assign(appState.returnTo);
    // history.push(
    //   appState && appState.returnTo
    //     ? appState.returnTo
    //     : window.location.pathname,
    // );
  };

  ReactGA.initialize(TRACKING_ID);

  const Auth0ProviderConfig = {
    domain: REACT_APP_AUTH0_DOMAIN,
    clientId: REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
    audience: REACT_APP_AUTH0_AUDIENCE,
    scope: 'openid profile email dateofbirth',
    cacheLocation: 'memory',
    onRedirectCallback,
  };

  const mainAppOutput = () => {
    return (
      <Auth0Provider {...Auth0ProviderConfig}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Routes />
          </PersistGate>
        </Provider>
      </Auth0Provider>
    );
  };

  const bugSnaggedOutput = () => {
    if (!bugsnagApiKey || !bugsnagApiKey.length) {
      return mainAppOutput();
    }
    const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
    return <ErrorBoundary>{mainAppOutput()}</ErrorBoundary>;
  };

  ReactDOM.render(bugSnaggedOutput(), document.getElementById('root'));
}
main();
