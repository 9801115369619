import { rest } from 'msw';
import { SHERPA_FORM_ID_LONG_TERM_DISABILITY } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_NUMBER } from 'constants/questionTypes';

export const sherpaForm219 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_LONG_TERM_DISABILITY}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_LONG_TERM_DISABILITY;
    const formHeader = 'LONG TERM DISABILITY';
    const formTitle =
      'What percent of income would your long term disability insurance pay out?';
    const formSubtitle = null;
    const formContent = null;
    const formIsSherpa = true;
    const groupedQuestions = [
      [
        {
          id: 'b840ee4e-ef65-4944-b9e5-af90db20f23f',
          title: 'Percent of Income',
          subtitle: '',
          titleLarge: null,
          type: INPUT_NUMBER,
          dependency: null,
          max: 100,
          min: 10,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '',
          suffix: '%',
        },
      ],
      [
        {
          id: '114cd423-a3d3-4515-ab97-3cba626cd439',
          title: 'Long Term Disability Wait Period',
          subtitle: '',
          titleLarge:
            'How long would you have to wait until your long term disability starts paying out?',
          type: INPUT_NUMBER,
          dependency: null,
          max: 24,
          min: 0,
          required: true,
          selectedAnswer: null,
          img: null,
          prefix: '',
          suffix: 'months',
          shownDefaultValue: 0,
          allowNullQuestions: true,
        },
      ],
    ];
    const theoCallout = {
      summaryText: 'This is why we ask this question...',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formHeader,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
