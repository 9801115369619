import { rest } from 'msw';
import { SHERPA_FORM_ID_HAS_DEPENDENTS } from 'constants/mocks/sherpaFormIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import {
  INPUT_BOOLEAN,
  INPUT_TEXT,
  INPUT_NUMBER,
} from 'constants/questionTypes';
import { SECONDARY, TERTIARY } from 'constants/dependencyStatuses';

export const sherpaForm201 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${SHERPA_FORM_ID_HAS_DEPENDENTS}`,
  (req, res, ctx) => {
    const formId = SHERPA_FORM_ID_HAS_DEPENDENTS;
    const formTitle = `Do you have anyone else in your household?`;
    const formSubtitle =
      'Include all, even if they will not be on your insurance plan this year.';
    const formContent = null;
    const formIsSherpa = true;
    const groupedQuestions = [
      [
        {
          id: '6ffb3b93-c044-4ee4-a57a-6939c0ec31c3',
          title: 'My partner/spouse',
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['e3e6972b-4e36-4402-ae61-2dbbc7509d53'],
          },
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          wipeValueWhenUnchecked: true,
          img: null,
        },
        {
          id: 'c5732d6f-d03e-426b-9659-4b0c3b822ba2',
          title: 'Name your partner/spouse',
          subtitle: "Partner/spouse's name",
          type: INPUT_TEXT,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '6ffb3b93-c044-4ee4-a57a-6939c0ec31c3',
            answerId: null,
            disableOnQuestionIds: ['e3e6972b-4e36-4402-ae61-2dbbc7509d53'],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '7836eb88-762a-4d55-9b89-2c204e3e9fbb',
          title: 'My child(ren)',
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['e3e6972b-4e36-4402-ae61-2dbbc7509d53'],
          },
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          wipeValueWhenUnchecked: true,
          img: null,
        },
        {
          id: '7c2fe1a1-37ef-4d03-afa6-6827ff8d5e69',
          title: 'Add Children',
          subtitle: 'How many children do you have?',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '7836eb88-762a-4d55-9b89-2c204e3e9fbb',
            answerId: null,
            disableOnQuestionIds: ['e3e6972b-4e36-4402-ae61-2dbbc7509d53'],
          },
          max: 10,
          min: 0,
          required: true,
          selectedAnswer: null,
          hideAnswer: true,
          img: null,
        },
        {
          id: 'ff5c02b0-c3b7-4be3-90c1-61fc8277c0c1',
          title: 'Name your children',
          subtitle: "Child's name",
          type: INPUT_TEXT,
          multipleChoiceOptions: null,
          dependency: {
            status: TERTIARY,
            questionId: '7836eb88-762a-4d55-9b89-2c204e3e9fbb',
            dynamicParentId: '7c2fe1a1-37ef-4d03-afa6-6827ff8d5e69',
            answerId: null,
            disableOnQuestionIds: ['e3e6972b-4e36-4402-ae61-2dbbc7509d53'],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'e3e6972b-4e36-4402-ae61-2dbbc7509d53',
          title: 'Just Me',
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            questionId: null,
            answerId: null,
            disableOnQuestionIds: [
              '6ffb3b93-c044-4ee4-a57a-6939c0ec31c3',
              '7836eb88-762a-4d55-9b89-2c204e3e9fbb',
            ],
          },
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        'Your costs are impacted by how many people are going to need medical coverage. I’ll factor your answers into my analysis, but this will not tie you to an enrollment decision.',
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        formSubtitle,
        formIsSherpa,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
