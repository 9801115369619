import { rest } from 'msw';
import { FORM_ID_SHOULDER_ARM_AND_HAND_SURGERY } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import { INPUT_BOOLEAN, INPUT_RADIO } from 'constants/questionTypes';
import { SECONDARY, TERTIARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 16
export const form16 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_SHOULDER_ARM_AND_HAND_SURGERY}`,
  (req, res, ctx) => {
    const formId = FORM_ID_SHOULDER_ARM_AND_HAND_SURGERY;
    const formTitle = 'What kind of shoulder, arm, or hand surgery?';
    const formSubtitle = 'Add as many as you need';
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: 'a299f905-72e1-4961-842c-322057abfdb5',
          title: 'Carpal Tunnel Surgery',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '97b36855-1d54-44cf-94f6-05e1841b541f',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a299f905-72e1-4961-842c-322057abfdb5',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '4d73486e-f8f7-4014-9e63-6967316f45d2',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a299f905-72e1-4961-842c-322057abfdb5',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '1b0c3efe-fe43-49da-928e-4053bf23b19e',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'a299f905-72e1-4961-842c-322057abfdb5',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '0e594747-97c7-4106-8080-ebb19b948ce2',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: 'a299f905-72e1-4961-842c-322057abfdb5',
          },
          multipleChoiceOptions: [
            {
              id: '3d204955-ba35-4fac-976c-af00f5746b21',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '1f0bddcc-ac8e-4e6c-a5ca-0e5f1718e2f3',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: '95319176-77b7-4161-a0b8-188683bd987d',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '8ad0cd43-40a0-44a6-8a49-efc2015f3ad5',
          title: 'Shoulder Arthroscopy with Labrum Repair',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '53e75635-3399-46b1-b965-f0a49b655654',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '8ad0cd43-40a0-44a6-8a49-efc2015f3ad5',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '47d749f2-2ca2-433c-b953-4f4b27501ef1',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '8ad0cd43-40a0-44a6-8a49-efc2015f3ad5',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '8b2ab903-da04-47dc-b007-23aa68c86352',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '8ad0cd43-40a0-44a6-8a49-efc2015f3ad5',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'e68f985d-fcca-45bf-8d9e-4071ee9040d5',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '8ad0cd43-40a0-44a6-8a49-efc2015f3ad5',
          },
          multipleChoiceOptions: [
            {
              id: 'f221b738-8573-4fac-b016-67d3f3af4911',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: '1d06be0a-ac88-4be4-9aad-a8488468d9a6',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: '0740facd-ada6-48c2-a742-2dec38887429',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '370e6a47-7f08-45cb-814c-5f2aa8f93c76',
          title: 'Shoulder Arthroscopy with Rotator',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '013fe388-42df-4941-9dec-d9cec2400d4f',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '370e6a47-7f08-45cb-814c-5f2aa8f93c76',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '315110a1-7a6e-4c8b-9678-458c138e9aaf',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '370e6a47-7f08-45cb-814c-5f2aa8f93c76',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'ab43eed4-9bb1-4c68-903e-849b57f230e4',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '370e6a47-7f08-45cb-814c-5f2aa8f93c76',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '068927fe-ae29-4320-80ab-e3bc8f70d1a4',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '370e6a47-7f08-45cb-814c-5f2aa8f93c76',
          },
          multipleChoiceOptions: [
            {
              id: '5c437443-a6f6-4478-b04f-c67c288a14d2',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: 'b8aa82a3-2fa3-4853-b96f-c5f2de718ee5',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: 'b198eeaf-666e-4a2f-ac34-ea3a2f127d26',
              title: "I don't know",
            },
          ],
        },
      ],
      [
        {
          id: '394353d9-ea4a-4996-84d5-8598384136b6',
          title: 'General Surgery',
          sidebar: {},
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: null,
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'c81d731b-8436-48f7-833d-f99437418206',
          subtitle: '',
          titleLarge: 'Who is it for?',
          title: 'You',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '394353d9-ea4a-4996-84d5-8598384136b6',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'c00b317e-de7c-4ca4-b495-ad09676ddc80',
          subtitle: '',
          title: 'Mira',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '394353d9-ea4a-4996-84d5-8598384136b6',
            answerId: null,
          },
          max: null,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '01208373-497d-4bf3-bc62-5028e1ce9ecc',
          title: 'Dave',
          subtitle: '',
          titleLarge: '',
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '394353d9-ea4a-4996-84d5-8598384136b6',
            answerId: null,
            disableOnQuestionIds: [],
          },
          max: 80,
          min: null,
          required: true,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'e1f4f03c-2df3-4469-af80-126df263c5f4',
          title: 'Will your surgery require an overnight stay?',
          type: INPUT_RADIO,
          dependency: {
            status: TERTIARY,
            questionId: '394353d9-ea4a-4996-84d5-8598384136b6',
          },
          multipleChoiceOptions: [
            {
              id: 'ed834cb8-284e-4177-8ae7-3a0ebad03a2f',
              title: 'Overnight Stay (inpatient)',
            },
            {
              id: 'af6e44f7-c8c5-4c71-88c1-57948851fe0e',
              title: 'No Overnight Stay (outpatient)',
            },
            {
              id: '0738c63a-179c-4f3b-8220-45584f71fe04',
              title: "I don't know",
            },
          ],
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        "If you don't see a surgery that you're planning or don't know the name, don't worry! Select General Surgery and I will factor an average cost surgery into my recommendation.",
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formSubtitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
