import { rest } from 'msw';
import { FORM_ID_HOUSEHOLD_DYNAMIC } from 'constants/mocks/formIds';
import { MOCK_QUESTIONNAIRE_ID } from 'constants/mocks/mockQuestionnaire';
import {
  INPUT_BOOLEAN,
  INPUT_TEXT,
  INPUT_NUMBER,
} from 'constants/questionTypes';
import { SECONDARY, TERTIARY } from 'constants/dependencyStatuses';

// Form ID from the MHM Decision Doc Copy Document: 3
export const form3 = rest.get(
  `/api/forms/${MOCK_QUESTIONNAIRE_ID}/${FORM_ID_HOUSEHOLD_DYNAMIC}`,
  (req, res, ctx) => {
    const formId = FORM_ID_HOUSEHOLD_DYNAMIC;
    const formTitle = 'Who else will be on your insurance plan this year?';
    const formContent = ``;
    const groupedQuestions = [
      [
        {
          id: 'e361dbc6-6bcb-44f1-9c14-07e610ba831d',
          title: 'My partner/spouse',
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a731dbc6-6bcb-44f1-9c14-07e610ba872c'],
          },
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: '9ff33fa2-39e8-4103-9e31-c75fc1a12b6d',
          title: 'Name your partner/spouse',
          subtitle: "Partner/spouse's name",
          type: INPUT_TEXT,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: 'e361dbc6-6bcb-44f1-9c14-07e610ba831d',
            answerId: null,
            disableOnQuestionIds: ['a731dbc6-6bcb-44f1-9c14-07e610ba872c'],
          },
          max: 80,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: '462bb7bf-9e68-4881-b0be-783e045A4006',
          title: 'My child(ren)',
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            disableOnQuestionIds: ['a731dbc6-6bcb-44f1-9c14-07e610ba872c'],
          },
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
        {
          id: 'a23bb7bf-9e68-4881-b0be-783e045A4F51',
          title: 'Add Children',
          subtitle: 'How many children do you have?',
          type: INPUT_NUMBER,
          showInputNumberButtons: true,
          multipleChoiceOptions: null,
          dependency: {
            status: SECONDARY,
            questionId: '462bb7bf-9e68-4881-b0be-783e045A4006',
            answerId: null,
            disableOnQuestionIds: ['a731dbc6-6bcb-44f1-9c14-07e610ba872c'],
          },
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          hideAnswer: true,
          img: null,
        },
        {
          id: '9ff33fa2-39e8-4103-9e31-c75fc1a12c7a',
          title: 'Name your children',
          subtitle: "Child's name",
          type: INPUT_TEXT,
          multipleChoiceOptions: null,
          dependency: {
            status: TERTIARY,
            questionId: '462bb7bf-9e68-4881-b0be-783e045A4006',
            dynamicParentId: 'a23bb7bf-9e68-4881-b0be-783e045A4F51',
            answerId: null,
            disableOnQuestionIds: ['a731dbc6-6bcb-44f1-9c14-07e610ba872c'],
          },
          max: 80,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
      ],
      [
        {
          id: 'a731dbc6-6bcb-44f1-9c14-07e610ba872c',
          title: 'Just Me',
          subtitle: '',
          titleLarge: null,
          type: INPUT_BOOLEAN,
          multipleChoiceOptions: null,
          dependency: {
            questionId: null,
            answerId: null,
            disableOnQuestionIds: [
              'e361dbc6-6bcb-44f1-9c14-07e610ba831d',
              '462bb7bf-9e68-4881-b0be-783e045A4006',
            ],
          },
          max: null,
          min: null,
          required: false,
          selectedAnswer: null,
          img: null,
        },
      ],
    ];
    const theoCallout = {
      summaryText:
        "Your costs are impacted by how many people are going to need medical coverage. I'll factor your answers into your report.<br />Don't worry, your information will be kept private. Feel free to use a nickname for any of your family members.",
      bodyText: '',
    };
    return res(
      ctx.delay(300),
      ctx.status(200),
      ctx.json({
        formId,
        formTitle,
        formContent,
        groupedQuestions,
        theoCallout,
      }),
    );
  },
);
